import { useEffect, useState } from "react";
import { api } from "../../../api/api";
import {
  InputCheckBox,
  InputDate,
  InputImage,
  InputNumber,
  InputText,
  InputTextBox,
  InputToggle,
} from "../../../components/Inputs/Inputs";
import Modal from "../../../components/Modal/Modal";
import { useToast } from "../../../components/Toast";
import {
  brand_url,
  country_url,
  first_category_url,
  gbrand_url,
  gfirst_category_url,
  gsecond_category_url,
  gthird_category_url,
  second_category_url,
  seller_annual_income,
  seller_business_type,
  seller_organisation_type,
  seller_url,
  third_category_url,
} from "../../../api/urls";
import unknownError from "../../../utils/unknownError";
import { urlTofile } from "../../../utils/urlTofile";
import objToFormdata from "../../../utils/objToFormdata";
import { APIDropdown } from "../../../components/APIDropdown/APIDropdown";
import { moveUpToError } from "../../../utils/moveUpToError";
import { objToParams } from "../../../utils/objToParams";

const initialState = {
  code: "",
  printable_name: "",
  description: "",
  disable: false,
  is_active: false,
  company_image: "",
  firstname: "",
  lastname: "",
  company_name: "",
  company_email_id: "",
  is_verified: false,
  email_id: "",
  website: "",
  landline_number: "",
  mobile_number: "",
  date_of_incoperate: "",
  gstin_number: "",
  pan_number: "",
  tan_number: "",
  cin_number: "",
  bizz_type: "",
  orgzn_type: "",
  ann_income: "",
};

const EditSellerProfileModal = ({
  trigger,
  refetch,
  editData,
  seller_id,
  isGlobal,
}) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const toast = useToast();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (editData) {
      const asyncSet = async () => {
        const newData = { ...editData };
        newData.company_image = await urlTofile(newData.company_image);
        delete newData.product;
        delete newData.video;
        setData(newData);
      };
      asyncSet();
    }
  }, [editData]);

  useEffect(() => {
    if (open === false && !editData) {
      setData({ ...initialState });
      setErrors({});
    }
  }, [open, setData, setErrors, editData]);

  const set = (key, value) => {
    setData((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };

  const view = false;

  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      trigger={trigger}
      className="modal-window"
      overlayClassName="no-overlay"
    >
      <div className="modal-content">
        <div className="modal-heading">Seller Profile</div>
        <div className="modal-form">
          <form
            id="form-wrapper"
            onSubmit={(e) => {
              e.preventDefault();
              setIsSaving(true);
              const newData = { ...data };
              try {
                if (editData) {
                  api
                    .patch(seller_url + data.id + "/", objToFormdata(newData))
                    .then((res) => {
                      refetch();
                      setOpen(false);
                      toast.open(
                        "Edited data with id: " + res.data.id,
                        "success"
                      );
                      setIsSaving(false);
                    })
                    .catch((err) => {
                      if (err?.response?.data) {
                        setErrors(err.response.data);
                        moveUpToError();
                      } else {
                        unknownError(err);
                      }
                      setIsSaving(false);
                    });
                } else {
                  api
                    .post(seller_url, objToFormdata(newData))
                    .then((res) => {
                      refetch();
                      setOpen(false);
                      toast.open(
                        "Added data with id: " + res.data.id,
                        "success"
                      );
                      setIsSaving(false);
                    })
                    .catch((err) => {
                      if (err?.response?.data) {
                        setErrors(err.response.data);
                        moveUpToError();
                      } else {
                        unknownError(err);
                      }
                      setIsSaving(false);
                    });
                }
              } catch (err) {
                unknownError(err);
                setIsSaving(false);
              }
            }}
          >
            <div className="twoside-form">
              <div className="formside">
                <InputText
                  label="Code"
                  placeholder="Code"
                  disabled={view}
                  required
                  value={data.code}
                  onChange={(v) => set("code", v)}
                  error={errors["code"]}
                />
                <InputText
                  label="Printable name"
                  placeholder="Printable name"
                  disabled={view}
                  required
                  value={data.printable_name}
                  onChange={(v) => set("printable_name", v)}
                  error={errors["printable_name"]}
                />
                <InputTextBox
                  label="Description"
                  placeholder="Description"
                  disabled={view}
                  required
                  value={data.description}
                  onChange={(v) => set("description", v)}
                  error={errors["description"]}
                />
                <InputText
                  label="First name"
                  placeholder="First name"
                  disabled={view}
                  required
                  value={data.firstname}
                  onChange={(v) => set("firstname", v)}
                  error={errors["firstname"]}
                />
                <InputText
                  label="Last name"
                  placeholder="Last name"
                  disabled={view}
                  required
                  value={data.lastname}
                  onChange={(v) => set("lastname", v)}
                  error={errors["lastname"]}
                />
                <InputText
                  label="Email id"
                  placeholder="Email id"
                  type="email"
                  disabled={view}
                  required
                  value={data.email_id}
                  onChange={(v) => set("email_id", v)}
                  error={errors["email_id"]}
                />
                <InputNumber
                  label="Mobile number"
                  placeholder="Mobile number"
                  disabled={view}
                  required
                  value={data.mobile_number}
                  onChange={(v) => set("mobile_number", v)}
                  error={errors["mobile_number"]}
                />
                <InputText
                  label="Company name"
                  placeholder="Company name"
                  disabled={view}
                  required
                  value={data.company_name}
                  onChange={(v) => set("company_name", v)}
                  error={errors["company_name"]}
                />
                <InputText
                  label="Company email id"
                  placeholder="Company email id"
                  type="email"
                  disabled={view}
                  required
                  value={data.company_email_id}
                  onChange={(v) => set("company_email_id", v)}
                  error={errors["company_email_id"]}
                />
                <InputText
                  label="Website url"
                  placeholder="Website url"
                  disabled={view}
                  required
                  value={data.website}
                  onChange={(v) => set("website", v)}
                  error={errors["website"]}
                />
                <InputNumber
                  label="Landline number"
                  placeholder="Landline number"
                  disabled={view}
                  required
                  value={data.landline_number}
                  onChange={(v) => set("landline_number", v)}
                  error={errors["landline_number"]}
                />
                <APIDropdown
                  label="Brand"
                  placeholder="Brand"
                  required
                  disabled={view}
                  value={data.brand}
                  url={isGlobal ? gbrand_url : brand_url}
                  onChange={(v) => set("brand", v)}
                  error={errors["brand"]}
                  hasMultipleSelection
                />
                <InputText
                  label="Registered office GSTIN number"
                  placeholder="Registered office GSTIN number"
                  disabled={view}
                  required
                  value={data.gstin_number}
                  onChange={(v) => set("gstin_number", v)}
                  error={errors["gstin_number"]}
                />
                <InputText
                  label="PAN number"
                  placeholder="PAN number"
                  disabled={view}
                  required
                  value={data.pan_number}
                  onChange={(v) => set("pan_number", v)}
                  error={errors["pan_number"]}
                />
              </div>
              <div className="formside">
                <InputText
                  label="TAN number"
                  placeholder="TAN number"
                  disabled={view}
                  required
                  value={data.tan_number}
                  onChange={(v) => set("tan_number", v)}
                  error={errors["tan_number"]}
                />
                <InputText
                  label="CIN number"
                  placeholder="CIN number"
                  disabled={view}
                  required
                  value={data.cin_number}
                  onChange={(v) => set("cin_number", v)}
                  error={errors["cin_number"]}
                />
                <InputImage
                  label="Company image"
                  placeholder="Company image"
                  disabled={view}
                  required
                  value={data.company_image}
                  onChange={(v) => set("company_image", v)}
                  error={errors["company_image"]}
                />
                <APIDropdown
                  label="Organisation type"
                  placeholder="Organisation type"
                  required
                  disabled={view}
                  value={data.orgzn_type}
                  url={seller_organisation_type}
                  onChange={(v) => set("orgzn_type", v)}
                  error={errors["orgzn_type"]}
                />
                <InputDate
                  label="Date of incorporation"
                  required
                  placeholder="Date of incorporation"
                  value={data.date_of_incoperate}
                  disabled={view}
                  onChange={(v) => set("date_of_incoperate", v)}
                  error={errors["date_of_incoperate"]}
                />
                <APIDropdown
                  label="Business type"
                  placeholder="Business type"
                  required
                  disabled={view}
                  value={data.bizz_type}
                  url={seller_business_type}
                  onChange={(v) => set("bizz_type", v)}
                  error={errors["bizz_type"]}
                />
                <APIDropdown
                  label="Annual income"
                  placeholder="Annual income"
                  required
                  disabled={view}
                  value={data.ann_income}
                  url={seller_annual_income}
                  onChange={(v) => set("ann_income", v)}
                  error={errors["ann_income"]}
                />
                <APIDropdown
                  label="Country"
                  placeholder="Country"
                  required
                  disabled={view}
                  value={data.country}
                  url={country_url}
                  onChange={(v) => set("country", v)}
                  error={errors["country"]}
                  hasMultipleSelection
                />
                <APIDropdown
                  label="First category"
                  placeholder="First category"
                  required
                  disabled={view}
                  value={data.category_first}
                  url={isGlobal ? gfirst_category_url : first_category_url}
                  onChange={(v) => set("category_first", v)}
                  error={errors["category_first"]}
                  hasMultipleSelection
                />
                <APIDropdown
                  label="Second category"
                  placeholder="Second category"
                  required
                  disabled={view}
                  value={data.category_second}
                  url={
                    (isGlobal ? gsecond_category_url : second_category_url) +
                    "?" +
                    objToParams({
                      category_first: data.category_first,
                    })
                  }
                  onChange={(v) => set("category_second", v)}
                  error={errors["category_second"]}
                  hasMultipleSelection
                />
                <APIDropdown
                  label="Third category"
                  placeholder="Third category"
                  required
                  disabled={view}
                  value={data.category_third}
                  url={
                    (isGlobal ? gthird_category_url : third_category_url) +
                    "?" +
                    objToParams({
                      category_second: data.category_second,
                    })
                  }
                  onChange={(v) => set("category_third", v)}
                  error={errors["category_third"]}
                  hasMultipleSelection
                />
                <InputCheckBox
                  label="Verified"
                  disabled={view}
                  value={data.is_verified}
                  onChange={(v) => set("is_verified", v)}
                />
                <InputToggle
                  label="Active"
                  disabled={view}
                  value={data.is_active}
                  error={errors["is_active"]}
                  onChange={(v) => set("is_active", v)}
                />
              </div>
            </div>
            <div style={{ padding: "10px" }} />
          </form>
        </div>
        <div className="modal-actions">
          <div className="actions">
            <button
              className="btn-red"
              type="button"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              form="form-wrapper"
              className="btn"
              onClick={() => {
                moveUpToError();
              }}
              disabled={isSaving}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditSellerProfileModal;
