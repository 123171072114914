import { useState } from "react";
import "./Modal.scss";
import { useEffect } from "react";
import { createPortal } from "react-dom";

const Modal = ({
  open,
  onOpen,
  trigger,
  className,
  children,
  overlayClassName,
}) => {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <>
      {isOpen === true &&
        createPortal(
          <div
            className={"overlay " + (overlayClassName ? overlayClassName : "")}
          >
            <div className={className + " window"}>{children}</div>
          </div>,
          document.body
        )}
      <div
        onClick={() => {
          setIsOpen(true);
          if (onOpen) {
            onOpen();
          }
        }}
      >
        {trigger}
      </div>
    </>
  );
};

export default Modal;
