import { useHistory } from "react-router-dom";
import {
  country_url,
  delivery_boy_temp_url,
  license_type_url,
  state_url,
} from "../../../api/urls";
import { useFetch } from "../../../utils/useFetch";
import { useSteps } from "../../../utils/useStep";
import { validators } from "../../../utils/zodValidations";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Field, FieldSet, Form } from "../../../components/form";
import { Input } from "../../../components/input";
import BackButton from "../../../components/BackButton/BackButton";
import { Header } from "semantic-ui-react";
import { FormSteps } from "../../../components/formSteps";
import { api } from "../../../api/api";
import { serialize } from "../../../utils/serialize";
import unknownError from "../../../utils/unknownError";
import { useToast } from "../../../components/Toast";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Loading from "../../../components/Loading/Loading";

export function NewPendingRegistration({ edit, view }) {
  const title = "Add user";
  const apiUrl = delivery_boy_temp_url;
  const backUrl = "/logistics/pending_registrations";
  const { step, setStep, next, canGoToStep } = useSteps(6, {
    freeMoving: true,
  });
  const countryData = useFetch(country_url);
  const stateData = useFetch(state_url);
  const licenseTypeData = useFetch(license_type_url);
  const history = useHistory();

  const schemas = [
    z
      .object({
        first_name: validators.stringRequired,
        last_name: validators.stringRequired,
        dob: validators.dateRequired,
        photo: validators.imageRequired,
        country: validators.numberRequired,
        mobile_number: validators.numberRequired,
      })
      .superRefine((values, ctx) => {
        if (countryData?.data) {
          const regex = countryData.data.find(
            (c) => c.country_mobile_code === values.country
          )?.country_regex;
          if (
            regex &&
            !RegExp(regex).test(`${values.country}${values.mobile_number}`)
          ) {
            ctx.addIssue({
              message: "Invalid mobile no",
              path: ["mobile_number"],
            });
          }
        }
      }),
    z.object({
      aadhaar_number: validators.stringRequired,
      aadhaar_file: validators.fileRequired,
      pan_no: validators.stringRequired,
      pan_file: validators.fileRequired,
      noc_file: validators.fileRequired,
    }),
    z.object({ vehicle: validators.stringRequired }),
    z.object({
      licence_no: validators.stringRequired,
      driving_license_file: validators.fileRequired,
      license_expiry: validators.dateRequired,
      license_type: validators.numberRequired,
      insurance_number: validators.stringRequired,
      insurance_file: validators.fileRequired,
      insurance_expiry: validators.dateRequired,
      vehicle_registration_cert_file: validators.fileRequired,
      vehicle_registration_cert_expiry: validators.dateRequired,
      puc_file: validators.fileRequired,
    }),
    z.object({
      address: validators.stringRequired,
      landmark: validators.stringRequired,
      state: validators.numberRequired,
      city: validators.stringRequired,
      country_address: validators.numberRequired,
      pincode: validators.numberRequired,
    }),
    z.object({
      bank_name: validators.stringRequired,
      account_holder_name: validators.stringRequired,
      ifsc_code: validators.stringRequired,
      acccount_number: validators.stringRequired,
    }),
  ];
  const methods = useForm({
    resolver: zodResolver(schemas[step]),
    shouldFocusError: true,
  });

  const [isGetting, setIsGetting] = useState(true);
  const params = useParams();
  useEffect(() => {
    if (edit || view || params.id) {
      const getData = async () => {
        try {
          const res = await api.get(apiUrl + params.id + "/", {
            headers: { module: module },
          });
          const data = res.data?.[0]?.form_data || {};
          data.id = res.data?.id;
          data.country_mobile_code = data?.mobile_number?.slice(0, 2);
          data.mobile_number = data?.mobile_number?.slice(2);
          methods.reset(data);

          setIsGetting(false);
        } catch (err) {
          unknownError(err);
        }
      };

      getData();
    } else {
      setIsGetting(false);
    }
  }, [params.id, edit, view, methods, apiUrl]);

  const loading = methods.formState.isSubmitting;
  const toast = useToast();
  const steps = [
    {
      title: "Personal info",
      onSubmit: () => {
        next();
      },
      body: (
        <FieldSet>
          <Input.Text
            methods={methods}
            label="Name"
            info="Your first name"
            name="first_name"
            placeholder="Enter name"
            required
            disabled={view}
          />
          <Input.Text
            methods={methods}
            label="Last name"
            info="Your last name"
            name="last_name"
            placeholder="Enter last name"
            required
            disabled={view}
          />
          <Input.Date
            methods={methods}
            label="Date of birth"
            name="dob"
            placeholder="Enter last name"
            required
            disabled={view}
          />
          <Input.Select
            methods={methods}
            label="Country"
            name="country"
            placeholder="Select country"
            options={countryData.data}
            loading={countryData.loading}
            onChangeFullData={(d) => {
              methods.setValue("country_mobile_code", d.country_mobile_code);
            }}
            required
            disabled={view}
          />
          <Input.Number
            methods={methods}
            label="Mobile no."
            name="mobile_number"
            placeholder="Enter mobile number"
            required
            disabled={view}
          />
          <Input.File
            methods={methods}
            label="Upload photo"
            name="photo"
            required
            disabled={view}
          />
          <Input.Text
            methods={methods}
            label="Email id"
            name="email_id"
            placeholder="Enter your email id"
            disabled={view}
          />
          {!view && (
            <Field>
              <div style={{ padding: "10px" }} />
              <div className="actions">
                <button
                  className="btn-red"
                  type="button"
                  onClick={() => {
                    history.push(backUrl);
                  }}
                >
                  Cancel
                </button>
                <button className="btn" disabled={loading}>
                  Save
                </button>
              </div>
            </Field>
          )}
        </FieldSet>
      ),
    },
    {
      title: "ID check",
      onSubmit: () => {
        next();
      },
      body: (
        <FieldSet>
          <Input.Text
            methods={methods}
            label="Aadhaar number"
            name="aadhaar_number"
            placeholder="Enter aadhaar nubmer"
            required
            disabled={view}
          />
          <Input.File
            methods={methods}
            label="Upload aadhaar"
            name="aadhaar_file"
            required
            disabled={view}
          />
          <Input.Text
            methods={methods}
            label="PAN number"
            name="pan_no"
            placeholder="Enter PAN number"
            required
            disabled={view}
          />
          <Input.File
            methods={methods}
            label="Upload PAN"
            name="pan_file"
            required
            disabled={view}
          />
          <Input.File
            methods={methods}
            label="Upload NOC"
            name="noc_file"
            required
            disabled={view}
          />
          {!view && (
            <Field>
              <div style={{ padding: "10px" }} />
              <div className="actions">
                <button
                  className="btn-red"
                  type="button"
                  onClick={() => {
                    history.push(backUrl);
                  }}
                >
                  Cancel
                </button>
                <button className="btn" disabled={loading}>
                  Save
                </button>
              </div>
            </Field>
          )}
        </FieldSet>
      ),
    },
    {
      title: "Select vehicle",
      onSubmit: () => {
        next();
      },
      body: (
        <FieldSet>
          <Input.Radio
            methods={methods}
            label="Select vehicle"
            name="vehicle"
            options={[
              { id: "Motorcycle", printable_name: "Motorcycle" },
              { id: "Car", printable_name: "Car" },
            ]}
            required
            disabled={view}
          />
          {!view && (
            <Field>
              <div style={{ padding: "10px" }} />
              <div className="actions">
                <button
                  className="btn-red"
                  type="button"
                  onClick={() => {
                    history.push(backUrl);
                  }}
                >
                  Cancel
                </button>
                <button className="btn" disabled={loading}>
                  Save
                </button>
              </div>
            </Field>
          )}
        </FieldSet>
      ),
    },
    {
      title: "Vehicle details",
      onSubmit: () => {
        next();
      },
      body: (
        <FieldSet>
          <Input.Text
            methods={methods}
            label="Driving license no"
            name="licence_no"
            placeholder="Enter driving license no"
            required
            disabled={view}
          />
          <Input.File
            methods={methods}
            label="Upload driving license"
            name="driving_license_file"
            required
            disabled={view}
          />
          <Input.Date
            methods={methods}
            label="License expiry"
            name="license_expiry"
            required
            disabled={view}
          />
          <Input.Select
            methods={methods}
            label="License type"
            name="license_type"
            placeholder="Select license type"
            options={licenseTypeData.data}
            loading={licenseTypeData.loading}
            required
            disabled={view}
          />
          <Input.Text
            methods={methods}
            label="Insurance number"
            name="insurance_number"
            placeholder="Enter insurance number"
            required
            disabled={view}
          />
          <Input.File
            methods={methods}
            label="Upload insurance"
            name="insurance_file"
            required
            disabled={view}
          />
          <Input.Date
            methods={methods}
            label="Insurance expiry"
            name="insurance_expiry"
            required
            disabled={view}
          />
          <Input.File
            methods={methods}
            label="Vehicle registration certificate"
            name="vehicle_registration_cert_file"
            required
            disabled={view}
          />
          <Input.Date
            methods={methods}
            label="Registration certificate expiry"
            name="vehicle_registration_cert_expiry"
            required
            disabled={view}
          />
          <Input.File
            methods={methods}
            label="Upload PUC"
            name="puc_file"
            required
            disabled={view}
          />
          {!view && (
            <Field>
              <div style={{ padding: "10px" }} />
              <div className="actions">
                <button
                  className="btn-red"
                  type="button"
                  onClick={() => {
                    history.push(backUrl);
                  }}
                >
                  Cancel
                </button>
                <button className="btn" disabled={loading}>
                  Save
                </button>
              </div>
            </Field>
          )}
        </FieldSet>
      ),
    },
    {
      title: "Address details",
      onSubmit: () => {
        next();
      },
      body: (
        <FieldSet>
          <Input.Text
            methods={methods}
            label="Address"
            name="address"
            placeholder="Street | bld | area | plot"
            required
            disabled={view}
          />
          <Input.Text
            methods={methods}
            label="Landmark"
            name="landmark"
            placeholder="Enter landmark"
            required
            disabled={view}
          />
          <Input.Text
            methods={methods}
            label="City / town"
            name="city"
            placeholder="City / town"
            disabled={view}
            required
          />
          <Input.Select
            methods={methods}
            label="Select state"
            name="state"
            placeholder="Select state"
            options={stateData.data}
            loading={stateData.loading}
            required
            disabled={view}
          />
          <Input.Select
            methods={methods}
            label="Select country"
            name="country_address"
            placeholder="Select country"
            options={countryData.data}
            loading={countryData.loading}
            required
            disabled={view}
          />
          <Input.Number
            methods={methods}
            label="Pincode"
            name="pincode"
            placeholder="Enter pincode"
            required
            disabled={view}
          />
          {!view && (
            <Field>
              <div style={{ padding: "10px" }} />
              <div className="actions">
                <button
                  className="btn-red"
                  type="button"
                  onClick={() => {
                    history.push(backUrl);
                  }}
                >
                  Cancel
                </button>
                <button className="btn" disabled={loading}>
                  Save
                </button>
              </div>
            </Field>
          )}
        </FieldSet>
      ),
    },
    {
      title: "Bank details",
      onSubmit: async () => {
        if (edit) {
          const data = methods.getValues();
          data.mobile_number = `${data.country_mobile_code}${data.mobile_number}`;
          try {
            const response = await api.patch(
              `${apiUrl}${data.id}/`,
              serialize({ form_data: data })
            );
            if (response.status === 200) {
              toast.open("Edited data successfully", "success");
              history.push(backUrl);
            } else {
              unknownError();
            }
          } catch (e) {
            unknownError();
          }
        } else {
          const data = methods.getValues();
          data.mobile_number = `${data.country_mobile_code}${data.mobile_number}`;
          try {
            const response = await api.post(apiUrl, serialize(data));
            if (response.status === 200) {
              toast.open("Added data successfully", "success");
              history.push(backUrl);
            } else {
              unknownError();
            }
          } catch (e) {
            unknownError();
          }
        }
      },
      body: (
        <FieldSet>
          <Input.Text
            methods={methods}
            label="Bank name"
            name="bank_name"
            placeholder="Enter bank name"
            required
            disabled={view}
          />
          <Input.Text
            methods={methods}
            label="Account holder name"
            name="account_holder_name"
            placeholder="Enter Account holder name"
            required
            disabled={view}
          />
          <Input.Text
            methods={methods}
            label="Account number"
            name="acccount_number"
            placeholder="Enter Account number"
            required
            disabled={view}
          />
          <Input.Text
            methods={methods}
            label="IFSC Code"
            name="ifsc_code"
            placeholder="Enter IFSC code"
            required
            disabled={view}
          />
          <Input.Text
            methods={methods}
            label="UPI ID"
            name="upi_id"
            placeholder="Enter Account number"
            disabled={view}
          />
          {!view && (
            <Field>
              <div style={{ padding: "10px" }} />
              <div className="actions">
                <button
                  className="btn-red"
                  type="button"
                  onClick={() => {
                    history.push(backUrl);
                  }}
                >
                  Cancel
                </button>
                <button className="btn" disabled={loading}>
                  Save
                </button>
              </div>
            </Field>
          )}
        </FieldSet>
      ),
    },
  ];

  return (
    <div>
      <div id="form">
        <BackButton href={backUrl} />
        <Header>{title}</Header>
      </div>
      <div style={{ padding: "10px" }} />
      {isGetting ? (
        <Loading />
      ) : (
        <Form methods={methods} onSubmit={steps[step].onSubmit}>
          <FormSteps
            currentStep={step}
            setCurrentStep={setStep}
            steps={steps}
            canGoToStep={canGoToStep}
            submit={async () => {
              if (!view) {
                const valid = await methods.trigger();
                return valid;
              } else {
                return true;
              }
            }}
          />
        </Form>
      )}
    </div>
  );
}
