import { Header, Radio } from "semantic-ui-react";
import BackButton from "../../../components/BackButton/BackButton";
import { useState } from "react";
import Tabs from "../../../components/Tabs/Tabs";
import { DashboardImage } from "../../../components/DashboardImage/DashboardImage";
import moment from "moment";
import { TextChip } from "../../../components/TextChip/TextChip";
import ActionDropdown from "../../../components/ActionDropdown/ActionDropdown";
import { ProtectedElement } from "../../../components/ProtectedElement/ProtectedElement";
import { modules, perms } from "../../../api/codes";
import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { ReactComponent as UserIcon } from "../../../images/user.svg";
import { TableComponent } from "../../../components/TableComponent/TableComponent";
import { useFetchWithPagination } from "../../../utils/useFetchWithPagination";
import { delivery_boy_temp_url, reels_url } from "../../../api/urls";
import { useFetch } from "../../../utils/useFetch";
import Loading from "../../../components/Loading/Loading";
import { TitleLine } from "../../../components/lineTitle";
import { na, na2 } from "../../../utils/na";
import { useModal } from "../../../components/Modaler";
import { api } from "../../../api/api";
import { useToast } from "../../../components/Toast";
import unknownError from "../../../utils/unknownError";
import { useParamsState } from "../../../utils/useParamsState";
import {
  EditorInput,
  editorToText,
  textToEditor,
} from "../../../components/EditorInput/editorInput";

const tabs = [
  {
    title: "Brand approval",
    code: "brand",
    api: `${delivery_boy_temp_url}`,
    singelApi: `${delivery_boy_temp_url}`,
  },
  {
    title: "Category approval",
    code: "category",
    api: `${delivery_boy_temp_url}`,
    singelApi: `${delivery_boy_temp_url}`,
  },
  {
    title: "Product approval",
    code: "product",
    api: `${delivery_boy_temp_url}`,
    singelApi: `${delivery_boy_temp_url}`,
  },
  {
    title: "Reels approval",
    code: "reel",
    api: `${reels_url}`,
    singelApi: `${reels_url}`,
    statusKey: "reels_request_status",
  },
  {
    title: "Profile image approval",
    code: "image",
    api: `${delivery_boy_temp_url}`,
    singelApi: `${delivery_boy_temp_url}`,
  },
];

const module = modules.OtherKYC;

export function OtherKYC() {
  const title = "Other KYC";
  const currentRoute = "other_kyc";

  const [code, setCode] = useParamsState("code", tabs[0].code);
  const [activeTab, setActiveTab] = useState(
    tabs.findIndex((tab) => tab.code === code)
  );
  const { data, pagination } = useFetchWithPagination(tabs[activeTab].api);

  return (
    <>
      <BackButton />
      <div id="heading">
        <Header>{title}</Header>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          gap: "10px",
          flexShrink: 0,
        }}
      >
        <div
          style={{
            flexGrow: 1,
            flexShrink: 0,
            overflow: "auto",
          }}
        >
          <Tabs
            options={tabs}
            active={activeTab}
            setActive={(index) => {
              setCode(tabs[index].code);
              setActiveTab(index);
            }}
          />
        </div>
      </div>
      <div className="p-5" />
      <TableComponent
        columns={columnsByCode(tabs[activeTab].code, currentRoute)}
        rows={data.data?.results || []}
        loading={data.loading}
      />
      {pagination}
    </>
  );
}

function columnsByCode(code, currentRoute) {
  return [
    ...columnsByCodeWithoutDefault(code, currentRoute),
    {
      headerName: "Actions",
      field: "actions",
      flex: 1,
      renderCell: (params) => (
        <ActionDropdown
          options={(closer) => {
            return (
              <ProtectedElement
                module={module}
                perm={perms.verifySellerOtherKYC}
              >
                <Link to={currentRoute + `/verify/${code}/${params.row.id}`}>
                  <div
                    className="option"
                    onClick={() => {
                      closer();
                    }}
                  >
                    <div className="icon-btn purple-icon">
                      <UserIcon />
                    </div>
                    <div className="purple-title">Verify profile</div>
                  </div>
                </Link>
              </ProtectedElement>
            );
          }}
        />
      ),
    },
  ];
}

function columnsByCodeWithoutDefault(code, currentRoute) {
  if (code === "brand") {
    return [
      {
        headerName: "Image",
        field: "form_data.photo",
        flex: 1,
        renderCell: (params) => (
          <DashboardImage width="50px" src={params?.row?.form_data?.photo} />
        ),
      },
      {
        headerName: "Name",
        field: "form_data.first_name",
        flex: 1,
        renderCell: (params) =>
          `${params?.row?.form_data?.first_name} ${params?.row?.form_data?.last_name}`,
      },
    ];
  }

  if (code === "reel") {
    return [
      {
        headerName: "Reel",
        field: "reels_video",
        flex: 1,
        renderCell: (params) => (
          <video width="150" height="150" controls>
            <source src={params.value} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ),
      },
      {
        headerName: "Title",
        field: "title",
      },
      {
        headerName: "Seller",
        field: "seller_video",
      },
      {
        headerName: "Upload date",
        field: "created_at",
        flex: 1,
        renderCell: (params) => moment(params.value).format("DD/MM/YYYY"),
      },
      {
        headerName: "Verified ?",
        field: "reels_request_status",
        flex: 1,
        renderCell: (params) => (
          <TextChip text={params.value} color="#FF6F31" />
        ),
      },
    ];
  }

  // default
  return [
    {
      headerName: "Image",
      field: "form_data.photo",
      flex: 1,
      renderCell: (params) => (
        <DashboardImage width="50px" src={params?.row?.form_data?.photo} />
      ),
    },
    {
      headerName: "Name",
      field: "form_data.first_name",
      flex: 1,
      renderCell: (params) =>
        `${params?.row?.form_data?.first_name} ${params?.row?.form_data?.last_name}`,
    },
  ];
}

export function OtherKYCVerification() {
  const title = "Other KYC";
  const params = useParams();
  const id = params.id;
  const code = params.code;
  const backUrl = `/sellers/other_kyc?code=${params.code}`;
  const activeTab = tabs.find((tab) => tab.code === code);
  const modalar = useModal();
  const toast = useToast();
  const history = useHistory();

  const data = useFetch(activeTab.singelApi + id + "/");

  const [verified, setVerified] = useState(false);
  const [comment, setComment] = useState(textToEditor(""));
  const [saving, setSaving] = useState(false);

  if (data.loading || !data.data) {
    return <Loading />;
  }

  return (
    <>
      <BackButton href={backUrl} />
      <Header>{title}</Header>
      <div className="p-10" />
      <ExtraKeys code={code} data={data.data} />
      <div className="p-10" />
      {false && <UploadedDocuments data={data} />}
      <div className="p-10" />
      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <Radio
          label="Verify"
          checked={verified}
          onChange={(v) => setVerified(true)}
        />
        <Radio
          label="Reject"
          checked={!verified}
          onChange={(v) => setVerified(false)}
        />
        <div style={{ flex: 1 }} />
        <button
          className="btn"
          disabled={saving}
          onClick={async () => {
            if (!verified) {
              modalar.opener(
                <div style={{ padding: "20px" }}>
                  <EditorInput
                    value={comment}
                    onChange={(v) => setComment(v)}
                    label="Comment"
                  />
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ flex: 1 }} />
                    <button
                      className="btn"
                      disabled={saving}
                      onClick={async () => {
                        setSaving(true);
                        try {
                          await api.patch(activeTab.singelApi + id + "/", {
                            [activeTab.statusKey]: "REJECTED",
                            comments: editorToText(comment),
                          });
                          modalar.closer();
                          toast.open("Success", "success");
                          history.push(backUrl);
                        } catch (e) {
                          if (
                            e?.response?.status === 400 &&
                            e?.response?.data?.data
                          ) {
                            toast.open(e?.response?.data.data, "success");
                          } else {
                            unknownError(e);
                          }
                        }
                        setSaving(false);
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>,
                true
              );
            } else {
              setSaving(true);
              try {
                await api.patch(activeTab.singelApi + id + "/", {
                  [activeTab.statusKey]: "APPROVED",
                });
                toast.open("Success", "success");
                history.push(backUrl);
              } catch (e) {
                if (e?.response?.status === 400 && e?.response?.data?.data) {
                  toast.open(e?.response?.data.data, "success");
                } else {
                  unknownError();
                }
              }
              setSaving(false);
            }
          }}
        >
          Save
        </button>
      </div>
      <div className="p-10" />
    </>
  );
}

function UploadedDocuments({ data }) {
  // const moduler = useModal();
  // const d = data?.data[0]?.form_data || {};
  return (
    <>
      <TitleLine right>Uploaded documents</TitleLine>
      <div className="p-10" />
      <TableComponent
        columns={[
          {
            headerName: "Document type",
            field: "doc_type",
          },
          {
            headerName: "Doc no.",
            field: "doc_no",
          },
          {
            headerName: "Verified ?",
            field: "doc_verified",
            renderCell: (params) => (params.value ? "Yes" : "No"),
          },
          {
            headerName: "File type",
            field: "doc_verified",
            renderCell: (params) => params.row.file?.split(".").pop(),
          },
          {
            headerName: "Action",
            renderCell: (params) => (
              <div
                className="blue-link"
                // onClick={() => {
                //   moduler.opener(
                //     <DocumentVerifyModal
                //       fileLink={params.row.file}
                //       verified={vdata[params.row.set_key]}
                //       onChange={(v) => {
                //         vset(params.row.set_key, v);
                //       }}
                //       onSave={() => moduler.closer()}
                //     />,
                //     true
                //   );
                // }}
              >
                Verify
              </div>
            ),
          },
        ]}
        loading={data.loading}
      />
    </>
  );
}

function ExtraKeys({ code, data: d }) {
  if (code === "reel") {
    return (
      <div className="flex-column">
        <div>
          <b>title : </b>
          {na(d?.title)}
        </div>
        <div className="p-5" />
        <div>
          <b>description : </b>
          {na(d?.description)}
        </div>
        <div className="p-5" />
        <div>
          <b>video : </b>
        </div>
        {na2(
          d?.reels_video,
          <video width="150" height="150" controls>
            <source src={d.reels_video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
    );
  }
  return (
    <>
      {code}
      {/* {Jdata} */}
    </>
  );
}
