import { ReactComponent as Logo } from "../../images/logo.svg";
import { ReactComponent as UserIcon } from "../../images/user.svg";
import { Dropdown } from "semantic-ui-react";
import "./Navbar.scss";
import { ReactComponent as LogoutIcon } from "../../images/logout.svg";
import { logout } from "../../redux/userStore";
import { useDispatch, useSelector } from "react-redux";
import { api } from "../../api/api";
import { user_logout_url } from "../../api/urls";
import { useRef, useState } from "react";
import { useOutsideClick } from "../Inputs/Inputs";

const NavBar = () => {
  const dispatch = useDispatch();
  const username = useSelector((state) => state.user.username);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const ref = useRef();
  useOutsideClick(ref, () => setOpen(false));
  return (
    <div id="nav">
      <Logo id="logo" height={35} width={85} />

      <div className="nav-right">
        <div className="nav-welcome">Welcome {username}</div>
        <div ref={ref}>
          <Dropdown
            icon={<UserIcon className="nav-user-icon" color="white" />}
            direction="left"
            open={open}
            onClick={() => setOpen(true)}
          >
            <Dropdown.Menu>
              <Dropdown.Item
                disabled={loading}
                onClick={async () => {
                  setLoading(true);
                  try {
                    await api.get(user_logout_url);
                  } catch (e) {
                    console.log(e);
                  }
                  setLoading(false);
                  setOpen(false);
                  await dispatch(logout());
                }}
              >
                <div className="logout-btn">
                  <LogoutIcon className="logout-icon" height={15} width={15} />
                  Logout
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
