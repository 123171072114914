import { useState } from "react";
import Modal from "../../../components/Modal/Modal";
import "./more_info_modal.scss";
import { ReactComponent as ArrowIcon } from "../../../images/arrow.svg";
import { na, na2, namt } from "../../../utils/na";
import { userAddressFormatter } from "../../../utils/addresssFormatter";
import moment from "moment";
import { DashboardImage } from "../../../components/DashboardImage/DashboardImage";

const MoreInfoModal = ({ data }) => {
  const [open, setOpen] = useState(false);
  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      trigger={<div className="blue-link">View Details</div>}
      className="modal-window product-modal"
    >
      <div
        className="close-btn"
        onClick={() => {
          setOpen(false);
        }}
      >
        &#10005;
      </div>
      <div style={{ padding: "2px" }} />
      <div className="modal-content">
        <div className="modal-form">
          <Annexture data={data} />
        </div>
      </div>
    </Modal>
  );
};

const Annexture = ({ data, actions }) => {
  const cancel = false;
  const is_return = false;
  return (
    <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
      <HeadingSection
        data={data}
        actions={actions}
        cancel={cancel}
        is_return={is_return}
      />
      <ProfileDetailsSection
        data={data}
        cancel={cancel}
        is_return={is_return}
      />
      <AddressSection data={data} cancel={cancel} is_return={is_return} />
      <ExtraDetailsSection data={data} cancel={cancel} is_return={is_return} />
    </div>
  );
};

const HeadingSection = ({ data: d, actions }) => {
  return (
    <div className="heading-section">
      <div className="left-section">
        <h3>
          <b>TagOn Customer Details</b>
        </h3>
      </div>
    </div>
  );
};

const ProfileDetailsSection = ({ data: d, cancel, is_return }) => {
  return (
    <div className="product-info">
      <div className="image-section">
        <DashboardImage
          src={d.user.profile_image}
          height="250px"
          width="250px"
          circle
        />
      </div>
      <div className="details-section">
        <div className="twoside-details">
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <div>
              <b>Name : </b>
              {na(d?.user?.full_name)}
            </div>
            <div>
              <b>Email id : </b>
              {na(d?.user?.email)}
            </div>
            <div>
              <b>Primary mobile number : </b>
              {na2(d?.user?.mobile_number, <>+{d?.user?.mobile_number}</>)}
            </div>
            <div>
              <b>Alternate mobile number : </b>
              {na2(
                d?.user?.alternate_number,
                <>+{d?.user?.alternate_number}</>
              )}
            </div>
            <div>
              <b>Birth date : </b>
              {na(d?.user?.birthday)}
            </div>
            <div>
              <b>Birth time : </b>
              {na(d?.user?.birthtime)}
            </div>
            <div>
              <b>Gender : </b>
              {na(d?.user?.gender)}
            </div>
            <div>
              <b>Referral code : </b>
              {na(d?.user?.referal_code)}
            </div>
            <div>
              <b>Referral link : </b>
              {na2(
                d?.user?.referal_link,
                <a href={d?.user?.referal_link}>{na(d?.user?.referal_link)}</a>
              )}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <div>
              <b>Country : </b>
              {na(d?.user?.country)}
            </div>
            <div>
              <b>Currency name : </b>
              {na(d?.user?.currency_name)}
            </div>
            <div>
              <b>Currency code : </b>
              {na(d?.user?.currency_code)}
            </div>
            <div>
              <b>Currency symbol : </b>
              {na(d?.user?.currency_symbol)}
            </div>
            <div>
              <b>Timezone : </b>
              {na2(
                d?.user?.currency_symbol,
                <span>
                  {d.user.timezone_name} ({d.user.timezone})
                </span>
              )}
            </div>
            <div>
              <b>Alpha two code : </b>
              {na(d?.user?.alpha_two_code)}
            </div>
            <div>
              <b>Alpha three code : </b>
              {na(d?.user?.alpha_three_code)}
            </div>
            <div>
              <b>Send email : </b>
              {d?.user?.send_email?.toString()}
            </div>
            <div>
              <b>Last login : </b>
              {na2(
                d?.user?.last_login,
                moment(d?.user?.last_login).format("llll")
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AddressSection = ({ data: d }) => {
  return (
    <Accordian
      head={"Address"}
      alwaysOpen
      body={
        <div className="address-box-wrapper">
          {d?.add && d?.add.length > 0
            ? d.add.map((d, idx) => (
                <div className="address-box" key={idx}>
                  {d?.name && (
                    <b>
                      {d?.name} <br />
                    </b>
                  )}
                  {userAddressFormatter(d)}
                  <br />
                  {d?.email_iD && (
                    <>
                      Email id : {d.email_iD} <br />
                    </>
                  )}
                  {d?.primary_mobile && (
                    <>
                      Mobile no : +{d.primary_mobile} <br />
                    </>
                  )}
                  {d?.alternate_mobile && (
                    <>
                      Secondary no : +{d.alternate_mobile} <br />
                    </>
                  )}
                  {d?.landline && (
                    <>
                      Landline no : +{d.landline} <br />
                    </>
                  )}
                  {d?.gstin_number && (
                    <>
                      GSTIN no : {d.gstin_number} <br />
                    </>
                  )}
                  {d?.pan_number && (
                    <>
                      PAN no : {d.gstin_number} <br />
                    </>
                  )}
                  {d?.company_name && (
                    <>
                      Company no : {d.company_name} <br />
                    </>
                  )}
                </div>
              ))
            : "N\\A"}
        </div>
      }
    />
  );
};

const ExtraDetailsSection = ({ data: d }) => {
  return (
    <Accordian
      head={"Extra Details"}
      alwaysOpen
      body={
        <div className="extra-details-section">
          {
            <div className="two-inputs">
              <div className="extra-box">
                <center style={{ color: "#0094FF" }}>
                  <b>Wallet</b>
                </center>
                <br />
                {d.money.length > 0
                  ? d.money.map((d, idx) => (
                      <div key={idx}>
                        <b>Wallet : </b>
                        {namt(d.amount)} ({d.alpha_two_code})
                      </div>
                    ))
                  : "N\\A"}
              </div>
              <div className="extra-box">
                <center style={{ color: "#0094FF" }}>
                  <b>Points</b>
                </center>
                <br />
                {d.points.length > 0
                  ? d.points.map((d, idx) => (
                      <div key={idx}>
                        <b>Points : </b>
                        {namt(d.coins_amount)}
                      </div>
                    ))
                  : "N\\A"}
              </div>
            </div>
          }
          {d.user.is_company && (
            <div className="extra-box">
              <center style={{ color: "#0094FF" }}>
                <b>Company details</b>
              </center>
              <div>
                <b>Company name : </b>
                {na(d?.user?.company_name)}
              </div>
              <div>
                <b>GSTIN no : </b>
                {na(d?.user?.gstin_number)}
              </div>
              <div>
                <b>PAN no : </b>
                {na(d?.user?.pan_number)}
              </div>
            </div>
          )}
        </div>
      }
    />
  );
};

const Accordian = ({ head, body, footer, alwaysOpen = false }) => {
  const [open, setOpen] = useState(alwaysOpen);
  const height = 10000;

  return (
    <div className={"acc " + (open ? "open" : "")}>
      <div
        className="acc-head"
        onClick={() => (!alwaysOpen ? setOpen(!open) : null)}
      >
        <b>{head}</b>
        {!alwaysOpen && (
          <ArrowIcon className={"acc-arrow " + (open ? "open" : "")} />
        )}
      </div>
      <div
        className={"acc-body " + (open ? "open" : "")}
        style={{ maxHeight: open ? height : 0 }}
      >
        {body}
        {footer}
      </div>
    </div>
  );
};

export default MoreInfoModal;
