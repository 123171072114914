import { Switch, Route } from "react-router-dom";
import Dashboard from "./pages/dashboard/dashboard";
import Content from "./components/Content";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import PageNotFound from "./pages/page_not_found/page_not_found";
import { RouteOrder } from "./components/Route/routeOrder";
import UpdTmpPswd from "./pages/upd_tmp_pswd/upd_tmp_pswd";
import Login from "./pages/login/login";
import { RoutePolicy } from "./components/Route/routePolicy";
import { RouteHelpCenter } from "./components/Route/routeHelpCenter";
import { RouteUsers } from "./components/Route/routeUsers";
import { RouteProducts } from "./components/Route/routeProducts";
import { RouteCoupons } from "./components/Route/routeCoupons";
import { RouteSeller } from "./components/Route/routeSeller";
import { RouteAdvertisements } from "./components/Route/routeAdvertisements";
import { RouteLogs } from "./components/Route/routeLogs";
import { RouteHoroscope } from "./components/Route/routeHoroscope";
import { RouteMasters } from "./components/Route/routeMasters";
import { RouteLogistics } from "./components/Route/routeLogistics";
import { RouteGlobal } from "./components/Route/routeGlobal";
import { RouteB2B } from "./components/Route/routeB2B";

const Main = () => {
  return (
    <Switch>
      <Route path="/" exact component={Login}></Route>
      <Route path="/dashboard" exact>
        <ProtectedRoute>
          <Content>
            <Dashboard />
          </Content>
        </ProtectedRoute>
      </Route>
      {[
        RouteOrder,
        RoutePolicy,
        RouteHelpCenter,
        RouteUsers,
        RouteProducts,
        RouteCoupons,
        RouteSeller,
        RouteAdvertisements,
        RouteLogs,
        RouteHoroscope,
        RouteMasters,
        RouteLogistics,
        RouteGlobal,
        RouteB2B,
      ].map((route) => {
        return route.map((d) => {
          return (
            <Route path={d.path} exact>
              <ProtectedRoute>
                <Content>{d.content}</Content>
              </ProtectedRoute>
            </Route>
          );
        });
      })}
      <Route path="/upd_tmp_pswd" exact component={UpdTmpPswd}></Route>
      <Route path="*" component={PageNotFound}></Route>
    </Switch>
  );
};

export default Main;
