import { useState } from "react";
import { Popup } from "semantic-ui-react";
import { ReactComponent as ArrowIcon } from "../../images/arrow.svg";
import { Link, useLocation } from "react-router-dom";
import "./Sidebar.scss";
import { items } from "../../data/routes";
import { perms } from "../../api/codes";
import { ProtectedElement } from "../ProtectedElement/ProtectedElement";

export default function Sidebar() {
  const [open, setOpen] = useState(window.innerWidth > 500);
  document.documentElement.style.setProperty(
    "--sidebar-width",
    open ? "270px" : "80px"
  );

  return (
    <div className={"sidebar " + (open ? "open" : "")}>
      <button
        className="sidebar-btn"
        onClick={() => {
          if (open) {
            setOpen(false);
          } else {
            setOpen(true);
          }
        }}
      >
        <ArrowIcon />
      </button>
      <div className="sidebar-body">
        {items.map((item, index) => (
          <ProtectedElement
            key={index}
            module={
              item.childrens
                ? item.childrens
                    .filter((ch) => ch.module)
                    .map((ch) => ch.module)
                : item.module
            }
            perm={perms.viewList}
          >
            <SidebarItem item={item} sidebarState={open} />
          </ProtectedElement>
        ))}
      </div>
    </div>
  );
}

function SidebarItem({ item, sidebarState }) {
  const location = useLocation();
  return (
    <Link
      to={item.path}
      className={
        "sidebar-item " +
        (location.pathname.startsWith(item.path) ? " active " : " ") +
        (!sidebarState ? "bar-close" : "")
      }
    >
      <div className="sidebar-title">
        <div className="item-left">
          {item.icon && sidebarState === false && (
            <Popup
              className="sidebar-popup"
              content={item.title}
              position="right center"
              on={["hover"]}
              trigger={
                <button
                  className={"sidebar-icon " + (!sidebarState && "bar-close")}
                >
                  <item.icon height={15} width={15} />
                </button>
              }
            />
          )}
          {item.icon && sidebarState === true && (
            <button
              className={"sidebar-icon " + (!sidebarState && "bar-close")}
            >
              <item.icon height={15} width={15} />
            </button>
          )}
          {sidebarState && (
            <span style={{ textTransform: "capitalize" }}>{item.title}</span>
          )}
        </div>
      </div>
    </Link>
  );
}
