import { useState } from "react";
import "./ActionDropdown.scss";
import "./DeleteConfirmModal.scss";
import { ReactComponent as DeleteIcon } from "../../images/delete.svg";
import { api } from "../../api/api";
import Modal from "../Modal/Modal";
import { useToast } from "../Toast";
import unknownError from "../../utils/unknownError";
import { ProtectedElement } from "../ProtectedElement/ProtectedElement";
import { perms } from "../../api/codes";

const DeleteConfirmModal = ({
  data,
  setTableData,
  dropdownCloser,
  route,
  refetch,
  module,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const toast = useToast();
  return (
    <ProtectedElement module={module} perm={perms.delete}>
      <Modal
        open={modalOpen}
        onOpen={() => {
          setModalOpen(true);
        }}
        trigger={
          <div className="option">
            <div className="icon-btn red-icon">
              <DeleteIcon />
            </div>
            <div className="red-title">Delete</div>
          </div>
        }
        className="modal-window small-modal"
      >
        <div className="modal-content">
          <div className="modal-form">
            <h1>
              <b>Are you sure?</b>
            </h1>
          </div>
          <div className="delete-modal-actions">
            <button
              className="btn-red"
              onClick={() => {
                setModalOpen(false);
              }}
            >
              No
            </button>
            <button
              className="btn"
              onClick={() => {
                if (data) {
                  try {
                    api
                      .delete(route + data.id + "/", {
                        headers: { module: module },
                      })
                      .then((_res) => {
                        if (setTableData) {
                          setTableData((v) =>
                            v.filter((d) => d.id !== data.id)
                          );
                          toast.open(
                            "Deleted data with id: " + data.id,
                            "success"
                          );
                        }
                        if (refetch) {
                          refetch();
                          toast.open(
                            "Deleted data with id: " + data.id,
                            "success"
                          );
                        }
                      })
                      .catch((err) => unknownError(err));
                  } catch (err) {
                    unknownError(err);
                  }
                }
                setModalOpen(false);
                dropdownCloser(false);
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>
    </ProtectedElement>
  );
};

export default DeleteConfirmModal;
