import "./Loading.scss";
import { ReactComponent as LoadingIcon } from "../../images/loading.svg";

const Loading = () => {
  return (
    <div className="loading">
      <LoadingIcon height={80} width={80} />
    </div>
  );
};

export default Loading;
