import { useEffect, useState } from "react";
import { api } from "../../../api/api";
import {
  InputCheckBox,
  InputDate,
  InputFile,
  InputText,
  InputTextBox,
} from "../../../components/Inputs/Inputs";
import Modal from "../../../components/Modal/Modal";
import { useToast } from "../../../components/Toast";
import { seller_docs, seller_docs_master } from "../../../api/urls";
import unknownError from "../../../utils/unknownError";
import { urlTofile } from "../../../utils/urlTofile";
import objToFormdata from "../../../utils/objToFormdata";
import { APIDropdown } from "../../../components/APIDropdown/APIDropdown";
import { moveUpToError } from "../../../utils/moveUpToError";

const initialState = {
  code: "",
  printable_name: "",
  description: "",
  doc_name: "",
  doc_number: "",
  doc_expiry: "",
  doc_file: "",
  is_verified: false,
  doc_kyc: false,
  doc_certificate: true,
  doc_type: "",
};

const NewSellerCertModal = ({ trigger, refetch, editData, seller_id }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const toast = useToast();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (editData) {
      const tmp = async () => {
        const newData = { ...editData };
        newData.doc_file = await urlTofile(newData.doc_file);
        setData(newData);
      };
      tmp();
    }
  }, [editData]);

  useEffect(() => {
    if (open === false && !editData) {
      setData({ ...initialState });
      setErrors({});
    }
  }, [open, setData, setErrors, editData]);

  const set = (key, value) => {
    setData((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };

  const view = false;

  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      trigger={trigger}
      className="modal-window"
      overlayClassName="no-overlay"
    >
      <form
        className="modal-content"
        onSubmit={(e) => {
          e.preventDefault();
          setIsSaving(true);
          const newData = { ...data };
          newData["seller"] = seller_id;
          try {
            if (editData) {
              api
                .patch(seller_docs + data.id + "/", objToFormdata(newData))
                .then((res) => {
                  refetch();
                  setOpen(false);
                  toast.open("Edited data with id: " + res.data.id, "success");
                  setIsSaving(false);
                })
                .catch((err) => {
                  if (err?.response?.data) {
                    setErrors(err.response.data);
                    moveUpToError();
                  } else {
                    unknownError(err);
                  }
                  setIsSaving(false);
                });
            } else {
              api
                .post(seller_docs, objToFormdata(newData))
                .then((res) => {
                  refetch();
                  setOpen(false);
                  toast.open("Data added successfully", "success");
                  setIsSaving(false);
                })
                .catch((err) => {
                  if (err?.response?.data) {
                    setErrors(err.response.data);
                    moveUpToError();
                  } else {
                    unknownError(err);
                  }
                  setIsSaving(false);
                });
            }
          } catch (err) {
            unknownError(err);
            setIsSaving(false);
          }
        }}
      >
        <div className="modal-heading">Seller KYC Certificate</div>
        <div className="modal-form">
          <div id="form-wrapper">
            <div className="twoside-form">
              <div className="formside">
                <InputText
                  label="Code"
                  placeholder="Code"
                  disabled={view}
                  required
                  value={data.code}
                  onChange={(v) => set("code", v)}
                  error={errors["code"]}
                />
                <InputText
                  label="Printable name"
                  placeholder="Printable name"
                  disabled={view}
                  required
                  value={data.printable_name}
                  onChange={(v) => set("printable_name", v)}
                  error={errors["printable_name"]}
                />
                <InputTextBox
                  label="Description"
                  placeholder="Description"
                  disabled={view}
                  required
                  value={data.description}
                  onChange={(v) => set("description", v)}
                  error={errors["description"]}
                />
                <InputText
                  label="Document name"
                  placeholder="Document name"
                  disabled={view}
                  required
                  value={data.doc_name}
                  onChange={(v) => set("doc_name", v)}
                  error={errors["doc_name"]}
                />
                <APIDropdown
                  label="Document type"
                  placeholder="Document type"
                  required
                  disabled={view}
                  value={data.doc_type}
                  url={seller_docs_master + "?doc=certify"}
                  onChange={(v) => set("doc_type", v)}
                  error={errors["doc_type"]}
                />
              </div>
              <div className="formside">
                <InputText
                  label="Document number"
                  placeholder="Document number"
                  required
                  disabled={view}
                  value={data.doc_number}
                  onChange={(v) => set("doc_number", v)}
                  error={errors["doc_number"]}
                />
                <InputDate
                  label="Document expiry date"
                  placeholder="Document expiry date"
                  required
                  disabled={view}
                  value={data.doc_expiry}
                  onChange={(v) => set("doc_expiry", v)}
                  error={errors["doc_expiry"]}
                />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <InputFile
                    label="Upload document"
                    placeholder="Upload document"
                    required
                    disabled={view}
                    value={data.doc_file}
                    onChange={(v) => set("doc_file", v)}
                    error={errors["doc_file"]}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "small",
                      color: "#64646499",
                      alignSelf: "stretch",
                    }}
                  >
                    Format pdf, docx, png, jpg
                  </div>
                </div>
                <InputCheckBox
                  label="Verified"
                  color="#5AA100"
                  disabled={view}
                  value={data.is_verified}
                  onChange={(v) => set("is_verified", v)}
                />
              </div>
            </div>
            <div style={{ padding: "10px" }} />
          </div>
        </div>
        <div className="modal-actions">
          <div className="actions">
            <button
              className="btn-red"
              type="button"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </button>
            <button
              className="btn"
              type="submit"
              onClick={() => {
                moveUpToError();
              }}
              disabled={isSaving}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default NewSellerCertModal;
