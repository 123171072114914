import { Header } from "semantic-ui-react";
import BackButton from "../../../components/BackButton/BackButton";
import { useState } from "react";
import {
  InputDate,
  InputFile,
  InputText,
} from "../../../components/Inputs/Inputs";
import { useFetch } from "../../../utils/useFetch";
import { b2b_documents_url, b2b_form_feilds_url } from "../../../api/urls";
import Loading from "../../../components/Loading/Loading";
import { moveUpToError } from "../../../utils/moveUpToError";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { api } from "../../../api/api";
import unknownError from "../../../utils/unknownError";
import { useToast } from "../../../components/Toast";
import { generateUID } from "../../../utils/generateUID";
import { serialize } from "../../../utils/serialize";

const title = "Add New B2B";
const backUrl = "/users/tagon_customers";

export function NewB2B({ view }) {
  const [data, setData] = useState({});
  const [errors] = useState({});
  const fields = useFetch(b2b_form_feilds_url);
  const [isSaving, setIsSaving] = useState(false);
  const history = useHistory();

  const params = useParams();
  const userId = params.id;
  const toast = useToast();

  const set = (key, value) => {
    setData((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };

  if (fields.loading || !fields.data) return <Loading />;

  return (
    <form
      id="form-wrapper"
      onSubmit={async (e) => {
        e.preventDefault();
        setIsSaving(true);

        try {
          for (let i = 0; i < fields.data.length; i++) {
            const field = fields.data[i];
            const name = field.doc_name;
            await api.post(
              b2b_documents_url,
              serialize({
                code: generateUID(),
                description: generateUID(),
                printable_name: generateUID(),
                disable: false,
                is_active: true,
                doc_name: name,
                doc_number: data[`${name}_number`],
                doc_expiry: data[`${name}_expiry`],
                doc_file: data[`${name}_file`],
                is_verified: false,
                doc_kyc: field.doc_kyc,
                doc_certificate: field.doc_certificate,
                doc_type: field.id,
                b2buser: userId,
              })
            );
          }
          toast.open("Success", "success");
          history.push(backUrl);
        } catch (e) {
          if (e?.response?.status === 400 && e?.response?.data?.data) {
            toast.open(e?.response?.data.data, "error");
          } else {
            unknownError(e);
          }
        }
        setIsSaving(false);
      }}
    >
      <div id="form">
        <BackButton href={backUrl} />
        <Header>{title}</Header>
        {fields.data.map((field) => {
          const name = field.doc_name;
          return (
            <>
              {field.doc_num_require && (
                <InputText
                  label={`${name} number`}
                  placeholder={`${name} number`}
                  disabled={view}
                  value={data[`${name}_number`]}
                  error={errors[`${name}_number`]}
                  required
                  onChange={(v) => set(`${name}_number`, v)}
                />
              )}
              {field.expiryrequire && (
                <InputDate
                  label={`${name} expiry`}
                  placeholder={`${name} expiry`}
                  disabled={view}
                  value={data[`${name}_expiry`]}
                  error={errors[`${name}_expiry`]}
                  required
                  onChange={(v) => set(`${name}_expiry`, v)}
                />
              )}
              {field.filerequire && (
                <InputFile
                  label={`${name} file`}
                  placeholder={`${name} file`}
                  disabled={view}
                  value={data[`${name}_file`]}
                  error={errors[`${name}_file`]}
                  required
                  onChange={(v) => set(`${name}_file`, v)}
                />
              )}
            </>
          );
        })}
        {!view && (
          <>
            <div style={{ padding: "10px" }} />
            <div className="actions">
              <button
                className="btn-red"
                type="button"
                onClick={() => {
                  history.push(backUrl);
                }}
              >
                Cancel
              </button>
              <button
                className="btn"
                onClick={() => {
                  moveUpToError();
                }}
                disabled={isSaving}
              >
                Save
              </button>
            </div>
          </>
        )}
        <div style={{ padding: "10px" }} />
      </div>
    </form>
  );
}
