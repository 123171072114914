import { useEffect, useState } from "react";
import { ReactComponent as ArrowIcon } from "../../../images/arrow.svg";
import BackButton from "../../../components/BackButton/BackButton";
import { Header } from "semantic-ui-react";
import {
  InputCheckBox,
  InputDate,
  InputFile,
  InputImage,
  InputNumber,
  InputSelect,
  InputText,
  InputTextBox,
  InputToggle,
} from "../../../components/Inputs/Inputs";
import MultipleImages from "../../../components/MultipleImages/MultipleImages";
import "./new_product.scss";
import { TableComponent } from "../../../components/Table/table";
import { ReactComponent as DeleteIcon } from "../../../images/delete.svg";
import Timeline from "../../../components/timeline/timeline";
import { useModal } from "../../../components/Modaler";
import {
  Keywords,
  keywordsToString,
  stringToKeyword,
} from "../../../components/Keywords/Keywords";
import { generateUID } from "../../../utils/generateUID";
import { useFetch } from "../../../utils/useFetch";
import {
  bbrand_url,
  bfirst_category_url,
  bproduct_add_url,
  brand_url,
  bsecond_category_url,
  bseller_url,
  bthird_category_url,
  charges_url,
  color_url,
  country_url,
  first_category_url,
  gbrand_url,
  gfirst_category_url,
  gproduct_add_url,
  gsecond_category_url,
  gseller_url,
  gthird_category_url,
  product_add_url,
  product_info_url,
  product_property_type_url,
  product_property_value_url,
  sac_tax_code_url,
  sac_tax_percent_url,
  second_category_url,
  seller_address,
  seller_url,
  size_chart_column_url,
  size_url,
  tax_code_url,
  tax_percent_url,
  third_category_url,
} from "../../../api/urls";
import { idToname } from "../../../utils/idToname";
import { nameToid } from "../../../utils/nameToid";
import { api } from "../../../api/api";
import unknownError from "../../../utils/unknownError";
import { serialize } from "../../../utils/serialize";
import { useHistory } from "react-router-dom";
import { useToast } from "../../../components/Toast";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { urlTofile } from "../../../utils/urlTofile";
import Loading from "../../../components/Loading/Loading";
import Button from "../../../components/Button/Button";
import { sellerAddressFormatter } from "../../../utils/addresssFormatter";
import { APIDropdown } from "../../../components/APIDropdown/APIDropdown";
import cloneDeep from "lodash.clonedeep";
import { createToast } from "../../../components/Toast/ToastProvider";
import { moveUpToError } from "../../../utils/moveUpToError";
import { areYouSure } from "../../../utils/areYouSure";
import { objToParams } from "../../../utils/objToParams";
import { modules } from "../../../api/codes";
import { DashboardImage } from "../../../components/DashboardImage/DashboardImage";

const delSlug = "parameter_del";
const initialData = {
  stock: "0",
  style_code: "these field would be deleted",
  product_inventory: [
    {
      id: generateUID(),
      code: generateUID(),
      printable_name: generateUID(),
      is_active: true,
    },
  ],
  chart_columns: [],
};
const debug = false;
const module = modules.Product;
const globalModule = modules.GlobalProduct;

function filterDataBeforeSave(paramData, step) {
  const data = { ...paramData };
  let ok = true;
  if (data) {
    if (step === 2 && data.parameter) {
      data.parameter.forEach((info, idx) => {
        data.parameter[idx] = { ...data.parameter[idx] };
        data.parameter[idx].paramater_product_type = [
          ...info.paramater_product_type.filter(
            (f) =>
              f?.product_property_type &&
              f?.product_property_value?.length &&
              f?.product_property_value?.length > 0
          ),
        ];
      });
      data.parameter = data.parameter.filter(
        (p) =>
          p.paramater_product_type?.length &&
          p.paramater_product_type?.length > 0
      );
    }
    if (
      step === 3 &&
      data.product_inventory &&
      data.product_inventory?.length > 0
    ) {
      data.product_inventory.forEach((pi, pidx) => {
        if (pi.price_tax && pi.price_tax?.length > 0) {
          pi.price_tax.forEach((pt, ptidx) => {
            if (pt.charges_of_product && pt.charges_of_product?.length > 0) {
              data.product_inventory[pidx].price_tax[ptidx].charges_of_product =
                pt.charges_of_product.filter((cp) => cp.charge_name);
            }
          });
        }
      });
    }
  }
  if (!ok) return false;
  return data;
}

async function filterDataAfterFetch(paramData) {
  const data = { ...paramData };
  if (data) {
    if (data?.product_inventory && data?.product_inventory?.length > 0) {
      data.product_inventory = await Promise.all(
        data.product_inventory.map(async (pi) => {
          const newPI = { ...pi };
          newPI.image01 = await urlTofile(newPI.image01);
          newPI.image02 = await urlTofile(newPI.image02);
          newPI.image03 = await urlTofile(newPI.image03);
          newPI.image04 = await urlTofile(newPI.image04);
          newPI.image05 = await urlTofile(newPI.image05);
          newPI.image06 = await urlTofile(newPI.image06);
          newPI.image07 = await urlTofile(newPI.image07);
          newPI.image08 = await urlTofile(newPI.image08);
          newPI.video = await urlTofile(newPI.video);
          return newPI;
        })
      );
    } else {
      data.product_inventory = [
        {
          id: generateUID(),
          code: generateUID(),
          printable_name: generateUID(),
          is_active: true,
        },
      ];
    }
    if (data?.data_upload) {
      data.data_upload = await urlTofile(data.data_upload);
    }
    if (data.has_size) {
      const set = new Set();
      data.size_chart_data.forEach((d) =>
        d.column_data?.forEach((c) => set.add(c?.column_name))
      );
      data.chart_columns = Array.from(set);
    }
    if (!data.chart_columns) {
      data.chart_columns = [];
    }
    if (data?.size_chart) {
      data.size_chart = await urlTofile(data.size_chart);
    }
  }
  return data;
}

const NewProduct = ({ edit, view, copy, isGlobal, isB2B }) => {
  const apiUrl = isB2B
    ? bproduct_add_url
    : isGlobal
    ? gproduct_add_url
    : product_add_url;
  const backUrl = isB2B
    ? "/b2b_store/products"
    : isGlobal
    ? "/global_store/products"
    : "/products";
  const [timeline, setTimeline] = useState([
    {
      name: "Product detail",
      complete: false,
      open: !(edit || view),
      component: ProductDetails,
    },
    {
      name: "Product specification",
      complete: false,
      open: false,
      component: ProductSpecification,
    },
    {
      name: "Variant & Price",
      complete: false,
      open: false,
      component: Variant,
    },
    { name: "Policies", complete: false, open: false, component: Policies },
    { name: "You may like", complete: false, open: false, component: Like },
  ]);

  const history = useHistory();
  const toast = useToast();
  const params = useParams();
  const [isGetting, setIsGetting] = useState(true);

  useEffect(() => {
    if (edit || view || params.id) {
      const getData = async () => {
        try {
          const res = await api.get(apiUrl + params.id + "/", {
            headers: { module: isGlobal ? globalModule : module },
          });
          const filteredData = await filterDataAfterFetch(res.data);
          if (copy) {
            const code = filteredData?.code;
            changeCode(filteredData);
            filteredData.code = code;
          } else {
          }
          setData(filteredData);
          setIsGetting(false);
        } catch (err) {
          unknownError(err);
        }
      };

      getData();
    } else {
      setIsGetting(false);
    }
  }, [params.id, edit, view, copy, apiUrl, isGlobal]);

  const setOpen = (idx, open) => {
    setTimeline((prev) => {
      const newData = [...prev];
      newData[idx].open = open;
      return newData;
    });
  };

  const setComplete = (idx, complete = true) => {
    setTimeline((prev) => {
      const newData = [...prev];
      newData[idx].complete = complete;
      return newData;
    });
  };

  const [data, setData] = useState(initialData);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <BackButton href={backUrl} />
      <Header>{edit ? "Edit Product" : "Add New Product"}</Header>
      {/* <pre className="code">{JSON.stringify(data, null, 2)}</pre> */}
      {!edit && !view && <Timeline steps={timeline} />}
      {debug && (
        <div className="two-inputs">
          <pre className="code">{JSON.stringify(data, null, 2)}</pre>
        </div>
      )}
      {isGetting && <Loading />}
      {!isGetting &&
        timeline.map((tl, idx) => (
          <Accordian
            key={idx}
            head={tl.name}
            body={
              <tl.component
                edit={idx !== 0 || tl.complete || edit}
                view={view}
                data={data}
                isGlobal={isGlobal}
                isB2B={isB2B}
                isOpen={tl.open}
                setData={setData}
                apiUrl={apiUrl}
                onSave={() => {
                  setComplete(idx);
                  setOpen(idx, false);
                  if (!edit) {
                    if (idx === timeline.length - 1) {
                      history.push(backUrl);
                      toast.open("Data submitted successfully", "success");
                    } else {
                      setOpen(idx + 1, true);
                    }
                  } else {
                    toast.open("Data updated successfully", "success");
                  }
                }}
              />
            }
            open={tl.open}
            setOpen={(open) => setOpen(idx, open)}
            noControl={
              idx === 0 || debug || edit || view
                ? false
                : !timeline[idx - 1].complete
            }
          />
        ))}
      <div className="p-10" />
      {/* <pre className="code">{makeformdata()}</pre> */}
    </div>
  );
};

function dataByStep(data, step) {
  if (step === 1) {
    return {
      code: data.code,
      printable_name: data.printable_name,
      stock: data.stock,
      style_code: data.style_code,
      seller_details: data.seller_details,
      brand_details: data.brand_details,
      title: data.title,
      short_description: data.short_description,
      skuid: data.skuid,
      product_origin_country: data.product_origin_country,
      description: data.description,
      first_category: data.first_category,
      second_category: data.second_category,
      third_category: data.third_category,
      duration_end: data.duration_end,
      deal_start: data.deal_start,
      deal_end: data.deal_end,
      search_keys: data.search_keys,
      product_country: data.product_country,
    };
  }
  if (step === 2) {
    return {
      parameter: data.parameter,
      data_upload: data.data_upload,
      file_upload: data.file_upload,
    };
  }
  if (step === 3) {
    return {
      product_inventory: data.product_inventory,
      has_size: data.has_size,
      has_color: data.has_color,
      size: data.size,
      colors: data.colors,
      size_chart_data: data.size_chart_data,
      size_chart: data.size_chart,
    };
  }
  if (step === 4) {
    return {
      is_cancellable: data.is_cancellable,
      is_refundable: data.is_refundable,
      is_returnable: data.is_returnable,
      return_span: data.return_span,
      is_replacable: data.is_replacable,
      replace_span: data.replace_span,
      no_replace: data.no_replace,
      is_active: data.is_active,
      is_global: data.is_global,
      qty_can_order: data.qty_can_order,
    };
  }
  if (step === 5) {
    return {
      you_may_like: data.you_may_like,
    };
  }
}

const ProductDetails = ({
  onSave,
  edit,
  view,
  data,
  setData,
  isGlobal,
  isB2B,
  apiUrl,
}) => {
  const [errors, setErrors] = useState({});
  const { data: thirds } = useFetch(
    isB2B
      ? bthird_category_url
      : isGlobal
      ? gthird_category_url
      : third_category_url +
        (data.second_category
          ? `?category_second=${data.second_category}`
          : ""),
    isGlobal ? globalModule : module
  );
  const [saving, setSaving] = useState(false);
  const { data: countrys } = useFetch(
    country_url,
    isGlobal ? globalModule : module
  );

  const [delData, setDelData] = useState({});
  const setDelete = (key, id) => {
    if (!isRealId(id)) return;
    if (!id) return;
    const newDelData = { ...delData };
    if (newDelData[key]) {
      newDelData[key] = [id, ...newDelData[key]];
    } else {
      newDelData[key] = [id];
    }
    setDelData(newDelData);
  };

  const set = (key, value) => {
    setData((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setSaving(true);
        const newData = dataByStep(filterDataBeforeSave({ ...data }), 1);
        if (isGlobal) {
          newData.is_global = true;
        } else {
          newData.is_global = false;
        }
        if (isB2B) {
          newData.is_b2b = true;
        } else {
          newData.is_b2b = false;
        }
        try {
          if (edit) {
            api
              .patch(apiUrl + data.id + "/", serialize(newData), {
                headers: { module: isGlobal ? globalModule : module },
              })
              .then((res) => {
                if (delData && Object.keys(delData).length) {
                  api
                    .patch(apiUrl + data.id + `/${delSlug}/`, delData, {
                      headers: { module: isGlobal ? globalModule : module },
                    })
                    .then((res) => {
                      onSave();
                      setSaving(false);
                    })
                    .catch((err) => {
                      unknownError(err);
                      setSaving(false);
                    });
                } else {
                  onSave();
                  setSaving(false);
                }
              })
              .catch((err) => {
                if (err?.response?.data) {
                  setErrors(err.response.data);
                  setSaving(false);
                  moveUpToError();
                } else {
                  unknownError(err);
                  setSaving(false);
                }
              });
          } else {
            api
              .post(apiUrl, serialize(newData), {
                headers: { module: isGlobal ? globalModule : module },
              })
              .then((res) => {
                onSave();
                set("id", res.data.id);
                setSaving(false);
              })
              .catch((err) => {
                if (err?.response?.data) {
                  setErrors(err.response.data);
                  setSaving(false);
                  moveUpToError();
                } else {
                  unknownError(err);
                  setSaving(false);
                }
              });
          }
        } catch (err) {
          unknownError(err);
          setSaving(false);
        }
      }}
    >
      <div className="form-grid">
        <APIDropdown
          module={isGlobal ? globalModule : module}
          label="Seller"
          placeholder="Seller"
          required
          disabled={view}
          value={data.seller_details}
          url={isB2B ? bseller_url : isGlobal ? gseller_url : seller_url}
          onChange={(v) => {
            set("seller_details", v);
            set("brand_details", "");
            set("first_category", "");
          }}
          error={errors["seller_details"]}
        />
        <APIDropdown
          module={isGlobal ? globalModule : module}
          label="Brand"
          placeholder="Brand"
          required
          disabled={view}
          value={data.brand_details}
          url={
            (isB2B ? bbrand_url : isGlobal ? gbrand_url : brand_url) +
            "?" +
            objToParams({
              global: isGlobal ? "true" : "false",
              seller: data.seller_details,
            })
          }
          onChange={(v) => set("brand_details", v)}
          error={errors["brand_details"]}
        />
      </div>
      <div style={{ padding: "5px" }} />
      <div className="form-grid">
        <InputText
          label="Product code"
          placeholder="Product code"
          required
          disabled={view}
          value={data.code}
          onChange={(v) => {
            set("code", v);
          }}
          error={errors["code"]}
        />
        <InputText
          label="Product title"
          placeholder="Product title"
          value={data.title}
          disabled={view}
          required
          onChange={(v) => {
            set("title", v);
            set("printable_name", v);
          }}
          error={errors["title"]}
        />
        <InputText
          label="Short description"
          placeholder="Short description"
          value={data.short_description}
          required
          disabled={view}
          onChange={(v) => set("short_description", v)}
          error={errors["short_description"]}
        />
        <InputText
          label="SKU id"
          placeholder="SKU id"
          required
          disabled={view}
          value={data.skuid}
          onChange={(v) => set("skuid", v)}
          error={errors["skuid"]}
        />
        <InputSelect
          label="Country of origin"
          placeholder="Country of origin"
          options={countrys ? countrys.map((d) => d.printable_name) : []}
          value={data.product_origin_country}
          disabled={view}
          onChange={(v) => set("product_origin_country", v)}
          error={errors["product_origin_country"]}
        />

        <APIDropdown
          module={isGlobal ? globalModule : module}
          label="Country availability"
          placeholder="Country availability"
          required
          disabled={view}
          value={data.product_country}
          beforeRemoveMsg={false}
          url={
            country_url +
            (data.seller_details ? `?seller=${data.seller_details}` : "")
          }
          onChange={(v) => {
            if (edit) {
              areYouSure(() => {
                set("product_country", v);
              }, "Are you sure, If you change this field you have to make changes in price and tax for all varients.");
            } else {
              set("product_country", v);
            }
          }}
          error={errors["product_country"]}
          hasMultipleSelection
          onDelete={(v) => setDelete("product_country_del", v)}
        />
      </div>
      <div style={{ padding: "5px" }} />
      <div className="twoside-form">
        <InputTextBox
          label="Description"
          placeholder="Description"
          required
          value={data.description}
          disabled={view}
          onChange={(v) => set("description", v)}
          error={errors["description"]}
        />
      </div>
      <div className="p-5" />
      <div className="twoside-form">
        <Keywords
          label="Key words"
          placeholder="Key words"
          value={stringToKeyword(data.search_keys)}
          onChange={(v) => set("search_keys", keywordsToString(v))}
          disabled={view}
          error={errors["search_keys"]}
        />
      </div>
      <div className="p-10" />
      <div className="psubtitle">Product category</div>
      <div className="form-grid">
        <APIDropdown
          module={isGlobal ? globalModule : module}
          label="Category 1st"
          placeholder="Select the category 1st"
          url={
            (isB2B
              ? bfirst_category_url
              : isGlobal
              ? gfirst_category_url
              : first_category_url) +
            "?" +
            objToParams({
              seller: data.seller_details,
            })
          }
          value={data.first_category}
          onChange={(v) => {
            set("first_category", v);
          }}
          error={errors["first_category"]}
          disabled={view}
          required
        />
        <APIDropdown
          module={isGlobal ? globalModule : module}
          label="Category 2nd"
          placeholder="Select the category 2nd"
          url={
            (isB2B
              ? bsecond_category_url
              : isGlobal
              ? gsecond_category_url
              : second_category_url) +
            "?" +
            objToParams({
              seller: data.seller_details,
              category_first: data.first_category,
            })
          }
          value={data.second_category}
          onChange={(v) => {
            set("second_category", v);
          }}
          error={errors["second_category"]}
          disabled={view}
          required
        />
        <APIDropdown
          module={isGlobal ? globalModule : module}
          label="Category 3rd"
          placeholder="Select the category 3rd"
          url={
            (isB2B
              ? bthird_category_url
              : isGlobal
              ? gthird_category_url
              : third_category_url) +
            "?" +
            objToParams({
              seller: data.seller_details,
              category_second: data.second_category,
            })
          }
          value={data.third_category}
          onChange={(v) => {
            const id = v;
            set("third_category", id);
            if (!data.return_span) {
              set("return_span", idToname(id, thirds, "return_span"));
            }
            if (!data.replace_span) {
              set("replace_span", idToname(id, thirds, "replace_span"));
            }
          }}
          error={errors["third_category"]}
          disabled={view}
          required
        />
      </div>
      <div className="p-10" />
      <div className="psubtitle">Limited time duration</div>
      <div className="form-grid">
        <InputDate
          type="datetime-local"
          label="Limited time duration end"
          placeholder="Date"
          disabled={view}
          value={data.duration_end}
          onChange={(v) => set("duration_end", v)}
          error={errors["duration_end"]}
          required
        />
      </div>
      <div className="p-10" />
      <div className="psubtitle">Deal start & end</div>
      <div className="form-grid">
        <InputDate
          type="datetime-local"
          label="Deal start"
          placeholder="Deal start"
          disabled={view}
          value={data.deal_start}
          onChange={(v) => set("deal_start", v)}
          error={errors["duration_start"]}
        />
        <InputDate
          type="datetime-local"
          label="Deal end"
          placeholder="Deal end"
          value={data.deal_end}
          disabled={view}
          onChange={(v) => set("deal_end", v)}
          error={errors["deal_end"]}
        />
      </div>
      {!view && (
        <>
          <div style={{ padding: "10px" }} />
          <div className="twoside-form">
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
                gap: "10px",
                width: "min-content",
              }}
            >
              <Button
                type="submit"
                isLoading={saving}
                onClick={() => {
                  moveUpToError();
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </>
      )}
    </form>
  );
};

const tabCodes = ["g_info", "a_info", "t_info", "o_info"];
const ProductSpecification = ({
  data,
  setData,
  onSave,
  edit,
  isOpen,
  view,
  apiUrl,
  isGlobal,
  isB2B,
}) => {
  const [active, setActive] = useState(0);
  const { data: tabTitles } = useFetch(
    product_info_url,
    isGlobal ? globalModule : module
  );
  const [tabs, setTabs] = useState();
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState({});
  const toast = useToast();

  const [delData, setDelData] = useState({});
  const setDelete = (key, id) => {
    if (!isRealId(id)) return;
    if (!id) return;
    const newDelData = { ...delData };
    if (newDelData[key]) {
      newDelData[key] = [id, ...newDelData[key]];
    } else {
      newDelData[key] = [id];
    }
    setDelData(newDelData);
  };

  useEffect(() => {
    if (isOpen) {
      if (tabTitles) {
        setTabs(
          tabCodes.map((tc) => {
            return tabTitles.find((d) => d.code === tc)?.name;
          })
        );
        if (!data.parameter) {
          const newData = { ...data };
          newData.parameter = tabCodes.map((tc) => {
            return {
              id: generateUID(),
              code: generateUID(),
              printable_name: generateUID(),
              product_info: tabTitles.find((d) => d.code === tc)?.id,
              is_active: true,
              disable: false,
              paramater_product_type: [{ id: generateUID() }],
            };
          });
          setData(newData);
        } else {
          let ok = true;
          data.parameter.forEach((p) => {
            ok &=
              p?.paramater_product_type &&
              p?.paramater_product_type?.length > 0;
          });
          if (!ok) {
            const newData = { ...data };
            newData.parameter = newData.parameter.map((p) => {
              if (
                p?.paramater_product_type &&
                p?.paramater_product_type?.length > 0
              ) {
                return { ...p };
              }
              const newP = { ...p };
              newP.paramater_product_type = [{ id: generateUID() }];
              return newP;
            });
            setData(newData);
          }
          ok = true;
          if (data?.parameter && data.parameter?.length >= 4) {
            tabCodes.forEach((tc, idx) => {
              ok &=
                idToname(
                  data?.parameter[idx]?.product_info,
                  tabTitles,
                  "code"
                ) === tc;
            });
          } else {
            ok = false;
          }
          if (!ok) {
            const newData = { ...data };
            const prevData = [...data.parameter];
            newData.parameter = [];
            tabCodes.forEach((tc) => {
              newData.parameter.push(
                prevData.find(
                  (pd) => pd.product_info === nameToid(tc, tabTitles, "code")
                ) || {
                  id: generateUID(),
                  code: generateUID(),
                  printable_name: generateUID(),
                  product_info: tabTitles.find((d) => d.code === tc)?.id,
                  is_active: true,
                  disable: false,
                  paramater_product_type: [{ id: generateUID() }],
                }
              );
            });
            setData(newData);
          }
        }
      }
    }
  }, [tabTitles, data, setData, isOpen]);

  if (!tabTitles || !tabs || !data.parameter) {
    return (
      <div
        className="twoside-form"
        style={{
          height: "200px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (!isValidParameter(data)) {
          toast.open("Every label should have a value.", "error");
          return;
        }
        const newData = dataByStep(filterDataBeforeSave({ ...data }, 2), 2);
        try {
          api
            .patch(apiUrl + data.id + "/", serialize(newData), {
              headers: { module: isGlobal ? globalModule : module },
            })
            .then((res) => {
              if (delData && Object.keys(delData).length) {
                api
                  .patch(apiUrl + data.id + `/${delSlug}/`, delData, {
                    headers: { module: isGlobal ? globalModule : module },
                  })
                  .then((res) => {
                    onSave();
                    setSaving(false);
                  })
                  .catch((err) => {
                    if (err?.response?.data) {
                      setErrors(err.response.data);
                      setSaving(false);
                    } else {
                      unknownError(err);
                      setSaving(false);
                    }
                  });
              } else {
                onSave();
                setSaving(false);
              }
            })
            .catch((err) => {
              unknownError(err);
              setSaving(false);
            });
        } catch (err) {
          unknownError(err);
          setSaving(false);
        }
      }}
    >
      <div className="twoside-form">
        <div style={{ flex: 1 }}>
          <div className="ps-tabs">
            {tabs.map((tab, idx) => (
              <div
                key={idx}
                className={"ps-tab " + (active === idx ? "active" : "")}
                onClick={() => {
                  setActive(idx);
                }}
              >
                {tab}
              </div>
            ))}
          </div>
          <div style={{ padding: "5px" }} />
          <div
            className="color-blue"
            style={{
              marginLeft: "auto",
              width: "fit-content",
            }}
          >
            How to fill data
          </div>
          <div style={{ padding: "5px" }} />
          <PSTable
            key={active}
            data={data.parameter[active].paramater_product_type}
            upperCode={data.parameter[active].code}
            view={view}
            mainData={data}
            setDelete={setDelete}
            setData={(v) => {
              const newData = { ...data };
              newData.parameter[active].paramater_product_type = v;
              setData(newData);
            }}
            isGlobal={isGlobal}
            isB2B={isB2B}
          />
        </div>
      </div>

      <div className="p-5" />
      <InputCheckBox
        label="We have technical data of this product"
        value={data.file_upload}
        disabled={view}
        onChange={(v) => {
          const newData = { ...data };
          if (v === false) {
            newData.data_upload = "";
          }
          newData.file_upload = v;
          setData(newData);
        }}
      />
      {data.file_upload && (
        <>
          <div className="p-5" />
          <div className="twoside-form">
            <div className="formside">
              <InputFile
                label="Technical data sheet"
                placeholder="Technical data sheet"
                value={data.data_upload}
                required
                onChange={(v) => {
                  const newData = { ...data };
                  newData.data_upload = v;
                  setData(newData);
                }}
                disabled={view}
                error={errors["data_upload"]}
              />
            </div>
          </div>
        </>
      )}

      {!view && (
        <>
          <div style={{ padding: "10px" }} />
          <div className="twoside-form">
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
                gap: "10px",
                width: "min-content",
              }}
            >
              <Button
                type="submit"
                isLoading={saving}
                onClick={() => {
                  moveUpToError();
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </>
      )}
    </form>
  );
};

const isValidParameter = (data) => {
  let ok = true;
  data.parameter.forEach((info, idx) => {
    data.parameter[idx].paramater_product_type.forEach((f) => {
      if (f?.product_property_type) {
        if (
          !(
            f?.product_property_value?.length &&
            f?.product_property_value?.length > 0
          )
        ) {
          ok = false;
        }
      }
    });
  });
  return ok;
};

const PSTable = ({ data, setData, view, setDelete, mainData, isGlobal }) => {
  const PropertyType = useFetch(
    product_property_type_url + `?cathird=${mainData.third_category}`,
    isGlobal ? globalModule : module
  );
  const addNew = () => {
    setData([...data, { id: generateUID(), is_active: true }]);
  };
  return (
    <div className="ps-section">
      {!view && (
        <button
          type="button"
          className="button"
          style={{ marginLeft: "auto" }}
          onClick={() => addNew()}
        >
          Add New
        </button>
      )}
      <div style={{ padding: "5px" }} />
      <TableComponent
        noWrapper
        celled
        disableHeaderPopup
        showbottom={false}
        headers={(view ? ["Label", "Value"] : ["Label", "Value", "Action"]).map(
          (h) => (
            <div className="center">{h}</div>
          )
        )}
        body={data.map((d, idx) => [
          <div style={{ minWidth: "150px" }}>
            <APIDropdown
              module={isGlobal ? globalModule : module}
              placeholder="Label"
              disabled={view}
              value={d.product_property_type}
              apiData={PropertyType}
              parameterForGet="name"
              required
              onChange={(v) => {
                const newData = [...data];
                if (!newData[idx].code) {
                  newData[idx].code = generateUID();
                  newData[idx].printable_name = generateUID();
                  newData[idx].is_active = true;
                }
                newData[idx].product_property_type = v;

                setData(newData);
              }}
              onDelete={() => {
                setDelete("paramater_product_type_del", d.id);
                if (data.length > 1) {
                  setData(data.filter((x) => x.id !== d.id));
                } else {
                  setData([{ id: generateUID(), is_active: true }]);
                }
              }}
            />
          </div>,
          <div style={{ minWidth: "150px" }}>
            {d.product_property_type ? (
              <APIDropdown
                module={isGlobal ? globalModule : module}
                placeholder="Value"
                disabled={view}
                value={d.product_property_value?.[0]}
                url={
                  product_property_value_url +
                  `?proptype=${d.product_property_type}`
                }
                parameterForGet="name"
                required
                onChange={(v) => {
                  const newData = [...data];
                  newData[idx].product_property_value = [];
                  newData[idx].product_property_value[0] = v;
                  setData(newData);
                }}
              />
            ) : (
              <InputText placeholder="Value" disabled />
            )}
          </div>,
          !view && (
            <div
              className="center"
              style={{ flexDirection: "row", gap: "10px" }}
            >
              <div
                className="i-btn btn-red"
                style={{ padding: "10px" }}
                onClick={() => {
                  areYouSure(() => {
                    setDelete("paramater_product_type_del", d.id);
                    if (data.length > 1) {
                      setData(data.filter((x) => x.id !== d.id));
                    } else {
                      setData([{ id: generateUID(), is_active: true }]);
                    }
                  });
                }}
              >
                <DeleteIcon />
              </div>
            </div>
          ),
        ])}
      />
    </div>
  );
};

const Variant = ({
  onSave,
  edit,
  data,
  setData,
  view,
  apiUrl,
  isGlobal,
  isB2B,
}) => {
  const [saving, setSaving] = useState(false);
  const { data: sizes } = useFetch(size_url, isGlobal ? globalModule : module);
  const { data: colors } = useFetch(
    color_url,
    isGlobal ? globalModule : module
  );

  const [delData, setDelData] = useState({});
  const setDelete = (key, id) => {
    if (!isRealId(id)) return;
    if (!id) return;
    setDelData((v) => {
      const newDelData = { ...v };
      if (newDelData[key] && newDelData[key].length > 0) {
        newDelData[key] = [id, ...newDelData[key]];
      } else {
        newDelData[key] = [id];
      }
      return newDelData;
    });
  };

  const set = (key, value) => {
    setData((x) => ({
      ...x,
      [key]: value,
    }));
  };

  useEffect(() => {
    const newData = { ...data };
    const newProductInv = [];
    const sizes =
      data.size && data.size?.length && data.size?.length > 0
        ? data.size
        : [null];
    const colors =
      data.colors && data.colors?.length && data.colors?.length > 0
        ? data.colors
        : [null];
    colors.forEach((color) => {
      sizes.forEach((size) => {
        const findInv = data.product_inventory?.find(
          (x) => x.product_colors === color && x.product_size === size
        );
        if (findInv) {
          findInv.added = true;
          newProductInv.push(findInv);
        } else {
          newProductInv.push({
            code: generateUID(),
            printable_name: generateUID(),
            product_colors: color,
            product_size: size,
            is_active: true,
          });
        }
      });
    });
    data.product_inventory?.forEach((x) => {
      let ok = false;
      newProductInv.forEach((y) => {
        if (x.id === y.id) ok = true;
      });
      if (!ok) {
        setDelete("product_inventory_del", x.id);
      }
    });
    newData.product_inventory = newProductInv;
    setData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.size, data.colors]);

  const { data: countries } = useFetch(
    country_url + (data.seller_details ? `?seller=${data.seller_details}` : ""),
    isGlobal ? globalModule : module
  );

  const { data: addresses } = useFetch(
    data.seller_details
      ? seller_address + `?seller=${data.seller_details}`
      : "",
    isGlobal ? globalModule : module
  );

  const [copySelected, setCopySelected] = useState(undefined);

  const modaler = useModal();

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (!isValidVariation(data)) return;
        setSaving(true);
        const filteredData = filterDataBeforeSave({ ...data }, 3);
        if (!filteredData) {
          setSaving(false);
          return;
        }
        const newData = dataByStep(filteredData, 3);
        try {
          api
            .patch(apiUrl + data.id + "/", serialize(newData), {
              headers: { module: isGlobal ? globalModule : module },
            })
            .then(async (res) => {
              if (delData && Object.keys(delData).length) {
                api
                  .patch(apiUrl + data.id + `/${delSlug}/`, delData, {
                    headers: { module: isGlobal ? globalModule : module },
                  })
                  .then(async (res) => {
                    const filteredData = await filterDataAfterFetch(res.data);
                    setData(filteredData);
                    onSave();
                    setSaving(false);
                  })
                  .catch((err) => {
                    unknownError(err);
                    setSaving(false);
                  });
              } else {
                const filteredData = await filterDataAfterFetch(res.data);
                setData(filteredData);
                onSave();
                setSaving(false);
              }
            })
            .catch((err) => {
              unknownError(err);
              setSaving(false);
            });
        } catch (err) {
          unknownError(err);
          setSaving(false);
        }
      }}
    >
      <div className="psubtitle">Select Type of varients</div>
      {/* <pre className="code">{JSON.stringify(delData, null, 2)}</pre> */}
      <div style={{ padding: "5px" }} />
      <div className="twoside-form">
        <div className="formside">
          <InputCheckBox
            label="Has size"
            value={data.has_size}
            onChange={(v) => {
              modaler.opener(
                <div style={{ padding: "20px" }}>
                  <div style={{ fontWeight: "bold" }}>
                    Are you sure you want to change this, data you entered for
                    size would be deleted?
                  </div>
                  <div className="p-10" />
                  <div
                    style={{
                      marginLeft: "auto",
                      display: "flex",
                      gap: "10px",
                      width: "min-content",
                    }}
                  >
                    <div
                      className="button btn-red"
                      style={{
                        padding: "3px 10px",
                        width: "80px",
                      }}
                      onClick={() => {
                        modaler.closer();
                      }}
                    >
                      No
                    </div>
                    <div
                      className="button"
                      style={{ padding: "3px 10px", width: "80px" }}
                      onClick={() => {
                        const newData = { ...data };
                        newData.has_size = v;
                        newData.size = undefined;
                        setData(newData);
                        modaler.closer();
                      }}
                    >
                      Yes
                    </div>
                  </div>
                </div>
              );
            }}
            disabled={view}
          />
          {data.has_size && (
            <APIDropdown
              module={isGlobal ? globalModule : module}
              label="Size"
              url={
                size_url
                // + `?third_category=${data.third_category}`
              }
              placeholder="Size"
              value={data.size}
              onChange={(v) => {
                set("size", v);
              }}
              disabled={view}
              hasMultipleSelection
              beforeRemoveMsg="Are you sure you want to remove this size, data you entered for this size would be deleted?"
              required
              onDelete={(v) => setDelete("size_del", v)}
              parameterForGet="size_label"
            />
          )}
        </div>
        <div className="formside">
          <InputCheckBox
            label="Has color"
            value={data.has_color}
            onChange={(v) => {
              modaler.opener(
                <div style={{ padding: "20px" }}>
                  <div style={{ fontWeight: "bold" }}>
                    Are you sure you want to change this, data you entered for
                    color would be deleted?
                  </div>
                  <div className="p-10" />
                  <div
                    style={{
                      marginLeft: "auto",
                      display: "flex",
                      gap: "10px",
                      width: "min-content",
                    }}
                  >
                    <div
                      className="button btn-red"
                      style={{
                        padding: "3px 10px",
                        width: "80px",
                      }}
                      onClick={() => {
                        modaler.closer();
                      }}
                    >
                      No
                    </div>
                    <div
                      className="button"
                      style={{ padding: "3px 10px", width: "80px" }}
                      onClick={() => {
                        const newData = { ...data };
                        newData.has_color = v;
                        newData.colors = undefined;
                        setData(newData);
                        modaler.closer();
                      }}
                    >
                      Yes
                    </div>
                  </div>
                </div>
              );
            }}
            disabled={view}
          />
          {data.has_color && (
            <APIDropdown
              module={isGlobal ? globalModule : module}
              label="Color"
              url={color_url}
              placeholder="Color"
              value={data.colors}
              onChange={(v) => set("colors", v)}
              disabled={view}
              hasMultipleSelection
              beforeRemoveMsg="Are you sure you want to remove this color, data you entered for this color would be deleted?"
              required
              onDelete={(v) => setDelete("colors_del", v)}
            />
          )}
        </div>
      </div>
      <div style={{ padding: "10px" }} />
      <SizeChartTable
        sizes={sizes}
        data={data}
        setData={setData}
        set={set}
        setDelete={setDelete}
        isGlobal={isGlobal}
        isB2B={isB2B}
      />
      {data.has_size && (
        <InputImage
          label="Size chart image"
          disabled={view}
          value={data.size_chart}
          onChange={(v) => {
            set("size_chart", v);
          }}
        />
      )}
      <div style={{ padding: "10px" }} />
      <TableComponent
        headers={[
          "",
          "Size",
          "Color",
          "SKU id",
          "Images & video",
          "Price & Tax",
          "Inventory",
          "",
        ]}
        body={data.product_inventory?.map((pi, idx) => {
          const size_name = idToname(pi.product_size, sizes, "size_label");
          const color_name = idToname(pi.product_colors, colors);
          const variant_name =
            (size_name || "") +
            (size_name && color_name ? "-" : "") +
            (color_name || "");
          return [
            <InputCheckBox
              value={pi.is_active}
              disabled={view}
              onChange={(v) => {
                const newData = { ...data };
                newData.product_inventory[idx].is_active = v;
                setData(newData);
              }}
            />,
            <div
              style={{ textDecoration: pi.is_active ? "none" : "line-through" }}
            >
              {size_name || "N/A"}
            </div>,
            <div
              style={{ textDecoration: pi.is_active ? "none" : "line-through" }}
            >
              {color_name || "N/A"}
            </div>,
            <InputText
              placeholder="SKU id"
              value={pi.skuid}
              onChange={(v) => {
                const newData = { ...data };
                newData.product_inventory[idx].skuid = v;
                setData(newData);
              }}
              disabled={!pi.is_active || view}
            />,
            <ImageButton
              data={pi}
              setData={(v) => {
                const newData = { ...data };
                newData.product_inventory[idx] = { ...v };
                setData(newData);
              }}
              disabled={!pi.is_active}
              view={view}
              name={variant_name}
            />,
            <PriceButton
              data={pi}
              setData={(v) => {
                const newData = { ...data };
                newData.product_inventory[idx] = { ...v };
                setData(newData);
              }}
              countries={countries}
              view={view}
              setDelete={setDelete}
              disabled={!pi.is_active}
              name={variant_name}
              mainCountries={data.product_country}
              isGlobal={isGlobal}
              isB2B={isB2B}
            />,
            <InventoryButton
              data={pi}
              setData={(v) => {
                const newData = { ...data };
                newData.product_inventory[idx] = { ...v };
                setData(newData);
              }}
              view={view}
              setDelete={setDelete}
              addresses={addresses}
              disabled={!pi.is_active}
              name={variant_name}
            />,
            <Button
              onClick={() => {
                if (copySelected === idx) {
                  setCopySelected(undefined);
                }
                if (copySelected === undefined) {
                  setCopySelected(idx);
                }
                if (copySelected !== undefined && copySelected !== idx) {
                  const inv = cloneDeep(data.product_inventory[copySelected]);
                  const newData = { ...data };
                  inv.product_colors =
                    newData.product_inventory[idx].product_colors;
                  inv.product_size =
                    newData.product_inventory[idx].product_size;
                  changeCode(inv, "product_inventory");
                  changeCode(
                    newData.product_inventory[idx],
                    "product_inventory",
                    setDelete
                  );
                  newData.product_inventory[idx] = inv;
                  setData(newData);
                  setCopySelected(undefined);
                }
              }}
              color={
                copySelected === idx
                  ? redColor
                  : copySelected === undefined
                  ? "#292d32"
                  : "#00b087"
              }
              disabled={
                ((!isValidImages(pi, false) ||
                  !isValidInventory(pi, false) ||
                  !isValidInventory(pi, false)) &&
                  copySelected === undefined) ||
                !pi.is_active ||
                view
              }
            >
              {copySelected === idx
                ? "Cancel"
                : copySelected === undefined
                ? "Copy"
                : "Paste"}
            </Button>,
          ];
        })}
        disableHeaderPopup
        showbottom={false}
      />
      {!view && (
        <>
          <div style={{ padding: "10px" }} />
          <div className="twoside-form">
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
                gap: "10px",
                width: "min-content",
              }}
            >
              <Button
                type="submit"
                isLoading={saving}
                onClick={() => {
                  moveUpToError();
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </>
      )}
    </form>
  );
};

const isValidVariation = (data, sendToast = true) => {
  let ok = true;
  const toast = (msg) => {
    if (ok) {
      if (sendToast) {
        createToast(msg, "error");
      }
      ok = false;
    }
  };
  data?.product_inventory?.forEach((d) => {
    if (d.is_active) {
      if (!isValidImages(d, false)) {
        toast("Please fill all the fields colored in red");
      }
      if (!isValidPrice(d, false)) {
        toast("Please fill all the fields colored in red");
      }
      if (!isValidInventory(d, false)) {
        toast("Please fill all the fields colored in red");
      }
    }
  });
  data?.size_chart_data?.forEach((x) =>
    x?.column_data?.forEach((d) => {
      if (!d.inch || !d.cm) {
        toast("Please fill all columns in size chart or remove columns");
      }
    })
  );
  return ok;
};

const redColor = "#ff455d";

const ImageButton = ({
  data: upperData,
  setData: upperSet,
  view,
  disabled,
  name,
}) => {
  const modaler = useModal();

  const PopUp = () => {
    const [data, setData] = useState(upperData);
    const set = (key, value) => {
      setData((prev) => ({ ...prev, [key]: value }));
    };
    return (
      <div
        style={{
          padding: "20px",
          width: "100%",
          maxHeight: "80vh",
          overflow: "auto",
        }}
      >
        <div className="psubtitle" style={{ fontSize: "20px" }}>
          Images{" "}
          <span style={{ fontWeight: "normal", fontSize: "15px" }}>
            {name ? `for ${name}` : ""}
          </span>
        </div>
        <div style={{ padding: "10px" }} />
        <MultipleImages set={set} value={data} disabled={view} />
        <div style={{ padding: "5px" }} />
        {!data.image01 && (
          <div className="error-message">First image is required.</div>
        )}
        <div style={{ padding: "10px" }} />
        <div style={{ maxWidth: "300px" }}>
          <InputFile
            label="Video"
            onChange={(v) => set("video", v)}
            value={data.video}
            disabled={view}
            accept="video/*"
          />
        </div>
        <div style={{ padding: "10px" }} />
        {!view && (
          <div>
            <Button
              onClick={() => {
                if (isValidImages(data, true)) {
                  upperSet({ ...data });
                  modaler.closer();
                } else {
                  moveUpToError();
                }
              }}
              style={{ marginLeft: "auto" }}
            >
              Continue
            </Button>
          </div>
        )}
      </div>
    );
  };
  return (
    <button
      className={isValidImages(upperData, false) ? "btn" : "btn-red"}
      type="button"
      onClick={() => {
        modaler.opener(<PopUp />, true);
      }}
      disabled={disabled}
    >
      {view ? "View" : isValidImages(upperData, false) ? "Edit" : "Add"} Images
    </button>
  );
};

const isValidImages = (data, error) => {
  let ok = true;
  const toast = (msg) => {
    if (error) {
      createToast(msg, "error");
    }
    ok = false;
  };
  if (!data.image01) {
    toast("First image is required");
  }
  return ok;
};

const PriceButton = ({
  data: upperData,
  setData: upperSet,
  countries,
  view,
  setDelete,
  disabled,
  name,
  mainCountries,
  isGlobal,
  isB2B,
}) => {
  const modaler = useModal();

  useEffect(() => {
    if (mainCountries && mainCountries?.length && mainCountries.length > 0) {
      const newData = [];
      mainCountries.forEach((c) => {
        const findCountry = upperData.price_tax?.find(
          (d) => d.country_tax === c
        );
        if (findCountry) {
          newData.push(findCountry);
        } else {
          newData.push({
            code: generateUID(),
            printable_name: generateUID(),
            country_tax: c,
            is_cod: false,
          });
        }
      });
      upperData.price_tax?.forEach((x) => {
        let ok = false;
        newData?.forEach((y) => {
          if (x.id === y.id) ok = true;
        });
        if (!ok) {
          setDelete("product_inventory_del", x.id);
        }
      });
      const newInv = { ...upperData };
      newInv.price_tax = newData;
      upperSet({ ...newInv });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainCountries]);

  const PopUp = () => {
    const [data, setData] = useState(upperData);
    const set = (key, value) => {
      setData((prev) => ({ ...prev, [key]: value }));
    };
    useEffect(() => {
      if (mainCountries && mainCountries?.length && mainCountries.length > 0) {
        const newData = [];
        mainCountries.forEach((c) => {
          const findCountry = upperData.price_tax?.find(
            (d) => d.country_tax === c
          );
          if (findCountry) {
            newData.push(findCountry);
          } else {
            newData.push({
              code: generateUID(),
              printable_name: generateUID(),
              country_tax: c,
              is_cod: false,
            });
          }
        });
        upperData.price_tax?.forEach((x) => {
          let ok = false;
          newData?.forEach((y) => {
            if (x.id === y.id) ok = true;
          });
          if (!ok) {
            setDelete("product_inventory_del", x.id);
          }
        });
        const newInv = { ...upperData };
        newInv.price_tax = newData;
        setData({ ...newInv });
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainCountries]);

    return (
      <div
        style={{
          padding: "20px",
          width: "100%",
          maxHeight: "80vh",
          overflow: "auto",
        }}
      >
        <div className="psubtitle" style={{ fontSize: "20px" }}>
          Price & Tax{" "}
          <span style={{ fontWeight: "normal", fontSize: "15px" }}>
            {name ? `for ${name}` : ""}
          </span>
        </div>
        <div className="ps-section">
          {data.price_tax ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                paddingTop: "10px",
              }}
            >
              {data.price_tax.map((c, idx) => (
                <Accordian
                  key={c?.country_tax}
                  noControl
                  open
                  head={`${idToname(c?.country_tax, countries)}`}
                  body={
                    <PriceTax
                      setDelete={setDelete}
                      view={view}
                      data={c}
                      isGlobal={isGlobal}
                      isB2B={isB2B}
                      set={(key, value) => {
                        const newData = [...data.price_tax];
                        newData[idx][key] = value;
                        set("price_tax", newData);
                      }}
                    />
                  }
                />
              ))}
            </div>
          ) : null}
        </div>
        <div style={{ padding: "10px" }} />
        {!view && (
          <div>
            <Button
              onClick={() => {
                if (isValidPrice(data, true)) {
                  upperSet({ ...data });
                  modaler.closer();
                } else {
                  moveUpToError();
                }
              }}
              style={{ marginLeft: "auto" }}
            >
              Continue
            </Button>
          </div>
        )}
      </div>
    );
  };
  return (
    <button
      type="button"
      className={isValidPrice(upperData, false) ? "btn" : "btn-red"}
      onClick={() => {
        modaler.opener(<PopUp />, true);
      }}
      disabled={disabled}
    >
      {view ? "View" : isValidPrice(upperData, false) ? "Edit" : "Add"} Prices
    </button>
  );
};

const isValidPrice = (data, error) => {
  let ok = true;
  const toast = (msg) => {
    if (error) {
      createToast(msg, "error");
    }
    ok = false;
  };
  if (
    !data.price_tax ||
    !(data.price_tax?.length && data.price_tax?.length > 0)
  ) {
    toast("No country availability selected.");
  }
  data?.price_tax?.forEach((data) => {
    if (!data.price_range_start || !data.price_range_end) {
      toast("Price range is required");
    }
    if (Number(data.price_range_start) > Number(data.price_range_end)) {
      toast("Start should be smaller than end");
    }
    if (!data.price_value) {
      toast("Basic price is required");
    }
    if (Number(data.price_value) > Number(data.price_range_end)) {
      toast("Price should be smaller than price range end");
    }
    if (Number(data.price_value) < Number(data.price_range_start)) {
      toast("Price should be greater than price range start");
    }
    if (!data.goods_tax_code) {
      toast("Tax code is required");
    }
    if (!data.disc_percent_range_start || !data.disc_percent_range_end) {
      toast("Discount range is required");
    }
    if (
      Number(data.disc_percent_range_start) >
      Number(data.disc_percent_range_end)
    ) {
      toast("Start should be smaller than end");
    }
    if (!data.disc_percent_value) {
      toast("Discount percent is required");
    }
    if (Number(data.disc_percent_value) > Number(data.disc_percent_range_end)) {
      toast("Discount percent should be smaller than range end");
    }
    if (
      Number(data.disc_percent_value) < Number(data.disc_percent_range_start)
    ) {
      toast("Discount should be greater than range start");
    }
    data?.charges_of_product
      ?.filter((d) => d.charge_name)
      ?.forEach((d) => {
        if (!d.price_range_start || !d.price_range_end) {
          toast("Charges price range is required");
        }
        if (Number(d.price_range_start) > Number(d.price_range_end)) {
          toast("Start should be smaller than end");
        }
        if (!d.price_value) {
          toast("Charges basic price is required");
        }
        if (Number(d.price_value) > Number(d.price_range_end)) {
          toast("Charges price should be smaller than price range end");
        }
        if (Number(d.price_value) < Number(d.price_range_start)) {
          toast("Charges price should be greater than price range start");
        }
        if (!d.tax_code) {
          toast("Charges SAC should be greater than price range start");
        }
      });
  });
  return ok;
};

const InventoryButton = ({
  data: upperData,
  setData: upperSet,
  view,
  setDelete,
  addresses,
  disabled,
  name,
}) => {
  const modaler = useModal();

  const PopUp = () => {
    const [data, setData] = useState(upperData);
    const set = (key, value) => {
      setData((prev) => ({ ...prev, [key]: value }));
    };
    useEffect(() => {
      if (addresses && addresses?.length && addresses.length > 0) {
        setSelectedAdd(0);
        data?.qty_addres?.forEach((x) => {
          if (!addresses.find((a) => a.id === x.seller_address && !x.used)) {
            setDelete("qty_addres_del", x.id);
          } else {
            x.used = true;
          }
        });
        set(
          "qty_addres",
          addresses.map((a) => {
            return {
              ...(data?.qty_addres?.find((x) => x.seller_address === a.id) || {
                code: generateUID(),
                printable_name: generateUID(),
                description: generateUID(),
                seller_address: a.id,
                stock: 0,
              }),
            };
          })
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addresses]);
    const [selectedAdd, setSelectedAdd] = useState(0);
    return (
      <div style={{ padding: "20px", maxHeight: "80vh", overflow: "auto" }}>
        <div className="psubtitle" style={{ fontSize: "20px" }}>
          Inventory details{" "}
          <span style={{ fontWeight: "normal", fontSize: "15px" }}>
            {name ? `for ${name}` : ""}
          </span>
        </div>
        <div className="ps-section">
          {addresses === undefined ? (
            <div style={{ height: "200px" }}>
              <Loading />
            </div>
          ) : (
            <div className="address-box-wrapper">
              {addresses && addresses?.length > 0
                ? addresses?.map((d, idx) => (
                    <div
                      className={
                        "address-box " +
                        (idx === selectedAdd ? "border-blue" : "")
                      }
                      key={idx}
                      onClick={() => setSelectedAdd(idx)}
                    >
                      {sellerAddressFormatter(d)}
                    </div>
                  ))
                : "No seller selected."}
            </div>
          )}
          <div className="p-5" />
          {data.qty_addres?.[selectedAdd] && (
            <WarehouseForm
              view={view}
              data={data.qty_addres[selectedAdd]}
              set={(key, value) => {
                const newData = [...data.qty_addres];
                newData[selectedAdd][key] = value;
                set("qty_addres", newData);
              }}
            />
          )}
          <div style={{ padding: "10px" }} />
          <div className="form-grid">
            <div className="weight-section">
              <div style={{ fontWeight: "bold" }}>Item dimensions</div>
              <div className="two-inputs">
                <InputText label="Length" placeholder="Length" disabled />
                <InputSelect
                  placeholder="Select"
                  options={["Centimeter", "Milimeter", "Inches"]}
                  disabled
                />
              </div>
              <div className="two-inputs">
                <InputText label="Width" placeholder="Width" disabled />
                <InputSelect
                  placeholder="Select"
                  options={["Centimeter", "Milimeter", "Inches"]}
                  disabled
                />
              </div>
              <div className="two-inputs">
                <InputText label="Height" placeholder="Height" disabled />
                <InputSelect
                  placeholder="Select"
                  options={["Centimeter", "Milimeter", "Inches"]}
                  disabled
                />
              </div>
            </div>
            <div className="weight-section">
              <div style={{ fontWeight: "bold" }}>Package dimensions</div>
              <div className="two-inputs">
                <InputText label="Length" placeholder="Length" disabled />
                <InputSelect
                  placeholder="Select"
                  options={["Centimeter", "Milimeter", "Inches"]}
                  disabled
                />
              </div>
              <div className="two-inputs">
                <InputText label="Width" placeholder="Width" disabled />
                <InputSelect
                  placeholder="Select"
                  options={["Centimeter", "Milimeter", "Inches"]}
                  disabled
                />
              </div>
              <div className="two-inputs">
                <InputText label="Height" placeholder="Height" disabled />
                <InputSelect
                  placeholder="Select"
                  options={["Centimeter", "Milimeter", "Inches"]}
                  disabled
                />
              </div>
            </div>
          </div>
          <div style={{ padding: "10px" }} />
          <div className="form-grid">
            <div className="two-inputs">
              <InputText
                label="Item weight"
                placeholder="Net weight"
                disabled
              />
              <InputSelect
                placeholder="Select"
                options={["Gram", "Kilogram", "Miligram"]}
                disabled
              />
            </div>
            <div className="two-inputs">
              <InputText
                label="Package weight"
                placeholder="Gross weight"
                disabled
              />
              <InputSelect
                placeholder="Select"
                options={["Gram", "Kilogram", "Miligram"]}
                disabled
              />
            </div>
          </div>
        </div>
        <div style={{ padding: "10px" }} />
        {!view && (
          <div>
            <Button
              onClick={() => {
                if (isValidInventory(data, true)) {
                  upperSet({ ...data });
                  modaler.closer();
                } else {
                  moveUpToError();
                }
              }}
              style={{ marginLeft: "auto" }}
            >
              Continue
            </Button>
          </div>
        )}
      </div>
    );
  };
  return (
    <button
      type="button"
      className={isValidInventory(upperData, false) ? "btn" : "btn-red"}
      onClick={() => {
        modaler.opener(<PopUp />, true);
      }}
      disabled={disabled}
    >
      {view ? "View" : isValidInventory(upperData, false) ? "Edit" : "Add"}{" "}
      Inventory
    </button>
  );
};

const isValidInventory = (data, error) => {
  let ok = true;
  const toast = (msg) => {
    if (error) {
      createToast(msg, "error");
    }
    ok = false;
  };
  if (
    !data.qty_addres ||
    !(data.qty_addres?.length && data.qty_addres?.length > 0)
  ) {
    toast("Seller address is required");
  }
  data?.qty_addres?.forEach((data) => {
    if (!data.stock && data.stock !== 0) {
      toast("Quantity is required");
    }
  });
  return ok;
};

const ChargesTable = ({
  data,
  setData,
  country_id,
  view,
  setDelete,
  isGlobal,
  isB2B,
}) => {
  useEffect(() => {
    if (!(data?.length && data.length > 0)) {
      setData([
        { id: generateUID(), code: generateUID(), country_tax: country_id },
      ]);
      return;
    }
  }, [data, setData, country_id]);
  const addNew = () => {
    setData([
      ...data,
      { id: generateUID(), code: generateUID(), country_tax: country_id },
    ]);
  };

  const set = (idx, key, value) => {
    const newData = [...data];
    newData[idx][key] = value;
    setData(newData);
  };
  const { data: sac } = useFetch(
    sac_tax_code_url,
    isGlobal ? globalModule : module
  );

  if (!data) return null;

  return (
    <>
      {!view && (
        <button
          type="button"
          className="button"
          style={{ marginLeft: "auto" }}
          onClick={() => addNew()}
        >
          Add New
        </button>
      )}
      <div className="p-5" />
      <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
        {data &&
          data.length > 0 &&
          data.map((d, idx) => (
            <div className="ps-section" key={idx}>
              {!view && (
                <div
                  className="button btn-red"
                  style={{
                    padding: "10px",
                    marginLeft: "auto",
                    width: "min-content",
                  }}
                  onClick={() => {
                    areYouSure(() => {
                      setDelete("charges_of_product_del", d.id);
                      setData(data.filter((x) => x.id !== d.id));
                    });
                  }}
                >
                  <DeleteIcon />
                </div>
              )}
              <div className="form-grid" style={{ alignItems: "flex-start" }}>
                <APIDropdown
                  module={isGlobal ? globalModule : module}
                  label="Charges name"
                  placeholder="Charges name"
                  required
                  disabled={view}
                  value={d.charge_name}
                  url={charges_url}
                  onChange={(v) => set(idx, "charge_name", v)}
                />
                <div>
                  <div className="two-inputs">
                    <InputNumber
                      label="Charges basic price range"
                      placeholder="Start"
                      value={d.price_range_start}
                      disabled={view}
                      onChange={(v) => set(idx, "price_range_start", v)}
                    />
                    <InputNumber
                      placeholder="End"
                      value={d.price_range_end}
                      disabled={view}
                      onChange={(v) => set(idx, "price_range_end", v)}
                    />
                  </div>
                  {d.charge_name &&
                    (!d.price_range_start || !d.price_range_end) && (
                      <div className="error-message">
                        These fields are required
                      </div>
                    )}
                  {d.charge_name &&
                    Number(d.price_range_start) > Number(d.price_range_end) && (
                      <div className="error-message">
                        Start should be smaller than end
                      </div>
                    )}
                </div>
                <div>
                  <InputNumber
                    label="Charges basic price"
                    placeholder="Charges basic price"
                    value={d.price_value}
                    disabled={view}
                    onChange={(v) => set(idx, "price_value", v)}
                  />
                  {d.charge_name && !d.price_value && (
                    <div className="error-message">This field is required</div>
                  )}
                  {d.charge_name &&
                    Number(d.price_value) > Number(d.price_range_end) && (
                      <div className="error-message">
                        Price should be smaller than price range end
                      </div>
                    )}
                  {d.charge_name &&
                    Number(d.price_value) < Number(d.price_range_start) && (
                      <div className="error-message">
                        Price should be greater than price range start
                      </div>
                    )}
                </div>
                <InputNumber
                  label="Country charges basic price"
                  placeholder="Country charges basic price"
                  value={d.charges_no_tax}
                  disabled
                />
                <div>
                  <APIDropdown
                    module={isGlobal ? globalModule : module}
                    label="SAC"
                    placeholder="SAC"
                    required
                    disabled={view}
                    value={d.tax_code}
                    url={sac_tax_code_url}
                    onChange={(v) => set(idx, "tax_code", v)}
                  />
                  {d.charge_name && !d.tax_code && (
                    <div className="error-message">This field is required</div>
                  )}
                </div>
                <InputNumber
                  label="Tax on country charges basic price"
                  placeholder="Tax on country charges basic price"
                  value={d.charge_tax_value}
                  disabled
                />
              </div>
              <TaxDisplay
                code={idToname(d.tax_code, sac, "code")}
                percentUrl={sac_tax_percent_url}
              />
              <div className="p-5" />
              <div className="form-grid" style={{ alignItems: "flex-start" }}>
                <InputNumber
                  label="Total charges(with tax)"
                  placeholder="Total charges(with tax)"
                  value={d.charge_with_tax}
                  disabled
                />
              </div>
              <InputToggle
                label="Active"
                value={d.is_active}
                disabled={view}
                onChange={(v) => set(idx, "is_active", v)}
              />
            </div>
          ))}
      </div>
    </>
  );
};

const WarehouseForm = ({ view, data, set }) => {
  return (
    <>
      <div className="form-grid">
        <InputText
          label="Quantity"
          placeholder="Quantity"
          disabled={view}
          value={data.stock}
          onChange={(v) => set("stock", v)}
        />
        <InputText
          label="Low stock threshold"
          placeholder="Low stock threshold"
          disabled
        />
        <InputText label="Pack of" placeholder="Pack of" disabled />
        <InputText
          label="Maximum order quantity"
          placeholder="Maximum order quantity"
          disabled
        />
      </div>
      <div style={{ padding: "10px" }} />
      <div className="time-to-ship">
        <div style={{ width: "300px", maxWidth: "100%", marginTop: "auto" }}>
          <InputText label="Time to ship" placeholder="From" disabled />
        </div>
        <div style={{ width: "300px", maxWidth: "100%", marginTop: "auto" }}>
          <InputText label="" placeholder="To" disabled />
        </div>
        <div style={{ width: "200px", maxWidth: "100%", marginTop: "auto" }}>
          <InputSelect
            placeholder="Select"
            options={["Hours", "Days", "Months"]}
            disabled
          />
        </div>
      </div>
      <InputToggle
        label="Active"
        value={data.is_active}
        disabled={view}
        onChange={(v) => set("is_active", v)}
      />
    </>
  );
};

const TaxDisplay = ({ code, isGlobal, percentUrl }) => {
  const { data } = useFetch(
    percentUrl + `?code=${code}`,
    isGlobal ? globalModule : module
  );
  if (!code) return null;
  const gst = data?.[0]?.tax_percent || 0;
  return (
    <>
      <div className="p-5" />
      <TableComponent
        celled
        disableHeaderPopup
        showbottom={false}
        headers={["Tax name", "Percentage"].map((h) => (
          <div className="center">{h}</div>
        ))}
        body={[
          [
            <div className="center">IGST</div>,
            <div className="center">{gst + "%"}</div>,
          ],
          [
            <div className="center">CGST</div>,
            <div className="center">{gst / 2 + "%"}</div>,
          ],
          [
            <div className="center">SGST</div>,
            <div className="center">{gst / 2 + "%"}</div>,
          ],
        ]}
      />
    </>
  );
};

const PriceTax = ({ data, set, view, setDelete, isGlobal, isB2B }) => {
  const { data: hsns } = useFetch(
    tax_code_url,
    isGlobal ? globalModule : module
  );
  return (
    <>
      <div className="form-grid" style={{ alignItems: "flex-start" }}>
        <div>
          <div className="two-inputs">
            <InputNumber
              label="Basic price range"
              placeholder="Start"
              value={data.price_range_start}
              disabled={view}
              onChange={(v) => set("price_range_start", v)}
            />
            <InputNumber
              placeholder="End"
              value={data.price_range_end}
              disabled={view}
              onChange={(v) => set("price_range_end", v)}
            />
          </div>
          {(!data.price_range_start || !data.price_range_end) && (
            <div className="error-message">These fields are required</div>
          )}
          {Number(data.price_range_start) > Number(data.price_range_end) && (
            <div className="error-message">
              Start should be smaller than end
            </div>
          )}
        </div>
        <div>
          <InputNumber
            label="Basic price"
            placeholder="Basic price"
            value={data.price_value}
            disabled={view}
            onChange={(v) => set("price_value", v)}
          />
          {!data.price_value && (
            <div className="error-message">This field is required</div>
          )}
          {Number(data.price_value) > Number(data.price_range_end) && (
            <div className="error-message">
              Price should be smaller than price range end
            </div>
          )}
          {Number(data.price_value) < Number(data.price_range_start) && (
            <div className="error-message">
              Price should be greater than price range start
            </div>
          )}
        </div>
        <InputNumber
          label="Country basic price"
          placeholder="Country basic price"
          disabled
          value={data.product_price_without_tax}
        />
        <div>
          <APIDropdown
            module={isGlobal ? globalModule : module}
            label="HSN code"
            placeholder="HSN code"
            required
            disabled={view}
            value={data.goods_tax_code}
            url={tax_code_url}
            onChange={(v) => set("goods_tax_code", v)}
          />
          {!data.goods_tax_code && (
            <div className="error-message">This field is required</div>
          )}
        </div>
        <InputNumber
          label="Tax on country price"
          placeholder="Tax on country price"
          value={data.tax_on_product}
          disabled
        />
        <InputNumber
          label="MRP"
          placeholder="MRP"
          disabled
          value={data.product_price_with_tax}
        />
      </div>
      <TaxDisplay
        code={idToname(data.goods_tax_code, hsns, "code")}
        isGlobal={isGlobal}
        isB2B={isB2B}
        percentUrl={tax_percent_url}
      />
      <div className="p-5" />
      <div className="form-grid" style={{ alignItems: "flex-start" }}>
        <div>
          <div className="two-inputs">
            <InputNumber
              label="Discount percent range"
              placeholder="Start"
              value={data.disc_percent_range_start}
              disabled={view}
              onChange={(v) => set("disc_percent_range_start", v)}
            />
            <InputNumber
              placeholder="End"
              value={data.disc_percent_range_end}
              disabled={view}
              onChange={(v) => set("disc_percent_range_end", v)}
            />
          </div>
          {(!data.disc_percent_range_start || !data.disc_percent_range_end) && (
            <div className="error-message">These fields are required</div>
          )}
          {Number(data.disc_percent_range_start) >
            Number(data.disc_percent_range_end) && (
            <div className="error-message">
              Start should be smaller than end
            </div>
          )}
        </div>
        <div>
          <InputNumber
            label="Discount percent"
            placeholder="Discount percent"
            value={data.disc_percent_value}
            onChange={(v) => set("disc_percent_value", v)}
            disabled={view}
          />
          {!data.disc_percent_value && (
            <div className="error-message">This field is required</div>
          )}
          {Number(data.disc_percent_value) >
            Number(data.disc_percent_range_end) && (
            <div className="error-message">
              Discount percent should be smaller than range end
            </div>
          )}
          {Number(data.disc_percent_value) <
            Number(data.disc_percent_range_start) && (
            <div className="error-message">
              Discount should be greater than range start
            </div>
          )}
        </div>
        <InputNumber
          label="Discount value(with tax)"
          placeholder="Discount value(with tax)"
          value={data.calculated_discount}
          disabled
        />
        <InputNumber
          label="Discount value(without tax)"
          placeholder="Discount value(without tax)"
          value={(
            data.product_price_without_tax - data.discounted_price_without_tax
          )?.toFixed(2)}
          disabled
        />
        <InputNumber
          label="Discounted selling price(with tax)"
          placeholder="Discounted selling price(with tax)"
          value={data.discounted_price}
          disabled
        />
        <InputNumber
          label="Discounted selling price(without tax)"
          placeholder="Discounted selling price(without tax)"
          disabled
          value={data.discounted_price_without_tax}
        />
        <InputCheckBox
          label="COD available"
          disabled={view}
          value={data.is_cod}
          onChange={(v) => set("is_cod", v)}
        />
      </div>
      <InputToggle
        label="Active"
        value={data.is_active}
        disabled={view}
        onChange={(v) => set("is_active", v)}
      />
      <div className="p-10" />
      <div className="p-10" />
      <div className="p-10" style={{ borderTop: "1px solid gray" }} />
      <div className="psubtitle">Applicable Charges</div>
      <ChargesTable
        data={data.charges_of_product}
        setData={(v) => set("charges_of_product", v)}
        country_id={data.country_tax}
        view={view}
        setDelete={setDelete}
        isGlobal={isGlobal}
        isB2B={isB2B}
      />
    </>
  );
};

// const SizeTable = ({
//   data,
//   set,
//   ticked,
//   setTicked,
//   sizes,
//   view,
//   setDelete,
// }) => {
//   const modaler = useModal();
//   return (
//     <div className="ps-section">
//       <TableComponent
//         celled
//         disableHeaderPopup
//         showbottom={false}
//         headers={(view
//           ? ["", "Size", "Variant Sku ID"]
//           : ["", "Size", "Variant Sku ID", "Action"]
//         ).map((h) => (
//           <div className="center">{h}</div>
//         ))}
//         body={
//           data.product_size_inventory
//             ? data.product_size_inventory.map((d, idx) => [
//                 <InputCheckBox
//                   value={ticked === idx}
//                   onChange={(v) => setTicked(idx)}
//                 />,
//                 idToname(d.product_size, sizes, "size_label"),
//                 <div style={{ minWidth: "150px" }}>
//                   <InputText
//                     placeholder="SKU id"
//                     value={d.skuid}
//                     disabled={view}
//                     onChange={(v) => {
//                       const newData = [...data.product_size_inventory];
//                       newData[idx].skuid = v;
//                       set("product_size_inventory", newData);
//                     }}
//                   />
//                 </div>,
//                 !view && (
//                   <div className="center">
//                     <ActionDropdown
//                       options={(closer) => (
//                         <div
//                           className="option"
//                           onClick={() => {
//                             closer();
//                             modaler.opener(
//                               <SizeCopy
//                                 data={data}
//                                 set={set}
//                                 close={modaler.closer}
//                                 current={idx}
//                                 sizes={sizes}
//                               />
//                             );
//                           }}
//                         >
//                           <div className="icon-btn blue-icon">
//                             <CopyIcon />
//                           </div>
//                           <div className="blue-title">Copy</div>
//                         </div>
//                       )}
//                       DeleteButton={({ setOpen }) => (
//                         <div
//                           className="option"
//                           onClick={() => {
//                             setOpen(false);
//                             if (data.product_size_inventory.length === 1) {
//                               set("product_size_inventory", "");
//                               return;
//                             }
//                             setDelete("product_size_inventory_del", d.id);
//                             set(
//                               "product_size_inventory",
//                               data.product_size_inventory.filter(
//                                 (x) => x.product_size !== d.product_size
//                               )
//                             );
//                           }}
//                         >
//                           <div className="icon-btn red-icon">
//                             <DeleteIcon />
//                           </div>
//                           <div className="red-title">Delete</div>
//                         </div>
//                       )}
//                     />
//                   </div>
//                 ),
//               ])
//             : []
//         }
//       />
//     </div>
//   );
// };

function changeCode(obj, parentKey, del) {
  if (Array.isArray(obj)) {
    // If it's an array, iterate over each element
    obj.forEach((element) => changeCode(element, parentKey, del));
  } else if (typeof obj === "object" && obj !== null) {
    // If it's an object, iterate over each key
    for (let key in obj) {
      if (key === "code") {
        if (parentKey && del) {
          del(parentKey + "_del", obj?.["id"]);
        }
        obj[key] = generateUID();
      } else {
        changeCode(obj[key], key, del); // Recursive call for nested objects/arrays
      }
    }
  }
}

const Policies = ({
  onSave,
  data,
  setData,
  edit,
  view,
  apiUrl,
  isGlobal,
  isB2B,
}) => {
  const [saving, setSaving] = useState(false);
  const set = (key, value) => {
    setData((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setSaving(true);
        const newData = dataByStep(filterDataBeforeSave({ ...data }), 4);
        try {
          if (edit) {
            api
              .patch(apiUrl + data.id + "/", serialize(newData), {
                headers: { module: isGlobal ? globalModule : module },
              })
              .then((res) => {
                onSave();
                set("id", res.data.id);
                setSaving(false);
              })
              .catch((err) => {
                unknownError(err);
                setSaving(false);
              });
          } else {
            api
              .post(apiUrl, serialize(newData), {
                headers: { module: isGlobal ? globalModule : module },
              })
              .then((res) => {
                onSave();
                set("id", res.data.id);
                setSaving(false);
              })
              .catch((err) => {
                unknownError(err);
                setSaving(false);
              });
          }
        } catch (err) {
          unknownError(err);
          setSaving(false);
        }
      }}
    >
      <div className="twoside-form">
        <div className="form-grid">
          <InputCheckBox
            label="Cancellable"
            value={data.is_cancellable}
            disabled={view}
            onChange={(v) => set("is_cancellable", v)}
          />
          <InputCheckBox
            label="Refundable"
            value={data.is_refundable}
            disabled={view}
            onChange={(v) => set("is_refundable", v)}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <InputCheckBox
              label="Returnable"
              value={data.is_returnable}
              disabled={view}
              onChange={(v) => set("is_returnable", v)}
            />
            {data.is_returnable && (
              <>
                <div>
                  Please specify the days for which the product is returnable
                  after the date of product being delivered
                </div>
                <InputNumber
                  value={data.return_span}
                  onChange={(v) => set("return_span", v)}
                  disabled={view}
                  required
                />
              </>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <InputCheckBox
              label="Replaceable"
              value={data.is_replacable}
              disabled={view}
              onChange={(v) => set("is_replacable", v)}
            />
            {data.is_replacable && (
              <>
                <div>
                  Please specify the days for which the product is replaceable
                  after the date of product being delivered
                </div>
                <InputNumber
                  value={data.replace_span}
                  onChange={(v) => set("replace_span", v)}
                  disabled={view}
                  required
                />
                <div>
                  Please specify number of replaces applicable to the product
                </div>
                <InputNumber
                  value={data.no_replace}
                  onChange={(v) => set("no_replace", v)}
                  disabled={view}
                  required
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div style={{ padding: "10px" }} />
      <div className="twoside-form">
        <div className="form-grid">
          <InputNumber
            label="Maximum orderable quantity"
            placeholder="Maximum orderable quantity"
            value={data.qty_can_order}
            onChange={(v) => set("qty_can_order", v)}
            disabled={view}
            required
          />
        </div>
        <div />
      </div>
      <div style={{ padding: "10px" }} />
      <InputToggle
        label="Active"
        value={data.is_active}
        disabled={view}
        onChange={(v) => set("is_active", v)}
      />
      {!view && (
        <>
          <div style={{ padding: "10px" }} />
          <div className="twoside-form">
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
                gap: "10px",
                width: "min-content",
              }}
            >
              <Button type="submit" isLoading={saving}>
                Save
              </Button>
            </div>
          </div>
        </>
      )}
    </form>
  );
};

const Like = ({
  data,
  setData,
  onSave,
  edit,
  view,
  isGlobal,
  apiUrl,
  isB2B,
}) => {
  const [saving, setSaving] = useState(false);

  const [delData, setDelData] = useState({});
  const setDelete = (key, id) => {
    if (!isRealId(id)) return;
    if (!id) return;
    const newDelData = { ...delData };
    if (newDelData[key]) {
      newDelData[key] = [id, ...newDelData[key]];
    } else {
      newDelData[key] = [id];
    }
    setDelData(newDelData);
  };

  const set = (key, value) => {
    setData((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setSaving(true);
        const newData = dataByStep(filterDataBeforeSave({ ...data }), 5);
        try {
          api
            .patch(apiUrl + data.id + "/", serialize(newData), {
              headers: { module: isGlobal ? globalModule : module },
            })
            .then((res) => {
              if (delData && Object.keys(delData).length) {
                api
                  .patch(apiUrl + data.id + `/${delSlug}/`, delData, {
                    headers: { module: isGlobal ? globalModule : module },
                  })
                  .then((res) => {
                    onSave();
                    setSaving(false);
                  })
                  .catch((err) => {
                    unknownError(err);
                    setSaving(false);
                  });
              } else {
                onSave();
                setSaving(false);
              }
            })
            .catch((err) => {
              unknownError(err);
              setSaving(false);
            });
        } catch (err) {
          unknownError(err);
          setSaving(false);
        }
      }}
    >
      <div className="twoside-form">
        <div className="formside">
          <APIDropdown
            module={isGlobal ? globalModule : module}
            placeholder="Select product"
            required
            disabled={view}
            value={data.you_may_like}
            url={
              (isB2B
                ? bproduct_add_url
                : isGlobal
                ? gproduct_add_url
                : product_add_url) +
              "?" +
              objToParams({
                seller: data.seller_details,
              })
            }
            onChange={(v) => set("you_may_like", v)}
            onDelete={(v) => setDelete("you_may_like_del", v)}
            hasMultipleSelection
            displayAs={(o) => {
              const image = o?.product_inventory?.[0]?.image01;
              return (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <DashboardImage
                    src={image}
                    height="50px"
                    width="50px"
                    noOpen
                  />
                  {o?.printable_name}
                </div>
              );
            }}
          />
        </div>
      </div>
      {!view && (
        <>
          <div style={{ padding: "10px" }} />
          <div className="twoside-form">
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
                gap: "10px",
                width: "min-content",
              }}
            >
              <Button type="submit" isLoading={saving}>
                Save
              </Button>
            </div>
          </div>
        </>
      )}
    </form>
  );
};

function createNewColumnArray(columns, oldArray, setDelete) {
  const newArray = [];
  oldArray.forEach((x) => (x.used = false));
  columns.forEach((c) => {
    if (oldArray.find((x) => x.column_name === c)) {
      oldArray.find((x) => x.column_name === c).used = true;
      newArray.push(oldArray.find((x) => x.column_name === c));
    } else {
      newArray.push({
        code: generateUID(),
        printable_name: generateUID(),
        column_name: c,
        inch: "",
        cm: "",
      });
    }
  });
  oldArray.forEach((x) => {
    if (!x.used) {
      setDelete("column_data_del", x.id);
    }
  });
  return newArray;
}

const SizeChartTable = ({
  data,
  setData,
  sizes,
  view,
  setDelete,
  set,
  isGlobal,
  isB2B,
}) => {
  const [refetcher, setRefetcher] = useState(false);
  const columnData = useFetch(
    size_chart_column_url +
      (data.third_category ? `?third_category=${data.third_category}` : ""),
    isGlobal ? globalModule : module
  );
  useEffect(() => {
    if (data?.size && data?.size?.length > 0 && data.chart_columns) {
      const newData = [...data?.size_chart_data];
      newData.forEach((x) => (x.used = false));
      const newSizeChart = [];
      data.size.forEach((s) => {
        if (newData.find((x) => x.size === s)) {
          newData.find((x) => x.size === s).used = true;
          newSizeChart.push({
            code: newData.find((x) => x.size === s).code,
            printable_name: newData.find((x) => x.size === s).printable_name,
            size: s,
            column_data: createNewColumnArray(
              data.chart_columns,
              newData.find((x) => x.size === s)?.column_data,
              setDelete
            ),
          });
        } else {
          newSizeChart.push({
            code: generateUID(),
            printable_name: generateUID(),
            size: s,
            column_data: createNewColumnArray(
              data?.chart_columns,
              [],
              setDelete
            ),
          });
        }
      });
      newData.forEach((x) => {
        if (!x.used) {
          setDelete("size_chart_data_del", x.id);
        }
      });
      set("size_chart_data", newSizeChart);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.size, data?.chart_columns, refetcher]);

  useEffect(() => {
    setTimeout(() => {
      setRefetcher((v) => !v);
    }, 200);
  }, [data.size]);

  return (
    <>
      <div className="ps-section">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ fontWeight: "bold", fontSize: "16px" }}>Size chart</div>
          <div style={{ width: "300px" }}>
            <APIDropdown
              module={isGlobal ? globalModule : module}
              placeholder="Column names"
              disabled={view}
              value={data.chart_columns}
              apiData={columnData}
              hasMultipleSelection
              required
              onChange={(v) => {
                if (v === undefined) {
                  set("chart_columns", []);
                } else {
                  set("chart_columns", v);
                }
              }}
            />
          </div>
        </div>
        <div className="p-5" />
        {data.chart_columns && data.chart_columns?.length === 0 ? (
          <div className="center">
            <div className="p-10" />
            Choose column names first to fill size chart
            <div className="p-10" />
          </div>
        ) : (
          <TableComponent
            celled
            disableHeaderPopup
            showbottom={false}
            headers={[
              "Size",
              ...data?.chart_columns.map((c, idx) => (
                <div
                  key={idx}
                  className="center"
                  style={{
                    minWidth: "150px",
                    display: "flex",
                    gap: "5px",
                    flexDirection: "row",
                  }}
                >
                  {idToname(c, columnData.data)}
                </div>
              )),
            ]}
            body={
              data?.size_chart_data && data?.size_chart_data?.length > 0
                ? data.size_chart_data.map((d, size_chart_index) => [
                    <div>
                      {size_chart_index === 0 && (
                        <div style={{ padding: "10px" }}></div>
                      )}
                      {idToname(d.size, sizes, "size_label")}{" "}
                    </div>,
                    ...data.chart_columns.map((c, idx) => (
                      <div key={c?.id} style={{ minWidth: "150px" }}>
                        <div className="two-inputs">
                          <div style={{ width: "100%" }}>
                            {size_chart_index === 0 && (
                              <div
                                className="center"
                                style={{ fontWeight: "bold" }}
                              >
                                Inch
                              </div>
                            )}
                            <InputNumber
                              placeholder="Inch"
                              value={
                                d.column_data.find((x) => x.column_name === c)
                                  ?.inch || ""
                              }
                              onChange={(v) => {
                                const newData = [...data.size_chart_data];
                                const newSizeChart = {
                                  ...newData[size_chart_index],
                                };
                                if (newSizeChart.column_data) {
                                  newSizeChart.column_data.find(
                                    (x) => x.column_name === c
                                  ).inch = v;
                                  newSizeChart.column_data.find(
                                    (x) => x.column_name === c
                                  ).cm = (v * 2.54).toFixed(2);
                                }
                                newData[size_chart_index] = newSizeChart;
                                set("size_chart_data", newData);
                              }}
                            />
                          </div>
                          <div style={{ width: "100%" }}>
                            {size_chart_index === 0 && (
                              <div
                                className="center"
                                style={{ fontWeight: "bold" }}
                              >
                                Cm
                              </div>
                            )}
                            <InputNumber
                              placeholder="Cm"
                              value={
                                d.column_data.find((x) => x.column_name === c)
                                  ?.cm || ""
                              }
                              onChange={(v) => {
                                const newData = [...data.size_chart_data];
                                const newSizeChart = {
                                  ...newData[size_chart_index],
                                };
                                if (newSizeChart.column_data) {
                                  newSizeChart.column_data.find(
                                    (x) => x.column_name === c
                                  ).cm = v;
                                }
                                newData[size_chart_index] = newSizeChart;
                                set("size_chart_data", newData);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )),
                  ])
                : []
            }
          />
        )}
      </div>
      <div className="p-5" />
    </>
  );
};

// const SizeChartDisplay = () => {
//   const data = [
//     {
//       size: 51,
//       size_column: [
//         {
//           id: 21,
//           column_name: "Across Shoulder",
//           inch: "14",
//           cm: "35",
//         },
//         {
//           id: 20,
//           column_name: "Waist",
//           inch: "38",
//           cm: "96",
//         },
//         {
//           id: 19,
//           column_name: "Front Length",
//           inch: "18",
//           cm: "45",
//         },
//         {
//           id: 18,
//           column_name: "Bust",
//           inch: "40",
//           cm: "101",
//         },
//       ],
//     },
//     {
//       size: 50,
//       size_column: [
//         {
//           id: 17,
//           column_name: "Across Shoulder",
//           inch: "13",
//           cm: "34",
//         },
//         {
//           id: 16,
//           column_name: "Waist",
//           inch: "36",
//           cm: "91",
//         },
//         {
//           id: 15,
//           column_name: "Front Length",
//           inch: "18",
//           cm: "45",
//         },
//         {
//           id: 14,
//           column_name: "Bust",
//           inch: "38",
//           cm: "96",
//         },
//       ],
//     },
//     {
//       size: 51,
//       size_column: [
//         {
//           id: 21,
//           column_name: "Across Shoulder",
//           inch: "14",
//           cm: "35",
//         },
//         {
//           id: 20,
//           column_name: "Waist",
//           inch: "38",
//           cm: "96",
//         },
//         {
//           id: 19,
//           column_name: "Front Length",
//           inch: "18",
//           cm: "45",
//         },
//         {
//           id: 18,
//           column_name: "Bust",
//           inch: "40",
//           cm: "101",
//         },
//       ],
//     },
//     {
//       size: 50,
//       size_column: [
//         {
//           id: 17,
//           column_name: "Across Shoulder",
//           inch: "13",
//           cm: "34",
//         },
//         {
//           id: 16,
//           column_name: "Waist",
//           inch: "36",
//           cm: "91",
//         },
//         {
//           id: 15,
//           column_name: "Front Length",
//           inch: "18",
//           cm: "45",
//         },
//         {
//           id: 14,
//           column_name: "Bust",
//           inch: "38",
//           cm: "96",
//         },
//       ],
//     },
//   ];

//   const { data: sizes } = useFetch(size_url);

//   const set = new Set();
//   data.forEach((d) => d.size_column.forEach((c) => set.add(c.column_name)));

//   return (
//     <>
//       <div className="p-5" />
//       <TableComponent
//         celled
//         disableHeaderPopup
//         showbottom={false}
//         headers={["Size", ...set]}
//         body={[
//           ...data.map((d) => {
//             return [
//               idToname(d.size, sizes, "size_label"),
//               ...[...set].map((s) => (
//                 <>
//                   {d.size_column.find((f) => f.column_name === s)?.cm || "0"}{" "}
//                   cm,{" "}
//                   {d.size_column.find((f) => f.column_name === s)?.inch || "0"}{" "}
//                   inch
//                 </>
//               )),
//             ];
//           }),
//         ]}
//       />
//     </>
//   );
// };

const Accordian = ({ head, body, footer, open, setOpen, noControl }) => {
  return (
    <div
      className={"acc " + (open ? "open" : "")}
      style={{ maxWidth: "850px" }}
    >
      <div
        className="acc-head"
        onClick={() => {
          if (!noControl) {
            setOpen(!open);
          }
        }}
      >
        <div style={{ fontWeight: "bold", fontSize: "15px" }}>{head}</div>
        {!noControl && (
          <ArrowIcon className={"acc-arrow " + (open ? "open" : "")} />
        )}
      </div>
      <div className={"acc-body " + (open ? "open" : "")}>
        {body}
        {footer}
      </div>
    </div>
  );
};

function isRealId(id) {
  let ok = true;
  if (
    (typeof id === "string" || id instanceof String) &&
    id.startsWith("ERP")
  ) {
    ok = false;
  }
  return ok;
}

export default NewProduct;
