import { useEffect, useState } from "react";
import Modal from "../../../components/Modal/Modal";
import { api } from "../../../api/api";
import {
  logistics_parter_url,
  order_list_url,
  sr_rate_list_url,
} from "../../../api/urls";
import unknownError from "../../../utils/unknownError";
import moment from "moment";
import {
  InputDate,
  InputNumber,
  InputSelect,
  InputText,
} from "../../../components/Inputs/Inputs";
import { useToast } from "../../../components/Toast";
import { ReactComponent as LoadingIcon } from "../../../images/loading.svg";
import Loading from "../../../components/Loading/Loading";
import "./generate_bill.scss";
import { na, na2, namt } from "../../../utils/na";
import { useFetch } from "../../../utils/useFetch";
import { idToname } from "../../../utils/idToname";
import { nameToid } from "../../../utils/nameToid";
// import { createToast } from "../../../components/Toast/ToastProvider";
import { getDateOfStatus } from "./more_info_modal";
import { isReplace, isReturn } from "./order_list";
import { modules } from "../../../api/codes";
import { DashboardImage } from "../../../components/DashboardImage/DashboardImage";
import { APIDropdown } from "../../../components/APIDropdown/APIDropdown";
import { TextChip } from "../../../components/TextChip/TextChip";

const initialState = {
  Breadth: "",
  Height: "",
  Length: "",
  Count: 1,
};

const GenerateBill = ({
  data,
  refetch,
  statusCode,
  actionName,
  regenerate,
}) => {
  const [open, setOpen] = useState(false);
  const [orders, setOrders] = useState();
  const [grossWeights, setGrossWeights] = useState({});
  const [saveLoad, setSaveLoad] = useState(false);
  const [saved, setSaved] = useState(0);
  const [generate, setGenerate] = useState(false);
  const toast = useToast();
  const view = false;
  const [gndata, setGndata] = useState(initialState);
  const [gnview, setGnview] = useState();
  const [deliveryPartner, setDeliveryPartner] = useState("");
  const [deliveryPartnerCode, setDeliveryPartnerCode] = useState("");
  const [totalWeight, setTotalWeight] = useState("");
  const [totalWeighterr, setTotalWeightErr] = useState("");
  const [errors, setErrors] = useState({});
  const { data: deliveryPartners } = useFetch(
    logistics_parter_url,
    modules.Order,
    open
  );

  const is_replace =
    statusCode === "OS_RPLCRQSTD_005" ||
    statusCode === "OS_RPLCD_010" ||
    statusCode === "OS_RPLCDND_006";

  const set = (key, value) => {
    setGndata((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };

  useEffect(() => {
    if (open === false) {
      setGndata(initialState);
      setErrors({});
      setGenerate(false);
    }
  }, [open]);

  useEffect(() => {
    if (saved === Object.keys(grossWeights).length && saved > 0) {
      setSaveLoad(false);
      setGenerate(true);
      setSaved(0);
      toast.open("Gross weights saved successfully.", "success");
    }
  }, [saved, grossWeights, toast]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSaveLoad(true);
    let total = 0;
    for (const key in grossWeights) {
      total += Number(grossWeights[key]);
    }
    if (total > totalWeight && !is_replace) {
      setTotalWeightErr("Total weight should be more than total gross weight");
      setSaveLoad(false);
      return;
    }
    for (const key in grossWeights) {
      try {
        api
          .patch(`${order_list_url}${key}/packing_list/`, {
            gross_wt: grossWeights[key],
            del_prtnr: deliveryPartner,
          })
          .then(() => {
            setSaved((v) => v + 1);
          })
          .catch((e) => unknownError(e));
      } catch (e) {
        unknownError(e);
      }
    }
  };

  useEffect(() => {
    const get = async () => {
      try {
        const res = await api.get(
          order_list_url +
            (regenerate
              ? `?filter=${JSON.stringify({
                  awb_no: data.awb_no,
                })}`
              : `?filter=${JSON.stringify({
                  shipping_address__id: data.shipping_address.id,
                  pickup_address__id: data.pickup_address.id,
                  payment_method: data.payment_method,
                  status__code: data.status_code,
                })}`)
        );
        setOrders(res.data);
        setGrossWeights(() => {
          const newData = {};
          res.data.map((d) => (newData[d.id] = ""));
          return newData;
        });
      } catch (err) {
        unknownError(err);
      }
    };
    if (open && !orders) {
      get();
    }
  }, [data.order_id, open, orders, data, regenerate]);

  useEffect(() => {
    const get = async () => {
      try {
        const res = await api.post(
          regenerate
            ? "/tagon_erp/regenerate_awb/"
            : "/tagon_erp/generate_awb/",
          {
            awb: orders.map((o) => o.id),
          }
        );
        setGnview(res.data[0]);
      } catch (err) {
        unknownError(err);
      }
    };
    if (generate) {
      get();
    }
  }, [generate, orders, regenerate]);

  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      trigger={
        <div className="blue-title">{actionName || "Generate Airway Bill"}</div>
      }
      className="modal-window product-modal"
    >
      <div
        className="close-btn"
        onClick={() => {
          setOpen(false);
        }}
      >
        &#10005;
      </div>
      <div style={{ padding: "2px" }} />
      <div className="modal-content" style={{ height: "100%" }}>
        {!generate && (
          <form
            className="modal-form"
            onSubmit={handleSubmit}
            style={{ height: "100%" }}
          >
            <Display
              data={orders ? orders : []}
              gwsetter={setGrossWeights}
              gw={grossWeights}
              setData={setOrders}
            />
            <div className="p-5" />
            {orders ? (
              <>
                <div
                  style={{
                    maxWidth: "400px",
                    marginLeft: "auto",
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <InputNumber
                    label="Total weight (In Kg)"
                    placeholder="Total weight"
                    value={totalWeight}
                    onChange={(v) => {
                      setTotalWeight(v);
                      setTotalWeightErr("");
                    }}
                    required
                    error={totalWeighterr}
                  />
                  <InputSelect
                    label="Delivery partner"
                    placeholder="Delivery partner"
                    value={idToname(deliveryPartner, deliveryPartners)}
                    options={
                      deliveryPartners
                        ? deliveryPartners.map((d) => d.printable_name)
                        : []
                    }
                    onChange={(v) => {
                      const id = nameToid(v, deliveryPartners);
                      setDeliveryPartner(id);
                      setDeliveryPartnerCode(
                        idToname(id, deliveryPartners, "code")
                      );
                    }}
                    required
                  />
                </div>
                <div className="p-5" />
                <button
                  className="button"
                  style={{
                    padding: "0px",
                    height: "35px",
                    width: "150px",
                    marginLeft: "auto",
                    marginTop: "10px",
                  }}
                >
                  {saveLoad ? <LoadingIcon height={30} width={30} /> : null}
                  Save & Next
                </button>
              </>
            ) : null}
          </form>
        )}
        {generate &&
          (gnview ? (
            <form
              className="modal-form"
              id="form-wrapper"
              style={{ height: "100%" }}
              onSubmit={(e) => {
                e.preventDefault();
                setSaveLoad(true);
                const newData = { ...gndata };
                if (newData.expctd_pckup?.length <= 2) {
                  newData.expctd_pckup = newData.expctd_pckup + "00";
                }
                try {
                  api
                    .post(
                      regenerate
                        ? "/tagon_erp/regenerate_awb/"
                        : "/tagon_erp/generate_awb/",
                      {
                        awb: orders.map((o) => o.id),
                        ship: isReturn(data) || isReplace(data) ? "rev" : "fwd",
                        dimension: {
                          ...newData,
                          ActualWeight: totalWeight,
                          weight: totalWeight,
                        },
                        generate: "pknglst",
                      },
                      {
                        headers: {
                          "Content-Type": "application/json",
                        },
                      }
                    )
                    .then(async (res) => {
                      const os = orders;
                      os.sort(function (a, b) {
                        return ("" + a?.order_id?.attr).localeCompare(
                          b?.order_id?.attr
                        );
                      });
                      let cartIds = [os[0].id];
                      try {
                        for (let i = 1; i < os.length + 1; i++) {
                          if (os?.[i]?.order_id === os?.[i - 1]?.order_id) {
                            cartIds.push(os[i].id);
                            continue;
                          }
                          await api.post(
                            regenerate
                              ? "/tagon_erp/regenerate_awb/"
                              : "/tagon_erp/generate_awb/",
                            {
                              awb: cartIds,
                              ship:
                                isReturn(data) || isReplace(data)
                                  ? "rev"
                                  : "fwd",
                              dimension: {
                                ...newData,
                                ActualWeight: totalWeight,
                                weight: totalWeight,
                              },
                              generate: "invoice",
                            },
                            {
                              headers: {
                                "Content-Type": "application/json",
                              },
                            }
                          );
                          cartIds = [os?.[i]?.id];
                        }
                      } catch (err) {
                        if (err?.response?.data?.data) {
                          toast.open(
                            JSON.stringify(err?.response?.data?.data, null, 2),
                            "error"
                          );
                          setSaveLoad(false);
                        } else {
                          unknownError(err);
                          setSaveLoad(false);
                        }
                      }

                      setSaveLoad(false);
                      setOpen(false);
                      setGenerate(false);
                      refetch();
                      toast.open(
                        "Airway bill generated successfully: " + res.data.id,
                        "success"
                      );
                    })
                    .catch((err) => {
                      if (err?.response?.data?.data) {
                        toast.open(
                          JSON.stringify(err?.response?.data?.data, null, 2),
                          "error"
                        );
                        setSaveLoad(false);
                      } else {
                        unknownError(err);
                        setSaveLoad(false);
                      }
                    });
                } catch (err) {
                  if (err?.response?.data?.data) {
                    toast.open(
                      JSON.stringify(err?.response?.data?.data, null, 2),
                      "error"
                    );
                    setSaveLoad(false);
                  } else {
                    unknownError(err);
                    setSaveLoad(false);
                  }
                }
              }}
            >
              <div id="form" style={{ maxWidth: "10000px" }}>
                <div className="air-heading">
                  <h3>
                    <b>Generate Airway Bill</b>
                  </h3>
                  {/* <KV title="Order id" value={orders[0].order_id} /> */}
                </div>
                {/* <div className="two-infos">
                  <ShipperAddress data={gnview} />
                  <ConsigneeAddress data={gnview} />
                </div>
                <div className="two-infos">
                  <ServiceOne data={gnview} />
                  <ServiceTwo data={gnview} />
                </div> */}
                <div className="two-infos">
                  {/* <Extra data={gnview} /> */}
                  <div className="air-form">
                    <div className="name">
                      Dimensions <span className="sub-name">in cm </span>:
                    </div>
                    {deliveryPartnerCode !== "dlvboys" &&
                      deliveryPartnerCode !== "shipRocket" && (
                        <table
                          style={{ maxWidth: "500px", verticalAlign: "top" }}
                        >
                          <tbody>
                            <tr style={{ verticalAlign: "top" }}>
                              <td>
                                <div
                                  className="name no-wrap"
                                  style={{ marginTop: "5px" }}
                                >
                                  Breadth :
                                </div>
                              </td>
                              <td>
                                <InputNumber
                                  disabled={view}
                                  value={gndata.Breadth}
                                  error={errors["Breadth"]}
                                  required
                                  onChange={(v) => set("Breadth", v)}
                                />
                              </td>
                            </tr>
                            <tr style={{ verticalAlign: "top" }}>
                              <td>
                                <div
                                  className="name no-wrap"
                                  style={{ marginTop: "5px" }}
                                >
                                  Height :
                                </div>
                              </td>
                              <td>
                                <InputNumber
                                  disabled={view}
                                  value={gndata.Height}
                                  error={errors["Height"]}
                                  required
                                  onChange={(v) => set("Height", v)}
                                />
                              </td>
                            </tr>
                            <tr style={{ verticalAlign: "top" }}>
                              <td>
                                <div
                                  className="name no-wrap"
                                  style={{ marginTop: "5px" }}
                                >
                                  Length :
                                </div>
                              </td>
                              <td>
                                <InputNumber
                                  disabled={view}
                                  value={gndata.Length}
                                  error={errors["Length"]}
                                  required
                                  onChange={(v) => set("Length", v)}
                                />
                              </td>
                            </tr>
                            <tr style={{ verticalAlign: "top" }}>
                              <td>
                                <div
                                  className="name no-wrap"
                                  style={{ marginTop: "5px" }}
                                >
                                  Pick-up hour :
                                </div>
                              </td>
                              <td>
                                <InputSelect
                                  disabled={view}
                                  value={gndata.expctd_pckup}
                                  error={errors["expctd_pckup"]}
                                  options={[
                                    ...[...Array(24)].map((_, index) =>
                                      ("0" + index.toString()).slice(-2)
                                    ),
                                  ]}
                                  required
                                  onChange={(v) => set("expctd_pckup", v)}
                                />
                              </td>
                            </tr>
                            {/* <tr style={{ verticalAlign: "top" }}>
                            <td>
                              <div
                                className="name no-wrap"
                                style={{ marginTop: "5px" }}
                              >
                                Count :
                              </div>
                            </td>
                            <td>
                              <InputNumber
                                disabled={view}
                                value={gndata.Count}
                                error={errors["Count"]}
                                required
                                onChange={(v) => set("Count", v)}
                              />
                            </td>
                          </tr> */}
                          </tbody>
                        </table>
                      )}
                    {deliveryPartnerCode === "dlvboys" && (
                      <table
                        style={{ maxWidth: "500px", verticalAlign: "top" }}
                      >
                        <tbody>
                          <tr style={{ verticalAlign: "top" }}>
                            <td>
                              <div
                                className="name no-wrap"
                                style={{ marginTop: "5px" }}
                              >
                                Origin :
                              </div>
                            </td>
                            <td>
                              <InputText
                                disabled={view}
                                value={gndata.origin}
                                error={errors["origin"]}
                                required
                                onChange={(v) => set("origin", v)}
                              />
                            </td>
                          </tr>
                          <tr style={{ verticalAlign: "top" }}>
                            <td>
                              <div
                                className="name no-wrap"
                                style={{ marginTop: "5px" }}
                              >
                                Destination :
                              </div>
                            </td>
                            <td>
                              <InputText
                                disabled={view}
                                value={gndata.dest}
                                error={errors["dest"]}
                                required
                                onChange={(v) => set("dest", v)}
                              />
                            </td>
                          </tr>
                          <tr style={{ verticalAlign: "top" }}>
                            <td>
                              <div
                                className="name no-wrap"
                                style={{ marginTop: "5px" }}
                              >
                                Expected delivery :
                              </div>
                            </td>
                            <td>
                              <InputDate
                                disabled={view}
                                value={gndata.xpctdate}
                                error={errors["xpctdate"]}
                                noTime
                                required
                                onChange={(v) => set("xpctdate", v)}
                              />
                            </td>
                          </tr>
                          <tr style={{ verticalAlign: "top" }}>
                            <td>
                              <div
                                className="name no-wrap"
                                style={{ marginTop: "5px" }}
                              >
                                Box size :
                              </div>
                            </td>
                            <td>
                              <InputText
                                disabled={view}
                                value={gndata.boxsize}
                                error={errors["boxsize"]}
                                required
                                onChange={(v) => set("boxsize", v)}
                              />
                            </td>
                          </tr>
                          <tr style={{ verticalAlign: "top" }}>
                            <td>
                              <div
                                className="name no-wrap"
                                style={{ marginTop: "5px" }}
                              >
                                Count :
                              </div>
                            </td>
                            <td>
                              <InputNumber
                                disabled={view}
                                value={gndata.Count}
                                error={errors["Count"]}
                                required
                                onChange={(v) => set("Count", v)}
                              />
                            </td>
                          </tr>
                          <tr style={{ verticalAlign: "top" }}>
                            <td>
                              <div
                                className="name no-wrap"
                                style={{ marginTop: "5px" }}
                              >
                                Chg wt (in Kg) :
                              </div>
                            </td>
                            <td>
                              <InputNumber
                                disabled={view}
                                value={gndata.chrg_wt}
                                error={errors["chrg_wt"]}
                                required
                                onChange={(v) => set("chrg_wt", v)}
                              />
                            </td>
                          </tr>
                          <tr style={{ verticalAlign: "top" }}>
                            <td>
                              <div
                                className="name no-wrap"
                                style={{ marginTop: "5px" }}
                              >
                                Earning :
                              </div>
                            </td>
                            <td>
                              <InputNumber
                                disabled={view}
                                value={gndata.earning}
                                error={errors["earning"]}
                                required
                                onChange={(v) => set("earning", v)}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                    {deliveryPartnerCode === "shipRocket" && (
                      <table
                        style={{ maxWidth: "500px", verticalAlign: "top" }}
                      >
                        <tbody>
                          <tr style={{ verticalAlign: "top" }}>
                            <td>
                              <div
                                className="name no-wrap"
                                style={{ marginTop: "5px" }}
                              >
                                Breadth :
                              </div>
                            </td>
                            <td>
                              <InputNumber
                                disabled={view}
                                value={gndata.breadth}
                                error={errors["breadth"]}
                                required
                                onChange={(v) => set("breadth", v)}
                              />
                            </td>
                          </tr>
                          <tr style={{ verticalAlign: "top" }}>
                            <td>
                              <div
                                className="name no-wrap"
                                style={{ marginTop: "5px" }}
                              >
                                Height :
                              </div>
                            </td>
                            <td>
                              <InputNumber
                                disabled={view}
                                value={gndata.height}
                                error={errors["height"]}
                                required
                                onChange={(v) => set("height", v)}
                              />
                            </td>
                          </tr>
                          <tr style={{ verticalAlign: "top" }}>
                            <td>
                              <div
                                className="name no-wrap"
                                style={{ marginTop: "5px" }}
                              >
                                Length :
                              </div>
                            </td>
                            <td>
                              <InputNumber
                                disabled={view}
                                value={gndata.length}
                                error={errors["length"]}
                                required
                                onChange={(v) => set("length", v)}
                              />
                            </td>
                          </tr>
                          <tr style={{ verticalAlign: "top" }}>
                            <td>
                              <div
                                className="name no-wrap"
                                style={{ marginTop: "5px" }}
                              >
                                SR partner :
                              </div>
                            </td>
                            <td>
                              <SRPartnerWithRate
                                gndata={gndata}
                                errors={errors}
                                set={set}
                                view={view}
                                pickupPincode={data.shipping_address.pincode}
                                deliveryPincode={data.pickup_address.pincode}
                                isCod={
                                  data.payment_method === "Cash On Delivery"
                                }
                              />
                            </td>
                          </tr>
                          {/* <tr style={{ verticalAlign: "top" }}>
                            <td>
                              <div
                                className="name no-wrap"
                                style={{ marginTop: "5px" }}
                              >
                                Count :
                              </div>
                            </td>
                            <td>
                              <InputNumber
                                disabled={view}
                                value={gndata.Count}
                                error={errors["Count"]}
                                required
                                onChange={(v) => set("Count", v)}
                              />
                            </td>
                          </tr> */}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
                {!view && (
                  <>
                    <div style={{ padding: "10px" }} />
                    <button
                      className="button"
                      style={{
                        padding: "0px",
                        height: "35px",
                        width: "150px",
                        marginLeft: "auto",
                        marginTop: "10px",
                      }}
                    >
                      {saveLoad ? <LoadingIcon height={30} width={30} /> : null}
                      Generate
                    </button>
                  </>
                )}
                <div style={{ padding: "10px" }} />
              </div>
            </form>
          ) : (
            <div
              style={{
                flex: 1,
                height: "300px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Loading />
            </div>
          ))}
      </div>
    </Modal>
  );
};

// function SRPartner({ view, gndata, errors, set }) {
//   const srPartnerData = useFetch(sr_partner_list_url);
//   return (
//     <APIDropdown
//       apiData={{
//         data: srPartnerData?.data?.courier_data,
//         loading: srPartnerData.loading,
//         refetch: srPartnerData.refetch,
//       }}
//       disabled={view}
//       value={gndata.courier_id}
//       error={errors["courier_id"]}
//       required
//       parameterForGet="master_company"
//       onChange={(v) => set("courier_id", v)}
//     />
//   );
// }

function SRPartnerWithRate({
  view,
  gndata,
  errors,
  set,
  deliveryPincode,
  pickupPincode,
  isCod,
}) {
  const srRateData = useFetch(
    sr_rate_list_url +
      `?pckuppin=${pickupPincode}&delvpin=${deliveryPincode}&iscod=${
        isCod ? "1" : "0"
      }`,
    "",
    true,
    "post"
  );
  return (
    <APIDropdown
      apiData={{
        data: srRateData?.data?.data?.available_courier_companies?.map((x) => ({
          printable_name: `${x?.courier_name} - ${srRateData?.data?.currency} ${x?.rate}`,
          id: x?.courier_company_id,
        })),
        loading: srRateData.loading,
        refetch: srRateData.refetch,
      }}
      disabled={view}
      value={gndata.courier_id}
      error={errors["courier_id"]}
      required
      // parameterForGet="master_company"
      onChange={(v) => set("courier_id", v)}
    />
  );
}

// const ShipperAddress = ({ data: x }) => {
//   const d = x?.Request?.Shipper || {};
//   const data = {
//     "Origin area": d.OriginArea,
//     "Customer code": d.CustomerCode,
//     "Customer name": d.CustomerName,
//     "Customer address 1": d.CustomerAddress1,
//     "Customer address 2": d.CustomerAddress2,
//     "Customer address 3": d.CustomerAddress3,
//     "Customer pincode": d.CustomerPincode,
//     "Customer mobile": d.CustomerMobile,
//     "Customer telephone": d.CustomerTelephone,
//     "Customer email id": d.CustomerEmailID,
//     Sender: d.Sender,
//     "Vendor code": d.VendorCode,
//     "Is to pay customer": d.IsToPayCustomer,
//   };

//   useEffect(() => {
//     if (!data["Customer pincode"] || data["Customer pincode"]?.length !== 6) {
//       createToast(
//         "Invalid shipper pincode, airway bill might not generate.",
//         "warning"
//       );
//     }
//     if (
//       !data["Customer mobile"] ||
//       (data["Customer mobile"]?.length !== 12 &&
//         data["Customer mobile"]?.length !== 10)
//     ) {
//       createToast(
//         "Invalid shipper mobile no, airway bill might not generate",
//         "warning"
//       );
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   return (
//     <div className="info-box">
//       <div className="title">Shipper Address</div>
//       {Object.keys(data).map((d, idx) => (
//         <KV key={idx} title={d} value={data[d]} />
//       ))}
//     </div>
//   );
// };

// const ConsigneeAddress = ({ data: x }) => {
//   const d = x?.Request?.Consignee || {};
//   const data = {
//     "Consignee name": d.ConsigneeName,
//     "Consignee address 1": d.ConsigneeAddress1,
//     "Consignee address 2": d.ConsigneeAddress2,
//     "Consignee address 3": d.ConsigneeAddress3,
//     "Consignee pincode": d.ConsigneePincode,
//     "Consignee mobile": d.ConsigneeMobile,
//     "Consignee telephone": d.ConsigneeTelephone,
//     "Consignee attention": d.ConsigneeAttention,
//   };
//   useEffect(() => {
//     if (!data["Consignee pincode"] || data["Consignee pincode"]?.length !== 6) {
//       createToast(
//         "Invalid consignee pincode, airway bill might not generate.",
//         "warning"
//       );
//     }
//     if (
//       !data["Consignee mobile"] ||
//       (data["Consignee mobile"]?.length !== 12 &&
//         data["Consignee mobile"]?.length !== 10)
//     ) {
//       createToast(
//         "Invalid consignee mobile no, airway bill might not generate",
//         "warning"
//       );
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   return (
//     <div className="info-box">
//       <div className="title">Consignee Address</div>
//       {Object.keys(data).map((d, idx) => (
//         <KV key={idx} title={d} value={data[d]} />
//       ))}
//     </div>
//   );
// };

// const ServiceOne = ({ data: x }) => {
//   const d = x?.Request?.Services || {};
//   const data = {
//     "Product code": d.ProductCode,
//     "Product type": d.ProductType,
//     "SubProduct code": d.SubProductCode,
//     "Piece count": d.PieceCount,
//     "Actual weight": d.ActualWeight,
//     "Pack type": d.PackType,
//     "IsReverse pickup": d.IsReversePickup,
//     "Invoice no": d.InvoiceNo,
//   };

//   return (
//     <div className="info-box">
//       <div className="title">Service</div>
//       {Object.keys(data).map((d, idx) => (
//         <KV key={idx} title={d} value={data[d]} />
//       ))}
//     </div>
//   );
// };

// const ServiceTwo = ({ data: x }) => {
//   const d = x?.Request?.Services || {};
//   const data = {
//     "Special instruction": d.SpecialInstruction,
//     "Declared value": d.DeclaredValue,
//     "Collectable amount": d.CollectableAmount,
//     "Credit reference no": d.CreditReferenceNo,
//     "PDF output not required": d.PDFOutputNotRequired,
//     "Pickup date": d.PickupDate,
//     "Pickup time": d.PickupTime,
//     "Register pickup": d.RegisterPickup.toString(),
//   };

//   return (
//     <div className="info-box">
//       <div className="title">Service</div>
//       {Object.keys(data).map((d, idx) => (
//         <KV key={idx} title={d} value={data[d]} />
//       ))}
//     </div>
//   );
// };

// const Extra = ({ data: x }) => {
//   const d = x?.Request?.Returnadds || {};
//   const data = {
//     "Return address 1": d.ReturnAddress1,
//     "Return address 2": d.ReturnAddress2,
//     "Return address 3": d.ReturnAddress3,
//     "Return pincode": d.ReturnPincode,
//     "Return telephone": d.ReturnTelephone,
//     "Return mobile": d.ReturnMobile,
//     "Return email id": d.ReturnEmailID,
//   };

//   useEffect(() => {
//     if (
//       !data["Return mobile"] ||
//       (data["Return mobile"]?.length !== 12 &&
//         data["Return mobile"]?.length !== 10)
//     ) {
//       createToast(
//         "Invalid return mobile no, airway bill might not generate",
//         "warning"
//       );
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   return (
//     <div className="info-box">
//       {Object.keys(data).map((d, idx) => (
//         <KV key={idx} title={d} value={data[d]} />
//       ))}
//     </div>
//   );
// };

// const KV = ({ title, value }) => {
//   return (
//     <div style={{ fontSize: "small" }}>
//       <b>{title} : </b>
//       {na(value)}
//     </div>
//   );
// };

const Display = ({ data, gwsetter, gw, setData }) => {
  if (data.length === 0) {
    return (
      <div
        style={{
          flex: 1,
          height: "300px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loading />
      </div>
    );
  }
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      {data.map((d) => {
        return (
          <ProductDetailsSection
            key={d.id}
            data={d}
            gwsetter={gwsetter}
            gw={gw}
            setData={setData}
            mainData={data}
          />
        );
      })}
    </div>
  );
};

const ProductDetailsSection = ({
  data: d,
  cancel,
  is_return,
  gwsetter,
  gw,
  setData,
  mainData,
}) => {
  return (
    <div className="product-info" style={{ position: "relative" }}>
      {mainData && mainData.length > 1 && (
        <div
          className="close-btn"
          onClick={() => {
            setData(mainData.filter((x) => x.id !== d.id));
            gwsetter((x) => {
              const newData = { ...x };
              delete newData[d.id];
              return newData;
            });
          }}
        >
          &#10005;
        </div>
      )}
      <div className="image-section">
        <DashboardImage
          src={d.tagon_product_image}
          height="200px"
          width="200px"
        />
      </div>
      <div className="details-section">
        {d?.prod_json?.title && (
          <div style={{ fontSize: "15px", fontWeight: "bold" }}>
            {d.prod_json.title}
          </div>
        )}
        {d?.prod_json?.description && <div>{d.prod_json.description}</div>}
        <div className="twoside-details">
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <div>
              <b>Size : </b>
              {na(d.size)}
            </div>
            <div>
              <b>Color : </b>
              {na(d.color)}
            </div>
            <div>
              <b>Quantity : </b>
              {na(d.quantity)}
            </div>
            <div>
              <b>Selling price : </b>
              {namt(d.subtotal_after_tax_amt)}
            </div>
            <div>
              <b>MRP : </b>
              {namt(d.mrp_qty)}
            </div>
            <div className="wrap">
              <b>Payment mode : </b>
              {na(d.payment_method)}
            </div>
            <div>
              <b>Brand : </b>
              {na(d?.brand_name)}
            </div>
            <div>
              <b>Sold by : </b>
              {na(d?.prod_json?.seller_name)}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <div>
              <b>Order id : </b>
              {na2(
                d.order_id,
                <span style={{ color: "#077ACE" }}>{d.order_id}</span>
              )}
            </div>
            <div>
              <b>Order date : </b>
              {na2(d.created, moment(d.created).format("DD-MM-YYYY"))}
            </div>
            {!cancel && !is_return && (
              <div>
                <b>Tracking id : </b>
                <span style={{ color: "#077ACE" }}>{d?.order_id}</span>
              </div>
            )}
            {cancel && (
              <div>
                <b>Cancel id : </b>
                <span style={{ color: "#077ACE" }}>{na(d.cancel_id)}</span>
              </div>
            )}
            {cancel && (
              <div>
                <b>Cancel date : </b>
                {na2(
                  getDateOfStatus(d?.order_logs, "OS_CNCLDRQSTD_032"),
                  moment(
                    getDateOfStatus(d?.order_logs, "OS_CNCLDRQSTD_032")
                  ).format("DD-MM-YYYY")
                )}
              </div>
            )}
            {cancel && (
              <div>
                <b>Cancellation reason : </b>
                {na(d.reason_choices)}
              </div>
            )}
            {is_return && (
              <div>
                <b>Return id : </b>
                <span style={{ color: "#077ACE" }}>{na(d.return_id)}</span>
              </div>
            )}
            {is_return && (
              <div>
                <b>Return date : </b>
                {na2(
                  getDateOfStatus(d?.order_logs, "OS_RTRNRQSTD_015"),
                  moment(
                    getDateOfStatus(d?.order_logs, "OS_RTRNRQSTD_015")
                  ).format("DD-MM-YYYY")
                )}
              </div>
            )}
            {is_return && (
              <div>
                <b>Reason for returning : </b>
                {na(d.reason_choices)}
              </div>
            )}
            {!cancel && !is_return && (
              <div>
                <b>Delivered date :</b>{" "}
                {na2(
                  getDateOfStatus(d?.order_logs, "OS_DLVRD_003"),
                  moment(getDateOfStatus(d?.order_logs, "OS_DLVRD_003")).format(
                    "DD-MM-YYYY"
                  )
                )}
              </div>
            )}
            {!cancel && !is_return && (
              <div>
                <b>Expected delivery date :</b>{" "}
                {na2(
                  d.expected_delivery,
                  moment(d.expected_delivery).format("DD-MM-YYYY")
                )}
              </div>
            )}
            <TextChip text={d.action_name} color={"#" + d.icon_color_code} />
            {/* <div
              className="active-green no-wrap"
              style={{
                backgroundColor: "#" + d.icon_color_code,
                width: "min-content",
                padding: "5px 20px",
                letterSpacing: "0.5px",
              }}
            >
              {na(d.action_name)}
            </div> */}
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <InputNumber
              label="Net weight (In Kg)"
              placeholder="Enter gross weight"
              value={d.net_wt}
              disabled
              onChange={(v) => {
                gw[d.id] = v;
              }}
            />
            <InputNumber
              label="Gross weight (In Kg)"
              placeholder="Enter gross weight"
              value={gw[d.id]}
              required
              onChange={(v) => {
                const newData = { ...gw };
                newData[d.id] = v;
                gwsetter(newData);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenerateBill;
