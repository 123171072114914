import { useEffect, useState } from "react";
import { api } from "../../../api/api";
import {
  InputNumber,
  InputText,
  InputTextBox,
} from "../../../components/Inputs/Inputs";
import Modal from "../../../components/Modal/Modal";
import { useToast } from "../../../components/Toast";
import { seller_bank } from "../../../api/urls";
import unknownError from "../../../utils/unknownError";
import { moveUpToError } from "../../../utils/moveUpToError";

const initialState = {
  code: "",
  printable_name: "",
  description: "",
  bank_name: "",
  bank_branch_address: "",
  account_name: "",
  account_number: "",
  ifsc_code: "",
  upi_id_number: "",
};

const NewSellerBankModal = ({ trigger, refetch, editData, seller_id }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const toast = useToast();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (editData) {
      setData(editData);
    }
  }, [editData]);

  useEffect(() => {
    if (open === false && !editData) {
      setData({ ...initialState });
      setErrors({});
    }
  }, [open, setData, setErrors, editData]);

  const set = (key, value) => {
    setData((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };

  const view = false;

  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      trigger={trigger}
      className="modal-window"
      overlayClassName="no-overlay"
    >
      <div className="modal-content">
        <div className="modal-heading">Seller Bank</div>
        <div className="modal-form">
          <form
            id="form-wrapper"
            onSubmit={(e) => {
              e.preventDefault();
              setIsSaving(true);
              const newData = { ...data };
              newData["seller"] = seller_id;
              try {
                if (editData) {
                  api
                    .patch(seller_bank + data.id + "/", newData)
                    .then((res) => {
                      refetch();
                      setOpen(false);
                      toast.open(
                        "Edited data with id: " + res.data.id,
                        "success"
                      );
                      setIsSaving(false);
                    })
                    .catch((err) => {
                      if (err?.response?.data) {
                        setErrors(err.response.data);
                        moveUpToError();
                      } else {
                        unknownError(err);
                      }
                      setIsSaving(false);
                    });
                } else {
                  api
                    .post(seller_bank, newData)
                    .then((res) => {
                      refetch();
                      setOpen(false);
                      toast.open(
                        "Added data with id: " + res.data.id,
                        "success"
                      );
                      setIsSaving(false);
                    })
                    .catch((err) => {
                      if (err?.response?.data) {
                        setErrors(err.response.data);
                        moveUpToError();
                      } else {
                        unknownError(err);
                      }
                      setIsSaving(false);
                    });
                }
              } catch (err) {
                unknownError(err);
                setIsSaving(false);
              }
            }}
          >
            <div className="twoside-form">
              <div className="formside">
                <InputText
                  label="Code"
                  placeholder="Code"
                  disabled={view}
                  required
                  value={data.code}
                  onChange={(v) => set("code", v)}
                  error={errors["code"]}
                />
                <InputText
                  label="Printable name"
                  placeholder="Printable name"
                  disabled={view}
                  required
                  value={data.printable_name}
                  onChange={(v) => set("printable_name", v)}
                  error={errors["printable_name"]}
                />
                <InputTextBox
                  label="Description"
                  placeholder="Description"
                  disabled={view}
                  required
                  value={data.description}
                  onChange={(v) => set("description", v)}
                  error={errors["description"]}
                />
                <InputText
                  label="Bank name"
                  placeholder="Bank name"
                  required
                  disabled={view}
                  value={data.bank_name}
                  onChange={(v) => set("bank_name", v)}
                  error={errors["bank_name"]}
                />
              </div>
              <div className="formside">
                <InputText
                  label="Bank address"
                  placeholder="Bank address"
                  required
                  disabled={view}
                  value={data.bank_branch_address}
                  onChange={(v) => set("bank_branch_address", v)}
                  error={errors["bank_branch_address"]}
                />
                <InputText
                  label="Account name"
                  placeholder="Account name"
                  required
                  disabled={view}
                  value={data.account_name}
                  onChange={(v) => set("account_name", v)}
                  error={errors["account_name"]}
                />
                <InputNumber
                  label="Account number"
                  placeholder="Account number"
                  required
                  disabled={view}
                  value={data.account_number}
                  onChange={(v) => set("account_number", v)}
                  error={errors["account_number"]}
                />
                <InputText
                  label="IFSC code"
                  placeholder="IFSC code"
                  required
                  disabled={view}
                  value={data.ifsc_code}
                  onChange={(v) => set("ifsc_code", v)}
                  error={errors["ifsc_code"]}
                />
                <InputText
                  label="UPI id"
                  placeholder="UPI id"
                  required
                  disabled={view}
                  value={data.upi_id_number}
                  onChange={(v) => set("upi_id_number", v)}
                  error={errors["upi_id_number"]}
                />
              </div>
            </div>
            <div style={{ padding: "10px" }} />
          </form>
        </div>
        <div className="modal-actions">
          <div className="actions">
            <button
              className="btn-red"
              type="button"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              form="form-wrapper"
              className="btn"
              onClick={() => {
                moveUpToError();
              }}
              disabled={isSaving}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NewSellerBankModal;
