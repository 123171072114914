import { Header } from "semantic-ui-react";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import {
  InputDate,
  InputImage,
  InputNumber,
  InputSelectMultiple,
  InputText,
  InputTextBox,
  InputToggle,
} from "../../../components/Inputs/Inputs";
import BackButton from "../../../components/BackButton/BackButton";
import { api } from "../../../api/api";
import objToFormdata from "../../../utils/objToFormdata";
import { useToast } from "../../../components/Toast";
import {
  advertisements_url,
  country_url,
  first_category_url,
  product_url,
  second_category_url,
  third_category_url,
  widget_title_url,
} from "../../../api/urls";
import { idTonameMany } from "../../../utils/idToname";
import { nameToidMany } from "../../../utils/nameToid";
import { urlTofile } from "../../../utils/urlTofile";
import unknownError from "../../../utils/unknownError";
import { APIDropdown } from "../../../components/APIDropdown/APIDropdown";
import Loading from "../../../components/Loading/Loading";
import { moveUpToError } from "../../../utils/moveUpToError";

const initialState = {
  code: "",
  description: "",
  printable_name: "",
  disable: false,
  is_active: false,
  post_content: "",
  ad_image: "",
  discount: "",
  discount_lte: "",
  discount_gte: "",
  price: "",
  price_lte: "",
  price_gte: "",
  deal_start: "",
  deal_end: "",
  products: "",
  first_category: "",
  second_category: "",
  third_category: "",
  country: "",
  title: "",
};
const title = "Advertisement";
const backUrl = "/advertisements";
const apiUrl = advertisements_url;

const NewAds = ({ edit, view }) => {
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [titles, setTitles] = useState();
  const history = useHistory();
  const params = useParams();
  const toast = useToast();
  const [isGetting, setIsGetting] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (edit || view || params.id) {
      const getData = async () => {
        try {
          const res = await api.get(apiUrl + params.id + "/");
          res.data.ad_image = await urlTofile(res.data.ad_image);
          setData(res.data);
          setIsGetting(false);
        } catch (err) {
          unknownError(err);
          setIsGetting(false);
        }
      };

      getData();
    } else {
      setIsGetting(false);
    }
  }, [params.id, edit, view]);

  useEffect(() => {
    const get = async () => {
      try {
        const res = await api.get(widget_title_url);
        setTitles(res.data.filter((d) => d.is_advertise));
      } catch (err) {
        unknownError(err);
      }
    };
    get();
  }, []);

  const set = (key, value) => {
    setData((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };

  if (isGetting) return <Loading />;

  return (
    <form
      id="form-wrapper"
      onSubmit={(e) => {
        e.preventDefault();
        setIsSaving(true);
        const newData = { ...data };
        try {
          if (edit && params.id) {
            api
              .patch(apiUrl + data.id + "/", objToFormdata(newData))
              .then((res) => {
                toast.open("Edited data with id: " + res.data.id, "success");
                history.push(backUrl);
                setIsSaving(false);
              })
              .catch((err) => {
                if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
                setIsSaving(false);
              });
          } else {
            api
              .post(apiUrl, objToFormdata(newData))
              .then((res) => {
                toast.open("Added data with id: " + res.data.id, "success");
                history.push(backUrl);
                setIsSaving(false);
              })
              .catch((err) => {
                if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
                setIsSaving(false);
              });
          }
        } catch (err) {
          unknownError(err);
          setIsSaving(false);
        }
      }}
    >
      <div id="form">
        <BackButton href={backUrl} />
        <Header>{title}</Header>
        <InputText
          label="Code"
          placeholder="Code"
          disabled={view}
          value={data.code}
          error={errors["code"]}
          required
          onChange={(v) => set("code", v)}
        />
        <InputText
          label="Printable name"
          placeholder="Printable name"
          disabled={view}
          value={data.printable_name}
          error={errors["printable_name"]}
          required
          onChange={(v) => set("printable_name", v)}
        />
        <InputTextBox
          label="Description"
          placeholder="Description"
          disabled={view}
          value={data.description}
          error={errors["description"]}
          onChange={(v) => set("description", v)}
        />
        <InputText
          label="Ads content"
          placeholder="Ads content"
          disabled={view}
          value={data.post_content}
          error={errors["post_content"]}
          required
          onChange={(v) => set("post_content", v)}
        />
        <APIDropdown
          label="Country"
          placeholder="Country"
          required
          disabled={view}
          value={data.country}
          url={country_url}
          onChange={(v) => set("country", v)}
          error={errors["country"]}
          hasMultipleSelection
        />
        <APIDropdown
          label="Product"
          placeholder="Product"
          url={product_url}
          value={data.products}
          onChange={(v) => set("products", v)}
          hasPagination
          required
          error={errors["products"]}
        />
        <InputSelectMultiple
          label="Title"
          placeholder="Title"
          value={idTonameMany(data.title, titles)}
          options={titles ? titles.map((d) => d.printable_name) : []}
          onChange={(v) => set("title", nameToidMany(v, titles))}
          error={errors["title"]}
          required
        />
        <APIDropdown
          label="First category"
          placeholder="First category"
          required
          disabled={view}
          value={data.first_category}
          url={first_category_url}
          onChange={(v) => set("first_category", v)}
          error={errors["first_category"]}
        />
        <APIDropdown
          label="Second category"
          placeholder="Second category"
          required
          disabled={view}
          value={data.second_category}
          url={second_category_url}
          onChange={(v) => set("second_category", v)}
          error={errors["second_category"]}
        />
        <APIDropdown
          label="Third category"
          placeholder="Third category"
          required
          disabled={view}
          value={data.third_category}
          url={third_category_url}
          onChange={(v) => set("third_category", v)}
          error={errors["third_category"]}
        />
        <InputImage
          label="Ads image"
          placeholder="ads image"
          disabled={view}
          value={data.ad_image}
          error={errors["ad_image"]}
          required
          onChange={(v) => set("ad_image", v)}
        />
        <InputNumber
          label="Discount"
          placeholder="Discount"
          disabled={view}
          value={data.discount}
          error={errors["discount"]}
          onChange={(v) => set("discount", v)}
        />
        <InputNumber
          label="Price"
          placeholder="Price"
          disabled={view}
          value={data.price}
          error={errors["price"]}
          onChange={(v) => set("price", v)}
        />
        <InputDate
          label="Deal start"
          placeholder="Deal start"
          disabled={view}
          value={data.deal_start}
          error={errors["deal_start"]}
          onChange={(v) => set("deal_start", v)}
        />
        <InputDate
          label="Deal end"
          placeholder="Deal end"
          disabled={view}
          value={data.deal_end}
          error={errors["deal_end"]}
          onChange={(v) => set("deal_end", v)}
        />
        <InputToggle
          label="Active"
          disabled={view}
          value={data.is_active}
          error={errors["is_active"]}
          onChange={(v) => set("is_active", v)}
        />
        {!view && (
          <>
            <div style={{ padding: "10px" }} />
            <div className="actions">
              <button
                className="btn-red"
                type="button"
                onClick={() => {
                  history.push(backUrl);
                }}
              >
                Cancel
              </button>
              <button
                className="btn"
                onClick={() => {
                  moveUpToError();
                }}
                disabled={isSaving}
              >
                Save
              </button>
            </div>
          </>
        )}
        <div style={{ padding: "10px" }} />
      </div>
    </form>
  );
};

export default NewAds;
