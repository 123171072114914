import moment from "moment";
import { delivery_boy_temp_url } from "../../../api/urls";
import { TextChip } from "../../../components/TextChip/TextChip";
import { Link } from "react-router-dom";
import { useFetchWithPagination } from "../../../utils/useFetchWithPagination";
import BackButton from "../../../components/BackButton/BackButton";
import { Header, Icon, Input } from "semantic-ui-react";
import { useState } from "react";
import { ProtectedElement } from "../../../components/ProtectedElement/ProtectedElement";
import { modules, perms } from "../../../api/codes";
import { TableComponent } from "../../../components/TableComponent/TableComponent";
import ActionDropdown from "../../../components/ActionDropdown/ActionDropdown";
import { ReactComponent as UserIcon } from "../../../images/user.svg";

export function B2BProfileList() {
  const apiUrl = delivery_boy_temp_url;
  const title = "B2B Verification";
  const module = modules.B2BVerification;
  const currentRoute = "b2b_verification";
  const columns = [
    {
      headerName: "Name",
      field: "form_data.first_name",
      flex: 1,
      renderCell: (params) =>
        `${params?.row?.form_data?.first_name} ${params?.row?.form_data?.last_name}`,
    },
    {
      headerName: "Mobile number",
      field: "form_data.mobile_number",
      flex: 1,
      renderCell: (params) => params?.row?.form_data?.mobile_number,
    },
    {
      headerName: "Upload date",
      field: "created_at",
      flex: 1,
      renderCell: (params) => moment(params.value).format("DD/MM/YYYY"),
    },
    {
      headerName: "Verified ?",
      field: "form_status",
      flex: 1,
      renderCell: (params) => <TextChip text={params.value} color="#FF6F31" />,
    },
    {
      headerName: "Actions",
      field: "actions",
      flex: 1,
      renderCell: (params) => (
        <ActionDropdown
          options={(closer) => {
            return (
              <ProtectedElement module={module} perm={perms.verifyB2BProfile}>
                <Link to={currentRoute + `/verify/${params.row.id}`}>
                  <div
                    className="option"
                    onClick={() => {
                      closer();
                    }}
                  >
                    <div className="icon-btn purple-icon">
                      <UserIcon />
                    </div>
                    <div className="purple-title">Verify profile</div>
                  </div>
                </Link>
              </ProtectedElement>
            );
          }}
        />
      ),
    },
  ];

  const { data, pagination } = useFetchWithPagination(apiUrl);
  const { search, setSearch } = useState("");
  return (
    <>
      <BackButton />
      <div id="heading">
        <Header>{title}</Header>
        <div className="right">
          <Input
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            icon={<Icon name="search" link id="search-icon" />}
          />
        </div>
      </div>
      <TableComponent
        columns={columns}
        rows={data.data?.results || []}
        loading={data.loading}
      />
      {pagination}
    </>
  );
}
