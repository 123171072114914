import { useEffect, useState } from "react";
import Modal from "../../../components/Modal/Modal";
import { api } from "../../../api/api";
import unknownError from "../../../utils/unknownError";
import moment from "moment";
import { ReactComponent as LoadingIcon } from "../../../images/loading.svg";
import Loading from "../../../components/Loading/Loading";
import "./generate_bill.scss";
import { na, na2, namt } from "../../../utils/na";
import { useToast } from "../../../components/Toast";
import { getDateOfStatus } from "./more_info_modal";
import { order_list_url } from "../../../api/urls";
import { DashboardImage } from "../../../components/DashboardImage/DashboardImage";
import { TextChip } from "../../../components/TextChip/TextChip";

const CancelBill = ({ data, refetch, statusCode, actionName }) => {
  const [open, setOpen] = useState(false);
  const [orders, setOrders] = useState();
  const [dataForSubmit, setDataForSubmit] = useState({});
  const [saveLoad, setSaveLoad] = useState(false);

  const toast = useToast();

  // const set = (key, value) => {
  //   setGndata((d) => {
  //     const newData = { ...d };
  //     newData[key] = value;
  //     return newData;
  //   });
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSaveLoad(true);
    try {
      api
        .post("/tagon_erp/cancel_awb/", dataForSubmit, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          toast.open("action performed successfully", "success");
          refetch();
          setOpen(false);
          setSaveLoad(false);
        })
        .catch((err) => {
          if (err.response?.data) {
            toast.open(err?.response?.data?.data, "error");
            setSaveLoad(false);
          } else {
            unknownError(err);
            setSaveLoad(false);
          }
        });
    } catch (err) {
      unknownError(err);
      setSaveLoad(false);
    }
  };

  useEffect(() => {
    const get = async () => {
      try {
        const res = await api.get(
          `${order_list_url}?filter=${JSON.stringify({
            awb_no: data.awb_no,
          })}`
        );
        setOrders(res.data);
        setDataForSubmit(() => {
          const newData = {
            awb: [res?.data?.[0]?.awb_no],
            ship: data?.shipping_return_label ? "rev" : "fwd",
          };
          // res.data.map((d) => newData.awb.push(d.awb_no));
          return newData;
        });
      } catch (err) {
        unknownError(err);
      }
    };
    if (open && !orders) {
      get();
    }
  }, [data.order_id, open, orders, data, statusCode]);

  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      trigger={
        <div className="blue-title">{actionName || "Generate Airway Bill"}</div>
      }
      className="modal-window product-modal"
    >
      <div
        className="close-btn"
        onClick={() => {
          setOpen(false);
        }}
      >
        &#10005;
      </div>
      <div style={{ padding: "2px" }} />
      <div className="modal-content">
        <form className="modal-form" onSubmit={handleSubmit}>
          <Display data={orders ? orders : []} />
          <button
            className="button"
            style={{
              padding: "0px",
              height: "35px",
              width: "150px",
              marginLeft: "auto",
              marginTop: "10px",
            }}
          >
            {saveLoad ? <LoadingIcon height={30} width={30} /> : null}
            Save & Next
          </button>
        </form>
      </div>
    </Modal>
  );
};

const Display = ({ data }) => {
  if (data.length === 0) {
    return (
      <div
        style={{
          flex: 1,
          height: "300px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loading />
      </div>
    );
  }
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      {data.map((d) => {
        return <ProductDetailsSection key={d.id} data={d} />;
      })}
    </div>
  );
};

const ProductDetailsSection = ({ data: d, cancel, is_return }) => {
  return (
    <div className="product-info" style={{ position: "relative" }}>
      <div className="image-section">
        <DashboardImage
          src={d.tagon_product_image}
          height="200px"
          width="200px"
        />
      </div>
      <div className="details-section">
        {d?.prod_json?.title && (
          <div style={{ fontSize: "15px", fontWeight: "bold" }}>
            {d.prod_json.title}
          </div>
        )}
        {d?.prod_json?.description && <div>{d.prod_json.description}</div>}
        <div className="twoside-details">
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <div>
              <b>Size : </b>
              {na(d.size)}
            </div>
            <div>
              <b>Color : </b>
              {na(d.color)}
            </div>
            <div>
              <b>Quantity : </b>
              {na(d.quantity)}
            </div>
            <div>
              <b>Selling price : </b>
              {namt(d.subtotal_after_tax_amt)}
            </div>
            <div>
              <b>MRP : </b>
              {namt(d.mrp_qty)}
            </div>
            <div>
              <b>Brand : </b>
              {na(d?.brand_name)}
            </div>
            <div>
              <b>Sold by : </b>
              {na(d?.prod_json?.seller_name)}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <div>
              <b>Order id : </b>
              {na2(
                d.order_id,
                <span style={{ color: "#077ACE" }}>{d.order_id}</span>
              )}
            </div>
            <div>
              <b>Order date : </b>
              {na2(d.created, moment(d.created).format("DD-MM-YYYY"))}
            </div>
            {!cancel && !is_return && (
              <div>
                <b>Tracking id : </b>
                <span style={{ color: "#077ACE" }}>{d?.order_id}</span>
              </div>
            )}
            {cancel && (
              <div>
                <b>Cancel id : </b>
                <span style={{ color: "#077ACE" }}>{na(d.cancel_id)}</span>
              </div>
            )}
            {cancel && (
              <div>
                <b>Cancel date : </b>
                {na2(
                  getDateOfStatus(d?.order_logs, "OS_CNCLDRQSTD_032"),
                  moment(
                    getDateOfStatus(d?.order_logs, "OS_CNCLDRQSTD_032")
                  ).format("DD-MM-YYYY")
                )}
              </div>
            )}
            {cancel && (
              <div>
                <b>Cancellation reason : </b>
                {na(d.reason_choices)}
              </div>
            )}
            {is_return && (
              <div>
                <b>Return id : </b>
                <span style={{ color: "#077ACE" }}>{na(d.return_id)}</span>
              </div>
            )}
            {is_return && (
              <div>
                <b>Return date : </b>
                {na2(
                  getDateOfStatus(d?.order_logs, "OS_RTRNRQSTD_015"),
                  moment(
                    getDateOfStatus(d?.order_logs, "OS_RTRNRQSTD_015")
                  ).format("DD-MM-YYYY")
                )}
              </div>
            )}
            {is_return && (
              <div>
                <b>Reason for returning : </b>
                {na(d.reason_choices)}
              </div>
            )}
            {!cancel && !is_return && (
              <div>
                <b>Delivered date :</b>{" "}
                {na2(
                  getDateOfStatus(d?.order_logs, "OS_DLVRD_003"),
                  moment(getDateOfStatus(d?.order_logs, "OS_DLVRD_003")).format(
                    "DD-MM-YYYY"
                  )
                )}
              </div>
            )}
            {!cancel && !is_return && (
              <div>
                <b>Expected delivery date :</b>{" "}
                {na2(
                  d.expected_delivery,
                  moment(d.expected_delivery).format("DD-MM-YYYY")
                )}
              </div>
            )}
            <TextChip text={d.action_name} color={"#" + d.icon_color_code} />
            {/* <div
              className="active-green no-wrap"
              style={{
                backgroundColor: "#" + d.icon_color_code,
                width: "min-content",
                padding: "5px 20px",
                letterSpacing: "0.5px",
              }}
            >
              {na(d.action_name)}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelBill;
