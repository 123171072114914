import { useLocation, Link } from "react-router-dom";

const BackButton = ({ href }) => {
  const location = useLocation();
  let path = location.pathname;
  while (path.length > 0 && path.charAt(path.length - 1) !== "/") {
    path = path.slice(0, -1);
  }
  if (path.length > 0) {
    path = path.slice(0, -1);
  }
  return (
    <div className="blue-link">
      <Link to={href ? href : path}>Back</Link>
    </div>
  );
};

export default BackButton;
