import { Header } from "semantic-ui-react";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import {
  InputDate,
  InputFile,
  InputText,
  InputToggle,
} from "../../../components/Inputs/Inputs";
import BackButton from "../../../components/BackButton/BackButton";
import { api } from "../../../api/api";
import { useToast } from "../../../components/Toast";
import { seller_annual_income } from "../../../api/urls";
import unknownError from "../../../utils/unknownError";
import Loading from "../../../components/Loading/Loading";
import { moveUpToError } from "../../../utils/moveUpToError";
import { modules } from "../../../api/codes";

const initialState = {
  aadhaar_number: "",
  aadhaar_file: "",
  pan_no: "",
  pan_file: "",
  noc_file: "",
  licence_no: "",
  driving_license_file: "",
  driving_license_expiry: "",
  driving_license_type: "",
  vehicle_insurance_number: "",
  vehicle_insurance_file: "",
  vehicle_insurance_expiry: "",
  vehicle_registration_cert_file: "",
  vehicle_registration_cert_expiry: "",
  puc_file: "",
  vehicle_type: "",
};

const module = modules.AnnualTurnover;

const title = "Update Documents";
const backUrl = "/logistics/pls";
const apiUrl = seller_annual_income;

export function PLSUpdateDocuments({ edit, view }) {
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const params = useParams();
  const toast = useToast();
  const [isGetting, setIsGetting] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (edit || view || params.id) {
      const getData = async () => {
        try {
          const res = await api.get(apiUrl + params.id + "/", {
            headers: {
              module: module,
            },
          });
          setData(res.data);
          setIsGetting(false);
        } catch (err) {
          unknownError(err);
          setIsGetting(false);
        }
      };

      getData();
    } else {
      setIsGetting(false);
    }
  }, [params.id, edit, view]);

  const set = (key, value) => {
    setData((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };

  if (isGetting) return <Loading />;

  return (
    <form
      id="form-wrapper"
      onSubmit={(e) => {
        e.preventDefault();
        setIsSaving(true);
        try {
          if (edit && params.id) {
            api
              .patch(apiUrl + data.id + "/", data, {
                headers: {
                  "Content-Type": "application/json",
                  module: module,
                },
              })
              .then((res) => {
                toast.open("Edited data with id: " + res.data.id, "success");
                history.push(backUrl);
                setIsSaving(false);
              })
              .catch((err) => {
                if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
                setIsSaving(false);
              });
          } else {
            api
              .post(apiUrl, data, {
                headers: {
                  "Content-Type": "application/json",
                  module: module,
                },
              })
              .then((res) => {
                toast.open("Added data with id: " + res.data.id, "success");
                history.push(backUrl);
                setIsSaving(false);
              })
              .catch((err) => {
                if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
                setIsSaving(false);
              });
          }
        } catch (err) {
          unknownError(err);
          setIsSaving(false);
        }
      }}
    >
      <div id="form">
        <BackButton href={backUrl} />
        <Header>{title}</Header>
        <InputText
          label="License number"
          placeholder="License number"
          disabled={view}
          value={data.licence_no}
          error={errors["licence_no"]}
          required
          onChange={(v) => set("licence_no", v)}
        />
        <InputFile
          label="Upload license"
          placeholder="Upload license"
          disabled={view}
          value={data.driving_license_file}
          error={errors["driving_license_file"]}
          required
          onChange={(v) => set("driving_license_file", v)}
        />
        <InputDate
          label="License expiry"
          placeholder="License expiry"
          disabled={view}
          value={data.driving_license_expiry}
          error={errors["driving_license_expiry"]}
          required
          onChange={(v) => set("driving_license_expiry", v)}
        />
        <InputText
          label="Vehicle insurrance number"
          placeholder="Vehicle insurrance number"
          disabled={view}
          value={data.vehicle_insurance_number}
          error={errors["vehicle_insurance_number"]}
          required
          onChange={(v) => set("vehicle_insurance_number", v)}
        />
        <InputFile
          label="Upload insurrance"
          placeholder="Upload insurrance"
          disabled={view}
          value={data.vehicle_insurance_file}
          error={errors["vehicle_insurance_file"]}
          required
          onChange={(v) => set("vehicle_insurance_file", v)}
        />
        <InputDate
          label="Vehicle insurrance expiry"
          placeholder="Vehicle insurrance expiry"
          disabled={view}
          value={data.vehicle_insurance_expiry}
          error={errors["vehicle_insurance_expiry"]}
          required
          onChange={(v) => set("vehicle_insurance_expiry", v)}
        />
        <InputFile
          label="Upload registration certificate"
          placeholder="Upload registration certificate"
          disabled={view}
          value={data.vehicle_registration_cert_file}
          error={errors["vehicle_registration_cert_file"]}
          required
          onChange={(v) => set("vehicle_registration_cert_file", v)}
        />
        <InputDate
          label="Vehicle registration certificate expiry"
          placeholder="Vehicle registration certificate expiry"
          disabled={view}
          value={data.vehicle_registration_cert_expiry}
          error={errors["vehicle_registration_cert_expiry"]}
          required
          onChange={(v) => set("vehicle_registration_cert_expiry", v)}
        />
        <InputFile
          label="Upload PUC"
          placeholder="Upload PUC"
          disabled={view}
          value={data.puc_file}
          error={errors["puc_file"]}
          required
          onChange={(v) => set("puc_file", v)}
        />
        <InputToggle
          label="Active"
          disabled={view}
          value={data.is_active}
          error={errors["is_active"]}
          onChange={(v) => set("is_active", v)}
        />
        {!view && (
          <>
            <div style={{ padding: "10px" }} />
            <div className="actions">
              <button
                className="btn-red"
                type="button"
                onClick={() => {
                  history.push(backUrl);
                }}
              >
                Cancel
              </button>
              <button
                className="btn"
                onClick={() => {
                  moveUpToError();
                }}
                disabled={isSaving}
              >
                Save
              </button>
            </div>
          </>
        )}
        <div style={{ padding: "10px" }} />
      </div>
    </form>
  );
}
