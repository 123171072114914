import { delivery_boy_temp_url } from "../../../api/urls";
import { Link } from "react-router-dom";
import { useFetchWithPagination } from "../../../utils/useFetchWithPagination";
import BackButton from "../../../components/BackButton/BackButton";
import { Header, Icon, Input } from "semantic-ui-react";
import { useState } from "react";
import { ProtectedElement } from "../../../components/ProtectedElement/ProtectedElement";
import { modules, perms } from "../../../api/codes";
import { TableComponent } from "../../../components/TableComponent/TableComponent";
import ActionDropdown from "../../../components/ActionDropdown/ActionDropdown";

export function B2BEligibleUsers() {
  const apiUrl = delivery_boy_temp_url;
  const title = "Eligible Users";
  const module = modules.B2BEligibleUsers;
  const currentRoute = "eligible_users";
  const columns = [
    {
      headerName: "Profile Image",
      render: () => "TODO",
    },
    {
      headerName: "Name",
      render: () => "TODO",
    },
    {
      headerName: "Mobile number",
      render: () => "TODO",
    },
    {
      headerName: "Credited amt.",
      render: () => "TODO",
    },
    {
      headerName: "Bill Due",
      render: () => "TODO",
    },
    {
      headerName: "Due Date",
      render: () => "TODO",
    },
    {
      headerName: "Status",
      render: () => "TODO",
    },
    {
      headerName: "Actions",
      field: "actions",
      flex: 1,
      renderCell: (params) => (
        <ActionDropdown
          options={(closer) => {
            return (
              <ProtectedElement module={module} perm={perms.add}>
                <Link to={currentRoute + `/add_credit/${params.row.id}`}>
                  <div
                    className="option"
                    onClick={() => {
                      closer();
                    }}
                  >
                    <div className="purple-title">Add Credit</div>
                  </div>
                </Link>
              </ProtectedElement>
            );
          }}
        />
      ),
    },
  ];

  const { data, pagination } = useFetchWithPagination(apiUrl);
  const { search, setSearch } = useState("");
  return (
    <>
      <BackButton />
      <div id="heading">
        <Header>{title}</Header>
        <div className="right">
          <Input
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            icon={<Icon name="search" link id="search-icon" />}
          />
        </div>
      </div>
      <TableComponent
        columns={columns}
        rows={data.data?.results || []}
        loading={data.loading}
      />
      {pagination}
    </>
  );
}
