import { Header } from "semantic-ui-react";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { InputText, InputToggle } from "../../../components/Inputs/Inputs";
import BackButton from "../../../components/BackButton/BackButton";
import { api } from "../../../api/api";
import { useToast } from "../../../components/Toast";
import {
  branch_url,
  department_url,
  designation_url,
  internal_users_url,
  roles_url,
} from "../../../api/urls";
import unknownError from "../../../utils/unknownError";
import Loading from "../../../components/Loading/Loading";
import { moveUpToError } from "../../../utils/moveUpToError";
import { APIDropdown } from "../../../components/APIDropdown/APIDropdown";
import { modules } from "../../../api/codes";

const initialState = {
  username: "",
  password: "",
  mobile_number: "",
  email: "",
  tmp_hex: "",
  pwd_temp: true,
  rst_pswd_email: true,
  is_active: false,
  erproles: "",
  branch: "",
  dept: "",
  desgntn: "",
};

const module = modules.InternalUser;

const title = "Internal User";
const backUrl = "/users/internal_users";
const apiUrl = internal_users_url;

const NewInternalUser = ({ edit, view }) => {
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const params = useParams();
  const toast = useToast();
  const [isGetting, setIsGetting] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (edit || view || params.id) {
      const getData = async () => {
        try {
          const res = await api.get(apiUrl + params.id + "/", {
            headers: {
              module: module,
            },
          });
          setData(res.data);
          setIsGetting(false);
        } catch (err) {
          unknownError(err);
          setIsGetting(false);
        }
      };

      getData();
    } else {
      setIsGetting(false);
    }
  }, [params.id, edit, view]);

  const set = (key, value) => {
    setData((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };

  if (isGetting) return <Loading />;

  return (
    <form
      id="form-wrapper"
      onSubmit={(e) => {
        e.preventDefault();
        setIsSaving(true);
        try {
          if (edit && params.id) {
            api
              .patch(apiUrl + data.id + "/", data, {
                headers: {
                  "Content-Type": "application/json",
                  module: module,
                },
              })
              .then((res) => {
                toast.open("Edited data with id: " + res.data.id, "success");
                history.push(backUrl);
                setIsSaving(false);
              })
              .catch((err) => {
                if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
                setIsSaving(false);
              });
          } else {
            api
              .post(apiUrl, data, {
                headers: {
                  "Content-Type": "application/json",
                  module: module,
                },
              })
              .then((res) => {
                toast.open("Added data with id: " + res.data.id, "success");
                history.push(backUrl);
                setIsSaving(false);
              })
              .catch((err) => {
                if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
                setIsSaving(false);
              });
          }
        } catch (err) {
          unknownError(err);
          setIsSaving(false);
        }
      }}
    >
      <div id="form">
        <BackButton href={backUrl} />
        <Header>{title}</Header>
        <InputText
          label="Username"
          placeholder="Username"
          disabled={view}
          value={data.username}
          error={errors["username"]}
          required
          onChange={(v) => set("username", v)}
        />
        <InputText
          label="Mobile number"
          placeholder="Mobile number"
          disabled={view}
          value={data.mobile_number}
          error={errors["mobile_number"]}
          required
          onChange={(v) => set("mobile_number", v)}
        />
        <InputText
          label="Email"
          placeholder="Email"
          disabled={view}
          value={data.email}
          error={errors["email"]}
          required
          onChange={(v) => set("email", v)}
        />
        <APIDropdown
          label="Branch"
          placeholder="Branch"
          required
          disabled={view}
          value={data.branch}
          url={branch_url}
          onChange={(v) => set("branch", v)}
          error={errors["branch"]}
          module={module}
        />
        <APIDropdown
          label="Department"
          placeholder="Department"
          required
          disabled={view}
          value={data.dept}
          url={department_url}
          onChange={(v) => set("dept", v)}
          error={errors["dept"]}
          module={module}
        />
        <APIDropdown
          label="Designation"
          placeholder="Designation"
          required
          disabled={view}
          value={data.desgntn}
          url={designation_url}
          onChange={(v) => set("desgntn", v)}
          error={errors["desgntn"]}
          module={module}
        />
        <APIDropdown
          label="Role"
          placeholder="Role"
          required
          disabled={view}
          value={data.erproles}
          url={roles_url}
          onChange={(v) => set("erproles", v)}
          error={errors["erproles"]}
          module={module}
          parameterForGet="user_role_name"
        />
        <InputToggle
          label="Active"
          disabled={view}
          value={data.is_active}
          error={errors["is_active"]}
          onChange={(v) => set("is_active", v)}
        />
        {!view && (
          <>
            <div style={{ padding: "10px" }} />
            <div className="actions">
              <button
                className="btn-red"
                type="button"
                onClick={() => {
                  history.push(backUrl);
                }}
              >
                Cancel
              </button>
              <button
                className="btn"
                onClick={() => {
                  moveUpToError();
                }}
                disabled={isSaving}
              >
                Save
              </button>
            </div>
          </>
        )}
        <div style={{ padding: "10px" }} />
      </div>
    </form>
  );
};

export default NewInternalUser;
