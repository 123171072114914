import { Header, Radio } from "semantic-ui-react";
import {
  city_url,
  country_url,
  delivery_boy_temp_url,
  license_type_url,
  state_url,
} from "../../../api/urls";
import BackButton from "../../../components/BackButton/BackButton";
import { useParams } from "react-router-dom";
import { useFetch } from "../../../utils/useFetch";
import { TitleLine } from "../../../components/lineTitle";
import { TableComponent } from "../../../components/TableComponent/TableComponent";
import { DashboardImage } from "../../../components/DashboardImage/DashboardImage";
import moment from "moment";
import Loading from "../../../components/Loading/Loading";
import { useModal } from "../../../components/Modaler";
import { useEffect, useState } from "react";
import { api } from "../../../api/api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useToast } from "../../../components/Toast";
import unknownError from "../../../utils/unknownError";
import { idToname } from "../../../utils/idToname";

export function VerifySellerProfile() {
  const title = "Verify Profile";
  const apiUrl = delivery_boy_temp_url;
  const backUrl = "/sellers/profile_kyc";
  const params = useParams();
  const id = params.id;
  const history = useHistory();
  const toast = useToast();

  const [varificationData, setVarificationdata] = useState({
    adhaar_verify: false,
    pan_verify: false,
    license_verify: false,
    insurance_verify: false,
    rc_verify: false,
    puc_verify: false,
    full_verify: false,
  });

  const data = useFetch(apiUrl + id + "/");

  useEffect(() => {
    if (data.data?.[0]?.form_data) {
      setVarificationdata((v) => ({
        ...v,
        ...data.data[0].form_data,
      }));
    }
  }, [data.data]);

  const set = (key, value) => {
    setVarificationdata((v) => ({ ...v, [key]: value }));
  };

  const [saving, setSaving] = useState(false);

  if (data.loading || !data.data) {
    return <Loading />;
  }

  const allTrue = () => {
    return (
      varificationData.adhaar_verify ||
      varificationData.insurance_verify ||
      varificationData.license_verify ||
      varificationData.pan_verify ||
      varificationData.puc_verify ||
      varificationData.rc_verify
    );
  };

  return (
    <div>
      <BackButton href={backUrl} />
      <Header>{title}</Header>
      {/* <pre className="code">{JSON.stringify(varificationData, null, 2)}</pre> */}
      <div className="p-10" />
      <PersonalInfo data={data} />
      <div className="p-10" />
      <BankInfo data={data} />
      <div className="p-10" />
      <AddressInfo data={data} />
      <div className="p-10" />
      <UploadedDocuments data={data} vdata={varificationData} vset={set} />
      <div className="p-10" />
      <div className="p-10" />
      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <Radio
          label="Verify"
          disabled={!allTrue()}
          checked={varificationData.full_verify}
          onChange={(v) => set("full_verify", true)}
        />
        <Radio
          label="Reject"
          disabled={!allTrue()}
          checked={!varificationData.full_verify}
          onChange={(v) => set("full_verify", false)}
        />
        <div style={{ flex: 1 }} />
        <button
          className="btn"
          disabled={saving}
          onClick={async () => {
            setSaving(true);
            const d = varificationData;
            try {
              await api.patch(delivery_boy_temp_url + id + "/", {
                form_data: {
                  bank: {
                    bank_name: d.bank_name,
                    account_holder_name: d.account_holder_name,
                    account_number: d.acccount_number,
                    ifsc_code: d.ifsc_code,
                    upi_id_number: d.upi_id,
                    delivery_boy: null,
                    verified: true,
                  },
                  address: {
                    contact_name: "",
                    contact_number: "",
                    contact_email_id: "",
                    building_premises_number: "",
                    building_premises_name: "",
                    address_line_1: d.address,
                    address_line_2: "",
                    landmark: d.landmark,
                    district: "",
                    country: d.country_address,
                    country_name: "",
                    city_village: d.city,
                    pincode: d.pincode,
                    state_link: d.state,
                    state: "",
                    state_code: "",
                    delivery_boy: null,
                    verified: true,
                  },
                  user: {
                    mobile_no: d.mobile_number,
                    first_name: d.first_name,
                    last_name: d.last_name,
                    birth_date: d.dob,
                    profile_img: d.photo,
                    country: d.country,
                  },
                  documents: {
                    aadhaar_number: d.aadhaar_number,
                    aadhaar_file: d.aadhaar_file,
                    pan_no: d.pan_no,
                    pan_file: d.pan_file,
                    noc_file: d.noc_file,
                    licence_no: d.licence_no,
                    driving_license_file: d.driving_license_file,
                    driving_license_expiry: d.driving_license_expiry,
                    driving_license_type: d.driving_license_type,
                    vehicle_insurance_number: d.vehicle_insurance_number,
                    vehicle_insurance_file: d.vehicle_insurance_file,
                    vehicle_insurance_expiry: d.vehicle_insurance_expiry,
                    vehicle_registration_cert_file:
                      d.vehicle_registration_cert_file,
                    vehicle_registration_cert_expiry:
                      d.vehicle_registration_cert_expiry,
                    puc_file: d.puc_file,
                    vehicle_type: d.vehicle_type,
                    adhaar_verify: d.adhaar_verify,
                    pan_verify: d.pan_verify,
                    license_verify: d.license_verify,
                    insurance_verify: d.insurance_verify,
                    rc_verify: d.rc_verify,
                    puc_verify: d.puc_verify,
                    verified: d.full_verify,
                  },
                },
                is_verified: d.full_verify,
              });
              history.push(backUrl);
              toast.open("Batch created sucessfully", "success");
            } catch (e) {
              if (e?.response?.status === 400 && e?.response?.data?.data) {
                toast.open(e?.response?.data.data, "success");
              } else {
                unknownError();
              }
            }
            setSaving(false);
          }}
        >
          Save
        </button>
      </div>
      <div className="p-10" />
    </div>
  );
}

function PersonalInfo({ data }) {
  const licenseTypeData = useFetch(license_type_url);
  return (
    <>
      <TitleLine right>Personal Info</TitleLine>
      <div className="p-10" />
      <TableComponent
        columns={[
          {
            headerName: "Image",
            renderCell: (params) => (
              <DashboardImage width="50px" src={params?.row?.photo} />
            ),
          },
          {
            headerName: "Name",
            renderCell: (params) =>
              `${params?.row?.first_name} ${params?.row?.last_name}`,
          },
          {
            headerName: "DOB",
            renderCell: (params) =>
              moment(params?.row?.dob).format("DD/MM/YYYY"),
          },
          {
            headerName: "Vehicle",
            field: "vehicle",
          },
          {
            headerName: "License type",
            field: "license_type",
            renderCell: (params) =>
              idToname(params.value, licenseTypeData.data) || "...",
          },
        ]}
        rows={data.data.map((d) => d.form_data)}
        loading={data.loading}
      />
    </>
  );
}

function BankInfo({ data }) {
  return (
    <>
      <TitleLine right>Bank Info</TitleLine>
      <div className="p-10" />
      <TableComponent
        columns={[
          {
            headerName: "Bank holder",
            field: "account_holder_name",
          },
          {
            headerName: "Bank name",
            field: "bank_name",
          },
          {
            headerName: "IFSC code",
            field: "ifsc_code",
          },
          {
            headerName: "Account no.",
            field: "acccount_number",
          },
          {
            headerName: "UPI ID",
            field: "upi_id",
          },
        ]}
        rows={data.data.map((d) => d.form_data)}
        loading={data.loading}
      />
    </>
  );
}

function AddressInfo({ data }) {
  const countryData = useFetch(country_url);
  const stateData = useFetch(state_url);
  const cityData = useFetch(city_url);
  return (
    <>
      <TitleLine right>Address Info</TitleLine>
      <div className="p-10" />
      <TableComponent
        columns={[
          {
            headerName: "Address",
            field: "address",
          },
          {
            headerName: "Landmark",
            field: "landmark",
          },
          {
            headerName: "Country",
            field: "country_address",
            renderCell: (params) =>
              idToname(params.value, countryData.data) || "...",
          },
          {
            headerName: "State",
            field: "state",
            renderCell: (params) =>
              idToname(params.value, stateData.data) || "...",
          },
          {
            headerName: "City",
            field: "city",
            renderCell: (params) =>
              idToname(params.value, cityData.data) || "...",
          },
          {
            headerName: "Pincode",
            field: "pincode",
          },
        ]}
        rows={data.data.map((d) => d.form_data)}
        loading={data.loading}
      />
    </>
  );
}

function UploadedDocuments({ data, vdata, vset }) {
  const moduler = useModal();
  const d = data?.data[0]?.form_data || {};
  return (
    <>
      <TitleLine right>Uploaded documents</TitleLine>
      <div className="p-10" />
      <TableComponent
        columns={[
          {
            headerName: "Document type",
            field: "doc_type",
          },
          {
            headerName: "Doc no.",
            field: "doc_no",
          },
          {
            headerName: "Verified ?",
            field: "doc_verified",
            renderCell: (params) => (params.value ? "Yes" : "No"),
          },
          {
            headerName: "File type",
            field: "doc_verified",
            renderCell: (params) => params.row.file?.split(".").pop(),
          },
          {
            headerName: "Action",
            renderCell: (params) => (
              <div
                className="blue-link"
                onClick={() => {
                  moduler.opener(
                    <DocumentVerifyModal
                      fileLink={params.row.file}
                      verified={vdata[params.row.set_key]}
                      onChange={(v) => {
                        vset(params.row.set_key, v);
                      }}
                      onSave={() => moduler.closer()}
                    />,
                    true
                  );
                }}
              >
                Verify
              </div>
            ),
          },
        ]}
        rows={[
          {
            doc_type: "Aadhaar card",
            doc_no: d.aadhaar_number,
            doc_verified: vdata["adhaar_verify"],
            set_key: "adhaar_verify",
            file: d.aadhaar_file,
          },
          {
            doc_type: "Pan Card",
            doc_no: d.pan_no,
            doc_verified: vdata["pan_verify"],
            set_key: "pan_verify",
            file: d.pan_file,
          },
          {
            doc_type: "Driving license",
            doc_no: d.licence_no,
            doc_verified: vdata["license_verify"],
            set_key: "license_verify",
            file: d.driving_license_file,
          },
          {
            doc_type: "Insurance",
            doc_no: d.insurance_number,
            doc_verified: vdata["insurance_verify"],
            set_key: "insurance_verify",
            file: d.insurance_file,
          },
          {
            doc_type: "R/C Book",
            doc_no: "N/A",
            doc_verified: vdata["rc_verify"],
            set_key: "rc_verify",
            file: d.vehicle_registration_cert_file,
          },
          {
            doc_type: "PUC",
            doc_no: "N/A",
            doc_verified: vdata["puc_verify"],
            set_key: "puc_verify",
            file: d.puc_file,
          },
        ]}
        loading={data.loading}
      />
    </>
  );
}

function DocumentVerifyModal({ fileLink, verified, onChange, onSave }) {
  const [verify, setVerify] = useState(verified);
  return (
    <div style={{ padding: "20px" }}>
      <div style={{ width: "500px", height: "300px" }}>
        <object
          data={fileLink}
          type="application/pdf"
          width="500px"
          height="300px"
        >
          <p>
            unable to display this file, here is the link :{" "}
            <a href={fileLink} target="_blank" rel="noreferrer">
              {fileLink}
            </a>
          </p>
        </object>
      </div>
      <div className="p-10" />
      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <Radio
          label="Verify"
          checked={verify}
          onChange={(v) => setVerify(true)}
        />
        <Radio
          label="Reject"
          checked={!verify}
          onChange={(v) => setVerify(false)}
        />
        <div style={{ flex: 1 }} />
        <button
          className="btn"
          onClick={() => {
            onChange(verify);
            onSave();
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
}
