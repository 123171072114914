import { useEffect, useState } from "react";
import Modal from "../../../components/Modal/Modal";
import "./generate_bill.scss";
import "./more_info_modal.scss";
import { na, na2, namt } from "../../../utils/na";
import moment from "moment";
import Loading from "../../../components/Loading/Loading";
import {
  InputSelect,
  InputText,
  InputTextBox,
} from "../../../components/Inputs/Inputs";
import { api } from "../../../api/api";
import { useToast } from "../../../components/Toast";
import unknownError from "../../../utils/unknownError";
import { moveUpToError } from "../../../utils/moveUpToError";
import { getDateOfStatus } from "./more_info_modal";
import { order_list_url } from "../../../api/urls";
import { DashboardImage } from "../../../components/DashboardImage/DashboardImage";

const CancelModal = ({ data, actionPath, actionName, refetch }) => {
  const [open, setOpen] = useState(false);
  const [orders, setOrders] = useState();

  useEffect(() => {
    setOrders([data]);
  }, [data]);

  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      trigger={
        <div className="blue-title" style={{ width: "100px" }}>
          {actionName}
        </div>
      }
      className="modal-window product-modal"
    >
      <div
        className="close-btn"
        onClick={() => {
          setOpen(false);
        }}
      >
        &#10005;
      </div>
      <div style={{ padding: "2px" }} />
      <div className="modal-content">
        <div className="modal-form">
          <Display
            data={orders ? orders : []}
            modalCloser={() => setOpen(false)}
            actionPath={actionPath}
            closer={() => setOpen(false)}
            refetch={refetch}
          />
        </div>
      </div>
    </Modal>
  );
};

const Display = ({ data, modalCloser, actionPath, closer, refetch }) => {
  if (data.length === 0) {
    return (
      <div
        style={{
          flex: 1,
          height: "300px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loading />
      </div>
    );
  }
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      {data.map((d, key) => {
        return (
          <ProductDetailsSection
            key={key}
            data={d}
            modalCloser={modalCloser}
            actionPath={actionPath}
            closer={closer}
            refetch={refetch}
            statusCode={d.status_code}
          />
        );
      })}
    </div>
  );
};

const viewDetails = false;

const initialState = {
  reason_choices: "",
  reason_comment: "",
  user_check: false,
  quantity: "",
};

const ProductDetailsSection = ({
  data: d,
  cancel,
  is_return,
  modalCloser,
  actionPath,
  refetch,
  closer,
  statusCode,
}) => {
  const [open, setOpen] = useState(!viewDetails);
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const toast = useToast();
  const [questions, setQuestions] = useState();
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const get = async () => {
      try {
        const res = await api.get(`${order_list_url}${d.id}/${actionPath}/`);
        if (res.data.questions) {
          setQuestions(res.data.questions.filter((x) => x.accept));
        }
      } catch (err) {
        unknownError(err);
      }
    };
    get();
  }, [actionPath, d.id, statusCode]);

  const set = (key, value) => {
    setData((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };

  const refundOptions = [
    d?.bank_structure?.find((x) => x === "TagOn Wallet") || null,
    d?.bank_structure?.find((x) => x === "Original Payment Method") || null,
  ].filter((x) => x !== null);

  return (
    <>
      <div className="product-info">
        <div className="image-section">
          <DashboardImage
            src={d.tagon_product_image}
            height="200px"
            width="200px"
          />
        </div>
        <div className="details-section">
          {d?.prod_json?.title && (
            <div style={{ fontSize: "15px", fontWeight: "bold" }}>
              {d.prod_json.title}
            </div>
          )}
          {d?.prod_json?.description && <div>{d.prod_json.description}</div>}
          <div className="twoside-details">
            <div
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              <div>
                <b>Size : </b>
                {na(d.size)}
              </div>
              <div>
                <b>Color : </b>
                {na(d.color)}
              </div>
              <div>
                <b>Quantity : </b>
                {na(d.quantity)}
              </div>
              <div>
                <b>Selling price : </b>
                {namt(d.subtotal_after_tax_amt)}
              </div>
              <div>
                <b>MRP : </b>
                {namt(d.mrp_qty)}
              </div>
              <div>
                <b>Brand : </b>
                {na(d?.brand_name)}
              </div>
              <div>
                <b>Sold by : </b>
                {na(d?.prod_json?.seller_name)}
              </div>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              <div>
                <b>Order id : </b>
                {na2(
                  d.order_id,
                  <span style={{ color: "#077ACE" }}>{d.order_id}</span>
                )}
              </div>
              <div>
                <b>Order date : </b>
                {na2(d.created, moment(d.created).format("DD-MM-YYYY"))}
              </div>
              {!cancel && !is_return && (
                <div>
                  <b>Tracking id : </b>
                  <span style={{ color: "#077ACE" }}>{d?.order_id}</span>
                </div>
              )}
              {cancel && (
                <div>
                  <b>Cancel id : </b>
                  <span style={{ color: "#077ACE" }}>{na(d.cancel_id)}</span>
                </div>
              )}
              {cancel && (
                <div>
                  <b>Cancel date : </b>
                  {na2(
                    getDateOfStatus(d?.order_logs, "OS_CNCLDRQSTD_032"),
                    moment(
                      getDateOfStatus(d?.order_logs, "OS_CNCLDRQSTD_032")
                    ).format("DD-MM-YYYY")
                  )}
                </div>
              )}
              {cancel && (
                <div>
                  <b>Cancellation reason : </b>
                  {na(d.reason_choices)}
                </div>
              )}
              {is_return && (
                <div>
                  <b>Return id : </b>
                  <span style={{ color: "#077ACE" }}>{na(d.return_id)}</span>
                </div>
              )}
              {is_return && (
                <div>
                  <b>Return date : </b>
                  {na2(
                    getDateOfStatus(d?.order_logs, "OS_RTRNRQSTD_015"),
                    moment(
                      getDateOfStatus(d?.order_logs, "OS_RTRNRQSTD_015")
                    ).format("DD-MM-YYYY")
                  )}
                </div>
              )}
              {is_return && (
                <div>
                  <b>Reason for returning : </b>
                  {na(d.reason_choices)}
                </div>
              )}
              <div>
                <b>Order status :</b>
                {na2(
                  d.action_name,
                  <span style={{ color: "#" + d.action_color_code }}>
                    {" "}
                    {d.action_name.toUpperCase()}
                  </span>
                )}
              </div>
              {!cancel && !is_return && (
                <div>
                  <b>Delivered date :</b>{" "}
                  {na2(
                    getDateOfStatus(d?.order_logs, "OS_DLVRD_003"),
                    moment(
                      getDateOfStatus(d?.order_logs, "OS_DLVRD_003")
                    ).format("DD-MM-YYYY")
                  )}
                </div>
              )}
              {!cancel && !is_return && (
                <div>
                  <b>Expected delivery date :</b>{" "}
                  {na2(
                    d.expected_delivery,
                    moment(d.expected_delivery).format("DD-MM-YYYY")
                  )}
                </div>
              )}
            </div>
          </div>
          {viewDetails && (
            <div
              className="blue-link"
              style={{ marginLeft: "auto" }}
              onClick={() => setOpen((v) => !v)}
            >
              View Details
            </div>
          )}
        </div>
      </div>
      <form
        style={{
          overflow: "hidden",
          padding: "0px 10px",
          maxHeight: open ? "10000px" : "0px",
          transition: open ? "max-height 2s ease" : null,
        }}
        onSubmit={(e) => {
          setSaving(true);
          e.preventDefault();
          const newData = { ...data };
          if (data.refund_method === "TagOn Wallet") {
            newData.refund_to_source = false;
            newData.refund_to_wallet = true;
            newData.refund_to_bank = false;
          } else if (data.refund_method === "Original Payment Method") {
            newData.refund_to_source = true;
            newData.refund_to_wallet = false;
            newData.refund_to_bank = false;
          }
          try {
            api
              .patch(`${order_list_url}${d.id}/${actionPath}/`, newData)
              .then((res) => {
                toast.open("Action performed successfully.", "success");
                setSaving(false);
                refetch();
                closer();
              })
              .catch((err) => {
                if (err?.response?.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                  setSaving(false);
                } else {
                  unknownError(err);
                  setSaving(false);
                  closer();
                }
              });
          } catch (e) {
            unknownError(e);
            setSaving(false);
          }
        }}
      >
        <div id="form" style={{}}>
          {actionPath !== "cancelproduct" && actionPath !== "order_deny" && (
            <InputSelect
              label="Quantity"
              placeholder="Quantity"
              options={[...Array(d.quantity).keys()].map((i) => `${i + 1}`)}
              value={data.quantity}
              required
              onChange={(v) => set("quantity", v)}
              error={errors["quantity"]}
              noSearch
            />
          )}
          {actionPath === "order_deny" ? (
            <InputText
              label="Reason"
              placeholder="Reason"
              value={data.reason_choices}
              required
              onChange={(v) => set("reason_choices", v)}
              error={errors["reason_choices"]}
            />
          ) : (
            <InputSelect
              label="Select the reason"
              placeholder="Select the reason"
              options={questions ? questions.map((q) => q.question) : []}
              value={data.reason_choices}
              required
              onChange={(v) => set("reason_choices", v)}
              error={errors["reason_choices"]}
            />
          )}
          {actionPath !== "replaceproduct" && refundOptions.length > 0 && (
            <>
              <InputSelect
                label="Select refund mode"
                placeholder="Select refund mode"
                options={refundOptions}
                required
                value={data.refund_method}
                error={errors["refund_to_source"]}
                onChange={(v) => {
                  set("refund_method", v);
                }}
              />
            </>
          )}
          <InputTextBox
            label="Remark"
            placeholder="Remark"
            value={data.reason_comment}
            error={errors["reason_comment"]}
            onChange={(v) => set("reason_comment", v)}
          />
          <div className="actions">
            <button
              className="btn-red"
              type="button"
              onClick={() => modalCloser()}
            >
              Cancel
            </button>
            <button className="btn" disabled={saving}>
              Submit
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default CancelModal;
