import { Image } from "lucide-react";

export function DashboardImage({
  src,
  height,
  width,
  circle,
  noOpen,
  className,
  onClick,
}) {
  const source = src ? src : Image;
  return src && !noOpen ? (
    <a
      href={source}
      rel="noreferrer"
      target="_blank"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        className={
          `dashboard-image ${circle ? "image-circle" : ""} ` + className
        }
        src={source}
        height={height}
        width={width}
        alt="img"
      />
    </a>
  ) : src ? (
    <img
      className={`dashboard-image ${circle ? "image-circle" : ""} ` + className}
      src={source}
      height={height}
      width={width}
      alt="img"
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    />
  ) : (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        className={`dashboard-image ${circle ? "image-circle" : ""}`}
        style={{
          color: "#dedede",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: width,
          height: height,
        }}
      >
        <Image style={{ height: "35px", width: "35px" }} />
      </div>
    </div>
  );
}
