import { useLocation } from "react-router-dom";
import { InputText } from "../../components/Inputs/Inputs";
import { Button, Header, Icon } from "semantic-ui-react";
import { useState, useEffect } from "react";
import { api, apiPublic } from "../../api/api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./upd_tmp_pswd.scss";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/userStore";
import { useToast } from "../../components/Toast";
import { update_password_url, user_logout_url } from "../../api/urls";
import unknownError from "../../utils/unknownError";

const UpdTmpPwsd = () => {
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);
  const token = decodeURIComponent(searchParams.get("token"));
  const pwd_temp = useSelector((state) => state.user.pwd_temp);
  const dispatch = useDispatch();
  const toast = useToast();

  const [retryPass, setRetryPass] = useState("");
  const [retryError, setRetryError] = useState("");
  const [password, setpassword] = useState("");
  const [passError, setPassError] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [retryType, setRetryType] = useState("password");

  useEffect(() => {
    setRetryError("");
  }, [retryPass]);

  useEffect(() => {
    setPassError("");
  }, [password]);

  function handleSubmit(event) {
    event.preventDefault();
    if (password !== retryPass) {
      setRetryError("Password and confirm password should be same");
      return;
    }
    try {
      if (token === "null") {
        api
          .post(update_password_url, {
            password,
            confirm_password: retryPass,
          })
          .then(async (res) => {
            try {
              await api.get(user_logout_url);
            } catch (e) {
              console.log(e);
            }
            toast.open(
              "Password has been resetted successfully. Please login using new password.",
              "success"
            );
            await dispatch(logout());
            history.push("/");
          })
          .catch((err) => {
            unknownError(err);
          });
      } else {
        apiPublic
          .post(update_password_url, {
            token,
            password,
            confirm_password: retryPass,
          })
          .then(async (res) => {
            try {
              await api.get(user_logout_url);
            } catch (e) {
              console.log(e);
            }
            toast.open(
              "Password has been resetted successfully. Please login using new password.",
              "success"
            );
            await dispatch(logout());
            history.push("/");
          })
          .catch((err) => {
            unknownError(err);
          });
      }
    } catch (err) {
      unknownError(err);
    }
  }

  return (
    <div className="upd-page">
      <div className="layout">
        <div id="segment-upd">
          <form id="form-upd" onSubmit={handleSubmit}>
            <Header textAlign="center">Password Reset</Header>
            {pwd_temp === true ? (
              <>
                <div className="error-message">
                  You are using a temporarily assigned password. Please reset
                  your password.
                </div>
              </>
            ) : null}
            <div style={{ padding: "10px" }} />
            <InputText
              label="Password"
              placeholder="Password"
              onChange={(v) => setpassword(v)}
              error={passError}
              required
              type={passwordType}
              icon={
                <Icon
                  name={passwordType === "password" ? "eye slash" : "eye"}
                  link
                  id="eye-icon"
                  onClick={() => {
                    if (passwordType === "password") {
                      setPasswordType("text");
                    } else {
                      setPasswordType("password");
                    }
                  }}
                />
              }
            />
            <div style={{ padding: "10px" }} />
            <InputText
              label="Confirm password"
              placeholder="Confirm password"
              onChange={(v) => setRetryPass(v)}
              error={retryError}
              type={retryType}
              required
              icon={
                <Icon
                  name={retryType === "password" ? "eye slash" : "eye"}
                  link
                  id="eye-icon"
                  onClick={() => {
                    if (retryType === "password") {
                      setRetryType("text");
                    } else {
                      setRetryType("password");
                    }
                  }}
                />
              }
            />
            <div style={{ padding: "5px" }} />
            <Button secondary type="submit" id="button-upd" fluid>
              Reset password
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};
export default UpdTmpPwsd;
