import { Table } from "semantic-ui-react";
import { ReactComponent as LoadingIcon } from "../../images/loading.svg";

export function TableComponent({ columns, rows, loading, noWrapper }) {
  if (loading) {
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LoadingIcon height="70px" width="70px" />
      </div>
    );
  }

  if (!loading && Array.isArray(rows) && rows.length === 0) {
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        NO DATA
      </div>
    );
  }
  return (
    <div
      className={
        (noWrapper ? "" : "table-wrapper ") + "table-wrapper-styles no-wrap"
      }
    >
      <Table className="table-component" size="small" unstackable>
        <Table.Header>
          <Table.Row>
            {columns.map((c, index) => (
              <Table.HeaderCell
                className="Table-Header-cell"
                key={index}
                style={{
                  whiteSpace: c.headerWrap ? "normal" : "nowrap",
                }}
              >
                <div
                  style={{
                    textTransform: "capitalize",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {c.headerName}
                </div>
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body className="table-body">
          {rows?.map((row, index) => (
            <Table.Row key={index} verticalAlign="middle">
              {columns.map((c, index) => (
                <Table.Cell key={index} className="middle aligned">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className={c.wrap ? "wrap" : null}
                      style={{ width: "fit-content", textAlign: "center" }}
                    >
                      {c.renderCell
                        ? c.renderCell({ row: row, value: row?.[c?.field] })
                        : row?.[c?.field] || "-"}
                    </div>
                  </div>
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}
