import React from "react";

import { Table, Popup } from "semantic-ui-react";

export const TableComponent = ({
  rowTop,
  headers,
  body,
  footer,
  showbottom,
  celled,
  disableHeaderPopup,
  noWrapper,
}) => {
  const headerMap = headers
    ? headers.map((d, index) => {
        return (
          <Table.HeaderCell key={index} className="Table-Header-cell">
            {disableHeaderPopup ? (
              <div
                style={{
                  textTransform: "capitalize",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {d}
              </div>
            ) : (
              <Popup content={d} trigger={<div> {d}</div>}></Popup>
            )}
          </Table.HeaderCell>
        );
      })
    : null;

  const bodyMap = body.map((d, i) => (
    <Table.Row key={i} verticalAlign={rowTop === false ? "middle" : "top"}>
      {d.map((celldata, j) => {
        return (
          <Table.Cell key={j} className="middle aligned">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "2px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {celldata ? celldata : "-"}{" "}
            </div>
          </Table.Cell>
        );
      })}
    </Table.Row>
  ));
  return (
    <div
      className={
        (noWrapper ? "" : "table-wrapper ") + "table-wrapper-styles no-wrap"
      }
    >
      <Table
        className="table-component"
        size="small"
        celled={celled}
        unstackable
      >
        {headers ? (
          <Table.Header
            style={{
              position: "sticky !important",
              top: "0",
              zIndex: 2,
            }}
          >
            <Table.Row>{headerMap}</Table.Row>
          </Table.Header>
        ) : null}
        <Table.Body className="table-body">{bodyMap}</Table.Body>
        {bodyMap.length === 0 && (
          <Table.Body>
            <Table.Row>
              <Table.Cell colSpan={headers.length} textAlign="center">
                No data entered yet
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        )}
        {showbottom === false ? null : (
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan={headers.length}>
                {footer}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        )}
      </Table>
    </div>
  );
};
