import { Header, Input, Icon } from "semantic-ui-react";
import { useEffect, useState } from "react";

import Pagination from "../../../components/Pagination/Pagination.js";
import { TableComponent } from "../../../components/Table/table";
import BackButton from "../../../components/BackButton/BackButton";
import Loading from "../../../components/Loading/Loading";
import { api } from "../../../api/api.js";
import { tagon_customer_url } from "../../../api/urls.js";
import axios from "axios";
import MoreInfoModal from "./more_info_modal.js";
import unknownError from "../../../utils/unknownError.js";
import { DashboardImage } from "../../../components/DashboardImage/DashboardImage.js";
// import ActionDropdown from "../../../components/ActionDropdown/ActionDropdown.js";
// import { ProtectedElement } from "../../../components/ProtectedElement/ProtectedElement.js";
// import { modules, perms } from "../../../api/codes.js";
// import { Link } from "react-router-dom/cjs/react-router-dom.min.js";
// import { ReactComponent as UserIcon } from "../../../images/user.svg";

const title = "TagOn Customers";
const apiUrl = tagon_customer_url;
// const module = modules.TagonCustomer;
const searchFeild = "full_name";
// const currentRoute = "tagon_customers";
const headers = [
  "Profile",
  "Full name",
  "User Id",
  "Email",
  "Primary number",
  "Country",
  // "Action",
];
const bodyMap = (d, refetch) => {
  return [
    <div>
      <DashboardImage
        src={d?.user?.profile_image}
        height="70px"
        width="70px"
        circle
      />
      <MoreInfoModal data={d} />
    </div>,
    d.user.full_name,
    d.user.id,
    d.user.email,
    d.user.mobile_number,
    d.user.country,
    // <ActionDropdown
    //   options={(closer) => {
    //     return (
    //       <ProtectedElement module={module} perm={perms.viewList}>
    //         <Link to={currentRoute + `/make_b2b/${d.user.id}`}>
    //           <div
    //             className="option"
    //             onClick={() => {
    //               closer();
    //             }}
    //           >
    //             <div className="icon-btn purple-icon">
    //               <UserIcon />
    //             </div>
    //             <div className="purple-title">Make B2B user</div>
    //           </div>
    //         </Link>
    //       </ProtectedElement>
    //     );
    //   }}
    // />,
  ];
};

const TagonCustomers = () => {
  const [data, setData] = useState();
  const [search, setSearch] = useState("");
  const [refetcher, setRefetcher] = useState(false);

  const [limit, setLimit] = useState(25);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState();

  useEffect(() => {
    const source = axios.CancelToken.source();
    const getData = async () => {
      setData();
      try {
        const res = await api.get(
          apiUrl + "?limit=" + limit + "&offset=" + offset,
          { cancelToken: source.token }
        );
        setData(res.data);
        setCount(res.data.count);
      } catch (err) {
        unknownError(err);
      }
    };
    getData();
    return () => {
      source.cancel();
    };
  }, [refetcher, limit, offset]);

  const refetch = () => {
    setRefetcher((v) => !v);
  };

  return (
    <>
      <BackButton />
      <div id="heading">
        <Header>{title}</Header>
        <div className="right">
          <Input
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            icon={<Icon name="search" link id="search-icon" />}
          />
        </div>
      </div>
      {data ? (
        <>
          <TableComponent
            headers={headers}
            body={
              data.results
                ? data.results
                    .filter((d) =>
                      d.user[searchFeild]
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    )
                    .map((d) => {
                      return bodyMap(d, refetch);
                    })
                : []
            }
            showbottom={false}
            disableHeaderPopup
          />
        </>
      ) : (
        <Loading />
      )}
      {count ? (
        <Pagination count={count} setLimit={setLimit} setOffset={setOffset} />
      ) : null}
    </>
  );
};

export default TagonCustomers;
