import { Header, Input, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

import Pagination from "../../components/Pagination/Pagination.js";
import { TableComponent } from "../../components/Table/table";
import ActionDropdown from "../../components/ActionDropdown/ActionDropdown";
import Loading from "../../components/Loading/Loading";
import { api } from "../../api/api.js";
import moment from "moment";
import { ReactComponent as EditIcon } from "../../images/edit.svg";
import { ReactComponent as EyeIcon } from "../../images/eye.svg";
import { ReactComponent as CopyIcon } from "../../images/copy.svg";
import DeleteConfirmModal from "../../components/ActionDropdown/DeleteConfirmModal.js";
import axios from "axios";
import { coupons_url } from "../../api/urls.js";
import unknownError from "../../utils/unknownError.js";
import { modules, perms } from "../../api/codes.js";
import { ProtectedElement } from "../../components/ProtectedElement/ProtectedElement.js";

const title = "Coupons";
const apiUrl = coupons_url;
const currentRoute = "coupons";
const module = modules.Coupon;
const searchFeild = "printable_name";
const headers = [
  "Promo code",
  "Discount",
  // "Available on",
  "Coupon expiry",
  "Update date",
  "Update by",
  "Status",
  "Actions",
];
const bodyMap = (d, refetch) => {
  return [
    d.promocode,
    `${d.discount_type === "value" ? `${d.currency_symbol}` : ""}${
      d.discount_upto
    }${d.discount_type === "percent" ? `%` : ""}`,
    // "Todo",
    moment(d.expiry).format("llll"),
    moment(d.updated_at).format("DD-MM-YYYY"),
    "Admin",
    <div className={d.is_active ? "active-green" : "active-red"}>
      {d.is_active ? "Active" : "Inactive"}
    </div>,
    <ActionDropdown
      DeleteButton={({ setOpen }) => (
        <DeleteConfirmModal
          data={d}
          dropdownCloser={() => setOpen(false)}
          route={apiUrl}
          setTableData={() => refetch()}
          module={module}
        />
      )}
      EditButton={({ setOpen }) => (
        <ProtectedElement module={module} perm={perms.edit}>
          <Link to={currentRoute + "/edit/" + d.id}>
            <div
              className="option"
              onClick={() => {
                setOpen(false);
              }}
            >
              <div className="icon-btn green-icon">
                <EditIcon />
              </div>
              <div className="green-title">Edit</div>
            </div>
          </Link>
        </ProtectedElement>
      )}
      ViewButton={({ setOpen }) => (
        <ProtectedElement module={module} perm={perms.view}>
          <Link to={currentRoute + "/view/" + d.id}>
            <div
              className="option"
              onClick={() => {
                setOpen(false);
              }}
            >
              <div className="icon-btn yellow-icon">
                <EyeIcon />
              </div>
              <div className="yellow-title">View</div>
            </div>
          </Link>
        </ProtectedElement>
      )}
      CopyButton={({ setOpen }) => (
        <ProtectedElement module={module} perm={perms.add}>
          <Link to={currentRoute + "/copy/" + d.id}>
            <div
              className="option"
              onClick={() => {
                setOpen(false);
              }}
            >
              <div className="icon-btn purple-icon">
                <CopyIcon />
              </div>
              <div className="purple-title">Copy</div>
            </div>
          </Link>
        </ProtectedElement>
      )}
    />,
  ];
};

const Coupons = () => {
  const [data, setData] = useState();
  const [search, setSearch] = useState("");
  const [refetcher, setRefetcher] = useState(false);

  const [limit, setLimit] = useState(25);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState();

  useEffect(() => {
    const source = axios.CancelToken.source();
    const getData = async () => {
      setData();
      try {
        const res = await api.get(
          apiUrl + "?limit=" + limit + "&offset=" + offset,
          { cancelToken: source.token }
        );
        setData(res.data);
        setCount(res.data.count);
      } catch (err) {
        unknownError(err);
      }
    };
    getData();
    return () => {
      source.cancel();
    };
  }, [refetcher, limit, offset]);

  const refetch = () => {
    setRefetcher((v) => !v);
  };

  return (
    <>
      <div id="heading">
        <Header>{title}</Header>
        <div className="right">
          <Input
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            icon={<Icon name="search" link id="search-icon" />}
          />
          <ProtectedElement module={module} perm={perms.add}>
            <Link to={currentRoute + "/new"}>
              <button className="btn">Add New</button>
            </Link>
          </ProtectedElement>
        </div>
      </div>
      {data ? (
        <>
          <TableComponent
            headers={headers}
            body={
              data.results
                ? data.results
                    .filter((d) =>
                      d[searchFeild]
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    )
                    .map((d) => {
                      return bodyMap(d, refetch);
                    })
                : []
            }
            showbottom={false}
            disableHeaderPopup
          />
        </>
      ) : (
        <Loading />
      )}
      {count ? (
        <Pagination count={count} setLimit={setLimit} setOffset={setOffset} />
      ) : null}
    </>
  );
};

export default Coupons;
