import { useEffect, useState } from "react";
import { api } from "../../../api/api";
import {
  InputNumber,
  InputText,
  InputTextBox,
} from "../../../components/Inputs/Inputs";
import Modal from "../../../components/Modal/Modal";
import { useToast } from "../../../components/Toast";
import {
  country_url,
  seller_address,
  seller_facility,
  state_url,
} from "../../../api/urls";
import unknownError from "../../../utils/unknownError";
import { APIDropdown } from "../../../components/APIDropdown/APIDropdown";
import { moveUpToError } from "../../../utils/moveUpToError";

const initialState = {
  facility_name: "",
  contact_name: "",
  contact_number: "",
  contact_email_id: "",
  building_premises_number: "",
  building_premises_name: "",
  address_line_1: "",
  address_line_2: "",
  landmark: "",
  area: "",
  district: "",
  country_name: "",
  city_village: "",
  pincode: "",
  state: "",
  state_code: "",
  facility_gstin_number: "",
  facility_type: "",
  country: "",
  state_link: "",
};

const NewSellerAddressModal = ({ trigger, refetch, editData, seller_id }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const toast = useToast();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (editData) {
      setData(editData);
    }
  }, [editData]);

  useEffect(() => {
    if (open === false && !editData) {
      setData({ ...initialState });
      setErrors({});
    }
  }, [open, setData, setErrors, editData]);

  const set = (key, value) => {
    setData((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };

  const view = false;

  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      trigger={trigger}
      className="modal-window"
      overlayClassName="no-overlay"
    >
      <div className="modal-content">
        <div className="modal-heading">Seller Address</div>
        <div className="modal-form">
          <form
            id="form-wrapper"
            onSubmit={(e) => {
              e.preventDefault();
              setIsSaving(true);
              const newData = { ...data };
              newData["seller"] = seller_id;
              try {
                if (editData) {
                  api
                    .patch(seller_address + data.id + "/", newData)
                    .then((res) => {
                      refetch();
                      setOpen(false);
                      toast.open(
                        "Edited data with id: " + res.data.id,
                        "success"
                      );
                      setIsSaving(false);
                    })
                    .catch((err) => {
                      if (err?.response?.data) {
                        setErrors(err.response.data);
                        moveUpToError();
                      } else {
                        unknownError(err);
                      }
                      setIsSaving(false);
                    });
                } else {
                  api
                    .post(seller_address, newData)
                    .then((res) => {
                      refetch();
                      setOpen(false);
                      toast.open(
                        "Added data with id: " + res.data.id,
                        "success"
                      );
                      setIsSaving(false);
                    })
                    .catch((err) => {
                      if (err?.response?.data) {
                        setErrors(err.response.data);
                        moveUpToError();
                      } else {
                        unknownError(err);
                      }
                      setIsSaving(false);
                    });
                }
              } catch (err) {
                unknownError(err);
                setIsSaving(false);
              }
            }}
          >
            <div className="twoside-form">
              <div className="formside">
                <InputText
                  label="Code"
                  placeholder="Code"
                  disabled={view}
                  required
                  value={data.code}
                  onChange={(v) => set("code", v)}
                  error={errors["code"]}
                />
                <InputText
                  label="Printable name"
                  placeholder="Printable name"
                  disabled={view}
                  required
                  value={data.printable_name}
                  onChange={(v) => set("printable_name", v)}
                  error={errors["printable_name"]}
                />
                <InputTextBox
                  label="Description"
                  placeholder="Description"
                  disabled={view}
                  required
                  value={data.description}
                  onChange={(v) => set("description", v)}
                  error={errors["description"]}
                />
                <InputText
                  label="Contact name"
                  placeholder="Contact name"
                  disabled={view}
                  required
                  value={data.contact_name}
                  onChange={(v) => set("contact_name", v)}
                  error={errors["contact_name"]}
                />
                <InputNumber
                  label="Mobile number"
                  placeholder="Mobile number"
                  disabled={view}
                  required
                  value={data.contact_number}
                  onChange={(v) => set("contact_number", v)}
                  error={errors["contact_number"]}
                />
                <InputText
                  label="Email id"
                  placeholder="Email id"
                  type="email"
                  disabled={view}
                  required
                  value={data.contact_email_id}
                  onChange={(v) => set("contact_email_id", v)}
                  error={errors["contact_email_id"]}
                />
                <InputText
                  label="Address 1"
                  placeholder="Room no, floor, building name, etc."
                  disabled={view}
                  required
                  value={data.address_line_1}
                  onChange={(v) => set("address_line_1", v)}
                  error={errors["address_line_1"]}
                />
                <InputText
                  label="Address 2"
                  placeholder="Street name, area, district, etc."
                  disabled={view}
                  required
                  value={data.address_line_2}
                  onChange={(v) => set("address_line_2", v)}
                  error={errors["address_line_2"]}
                />
                <InputText
                  label="Landmark"
                  placeholder="Landmark"
                  disabled={view}
                  required
                  value={data.landmark}
                  onChange={(v) => set("landmark", v)}
                  error={errors["landmark"]}
                />
              </div>
              <div className="formside">
                <InputText
                  label="City"
                  placeholder="City"
                  disabled={view}
                  required
                  value={data.city_village}
                  onChange={(v) => set("city_village", v)}
                  error={errors["city_village"]}
                />
                <InputNumber
                  label="Pincode"
                  placeholder="Pincode"
                  required
                  disabled={view}
                  value={data.pincode}
                  onChange={(v) => set("pincode", v)}
                  error={errors["pincode"]}
                />
                <APIDropdown
                  label="State"
                  placeholder="State"
                  required
                  disabled={view}
                  value={data.state_link}
                  url={state_url}
                  onChange={(v) => set("state_link", v)}
                  error={errors["state_link"]}
                />
                <APIDropdown
                  label="Country"
                  placeholder="Country"
                  required
                  disabled={view}
                  value={data.country}
                  url={country_url}
                  onChange={(v) => set("country", v)}
                  error={errors["country"]}
                />
                <InputText
                  label="Facility GSTIN number"
                  placeholder="Facility GSTIN number"
                  required
                  disabled={view}
                  value={data.facility_gstin_number}
                  onChange={(v) => set("facility_gstin_number", v)}
                  error={errors["facility_gstin_number"]}
                />
                <InputText
                  label="Facility name"
                  placeholder="Facility name"
                  required
                  disabled={view}
                  value={data.facility_name}
                  onChange={(v) => set("facility_name", v)}
                  error={errors["facility_name"]}
                />
                <APIDropdown
                  label="Facility type"
                  placeholder="Facility type"
                  disabled={view}
                  value={data.facility_type}
                  url={seller_facility}
                  onChange={(v) => set("facility_type", v)}
                  error={errors["facility_type"]}
                />
                <InputText
                  label="Building premises number"
                  placeholder="Building premises number"
                  disabled={view}
                  value={data.building_premises_number}
                  onChange={(v) => set("building_premises_number", v)}
                  error={errors["building_premises_number"]}
                />
                <InputText
                  label="Building premises name"
                  placeholder="Building premises name"
                  disabled={view}
                  value={data.building_premises_name}
                  onChange={(v) => set("building_premises_name", v)}
                  error={errors["building_premises_name"]}
                />
              </div>
            </div>
            <div style={{ padding: "10px" }} />
          </form>
        </div>
        <div className="modal-actions">
          <div className="actions">
            <button
              className="btn-red"
              type="button"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              form="form-wrapper"
              className="btn"
              onClick={() => {
                moveUpToError();
              }}
              disabled={isSaving}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NewSellerAddressModal;
