import { Header } from "semantic-ui-react";
import { useHistory, useParams } from "react-router-dom";
import { useState } from "react";

import { InputText } from "../../../components/Inputs/Inputs";
import BackButton from "../../../components/BackButton/BackButton";
import { api } from "../../../api/api";
import objToFormdata from "../../../utils/objToFormdata";
import { useToast } from "../../../components/Toast";
import { advertisements_url } from "../../../api/urls";
import unknownError from "../../../utils/unknownError";
import { moveUpToError } from "../../../utils/moveUpToError";

const initialState = {
  credit: "",
};
const title = "Add Credit";
const backUrl = "/b2b_store/eligible_users";
const apiUrl = advertisements_url;

const B2BAddCredit = ({ edit, view }) => {
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const params = useParams();
  const id = params.id;
  const toast = useToast();
  const [isSaving, setIsSaving] = useState(false);

  const set = (key, value) => {
    setData((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };

  return (
    <form
      id="form-wrapper"
      onSubmit={(e) => {
        e.preventDefault();
        setIsSaving(true);
        const newData = { ...data };
        try {
          api
            .post(apiUrl + id, objToFormdata(newData))
            .then((res) => {
              toast.open("Added data with id: " + res.data.id, "success");
              history.push(backUrl);
              setIsSaving(false);
            })
            .catch((err) => {
              if (err.response.data) {
                setErrors(err.response.data);
                moveUpToError();
              } else {
                unknownError(err);
              }
              setIsSaving(false);
            });
        } catch (err) {
          unknownError(err);
          setIsSaving(false);
        }
      }}
    >
      <div id="form">
        <BackButton href={backUrl} />
        <Header>{title}</Header>
        <InputText
          label="Enter credit"
          placeholder="Enter credit"
          disabled={view}
          value={data.code}
          error={errors["code"]}
          required
          onChange={(v) => set("code", v)}
        />
        {!view && (
          <>
            <div style={{ padding: "10px" }} />
            <div className="actions">
              <button
                className="btn-red"
                type="button"
                onClick={() => {
                  history.push(backUrl);
                }}
              >
                Cancel
              </button>
              <button
                className="btn"
                onClick={() => {
                  moveUpToError();
                }}
                disabled={isSaving}
              >
                Save
              </button>
            </div>
          </>
        )}
        <div style={{ padding: "10px" }} />
      </div>
    </form>
  );
};

export default B2BAddCredit;
