export function TextChip({ text, color: propColor }) {
  let color = propColor;
  if (color === "success") {
    color = "#00ff00";
  }
  if (color === "error") {
    color = "#FF0000";
  }
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: `${color}1a`,
        color: color,
        borderRadius: "5px",
        padding: "3px 10px",
        // whiteSpace: "nowrap",
        fontSize: "12px",
        width: "100%",
        minWidth: "80px",
        borderColor: color,
        fontWeight: "500",
      }}
    >
      {text}
    </div>
  );
}
