import { Header } from "semantic-ui-react";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import {
  InputCheckBox,
  InputDate,
  InputImage,
  InputNumber,
  InputText,
  InputTextBox,
  InputToggle,
} from "../../../components/Inputs/Inputs";
import BackButton from "../../../components/BackButton/BackButton";
import { api } from "../../../api/api";
import { useToast } from "../../../components/Toast";
import Stepper from "../../../components/Stepper/Stepper";
import { ReactComponent as LoadingIcon } from "../../../images/loading.svg";
import {
  brand_url,
  country_url,
  first_category_url,
  gbrand_url,
  gfirst_category_url,
  gsecond_category_url,
  gseller_url,
  gthird_category_url,
  second_category_url,
  seller_address,
  seller_annual_income,
  seller_bank,
  seller_business_type,
  seller_docs,
  seller_facility,
  seller_organisation_type,
  seller_url,
  state_url,
  third_category_url,
} from "../../../api/urls";
import unknownError from "../../../utils/unknownError";
import SellerDocDashboard from "./seller_doc_dashboard";
import SellerCertDashboard from "./seller_cert_dashboard";
import Loading from "../../../components/Loading/Loading";
import { APIDropdown } from "../../../components/APIDropdown/APIDropdown";
import { moveUpToError } from "../../../utils/moveUpToError";
import { objToParams } from "../../../utils/objToParams";

const initialState = {
  seller_id: "",
  add_id: "",
  bank_id: "",
  kyc_doc_id: "",
  cert_doc_id: "",

  code: "",
  description: "",
  printable_name: "",
  disable: false,
  is_active: false,
  company_image: "",
  firstname: "",
  lastname: "",
  company_name: "",
  company_email_id: "",
  is_verified: false,
  email_id: "",
  website: "",
  landline_number: "",
  mobile_number: "",
  date_of_incoperate: "",
  gstin_number: "",
  pan_number: "",
  tan_number: "",
  cin_number: "",
  bizz_type: "",
  orgzn_type: "",
  ann_income: "",
  is_global: "",
  // product: "",
  // video: "",
  country: "",
  brand: "",
  category_first: "",
  category_second: "",
  category_third: "",
  facility_name: "",
  contact_name: "",
  contact_number: "",
  contact_email_id: "",
  building_premises_number: "",
  building_premises_name: "",
  address_line_1: "",
  address_line_2: "",
  landmark: "",
  area: "",
  district: "",
  country_name: "",
  city_village: "",
  pincode: "",
  state: "",
  state_code: "",
  facility_gstin_number: "",
  facility_type: "",
  add_country: "",
  state_link: "",
  seller: "",

  bank_name: "",
  bank_branch_address: "",
  account_name: "",
  account_number: "",
  ifsc_code: "",
  upi_id_number: "",

  kyc_doc_name: "",
  kyc_doc_number: "",
  kyc_doc_expiry: "",
  kyc_doc_file: "",
  kyc_is_verified: false,
  kyc_doc_kyc: false,
  kyc_doc_certificate: false,
  kyc_doc_type: "",

  cert_doc_name: "",
  cert_doc_number: "",
  cert_doc_expiry: "",
  cert_doc_file: "",
  cert_is_verified: false,
  cert_doc_kyc: false,
  cert_doc_certificate: false,
  cert_doc_type: "",
};

const title = "Seller";
const apiUrl = "";
const steps = [
  "Personal Profile",
  "Company Profile",
  "Address",
  "Bank Account",
  "Kyc Document",
  "Kyc Certificate",
  "Configuration",
];

const NewSeller = ({ edit, view, isGlobal }) => {
  const backUrl = isGlobal ? "/global_store/sellers" : "/sellers/sellers";
  const [data, setData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [step, setStep] = useState(0);
  const [complete, setComplete] = useState(edit ? 100 : -1);
  const history = useHistory();
  const params = useParams();
  const toast = useToast();
  const [isGetting, setIsGetting] = useState(true);

  function get_api_by_step(step) {
    const apiUrl = isGlobal ? gseller_url : seller_url;
    if (step === 0) return apiUrl;
    if (step === 1) return apiUrl;
    if (step === 2) return seller_address;
    if (step === 3) return seller_bank;
    if (step === 4) return seller_docs;
    if (step === 5) return seller_docs;
    if (step === 6) return apiUrl;
  }

  useEffect(() => {
    if ((edit || view) && params.id) {
      const getData = async () => {
        try {
          const res = await api.get(apiUrl + params.id + "/");
          setData(res.data);
          setIsGetting(false);
        } catch (err) {
          unknownError(err);
          setIsGetting(false);
        }
      };

      getData();
    } else {
      setIsGetting(false);
    }
  }, [params.id, edit, view]);

  const set = (key, value) => {
    setData((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };

  const get_id_by_step = (step) => {
    if (step === 0) return data.seller_id;
    if (step === 1) return data.seller_id;
    if (step === 2) return data.add_id;
    if (step === 3) return data.bank_id;
    if (step === 4) return data.kyc_doc_id;
    if (step === 5) return data.cert_doc_id;
    if (step === 6) return data.seller_id;
  };

  const set_id_by_step = (step, id) => {
    if (step === 0) {
      set("seller_id", id);
      set("seller", id);
    }
    if (step === 1) set("seller_id", id);
    if (step === 2) set("add_id", id);
    if (step === 3) set("bank_id", id);
    if (step === 4) set("kyc_doc_id", id);
    if (step === 5) set("cert_doc_id", id);
  };

  if (isGetting) return <Loading />;

  return (
    <form
      id="form-wrapper"
      onSubmit={(e) => {
        e.preventDefault();
        if (step === 4 || step === 5) return;
        setLoading(true);
        const api_url = get_api_by_step(step);
        const id = get_id_by_step(step);
        const newData = data_by_step(data, step, isGlobal);
        try {
          if (edit || step <= complete || step === 6) {
            api
              .patch(api_url + id + "/", newData, {
                headers: {
                  "Content-Type":
                    step < 2 || step > 3
                      ? "multipart/form-data"
                      : "application/json",
                },
              })
              .then((res) => {
                set_id_by_step(step, res.data.id);
                if (step === 0) {
                  setComplete(1);
                } else {
                  setComplete(step);
                }
                if (step === steps.length - 1) {
                  toast.open("Created seller successfully.", "success");
                  history.push(backUrl);
                }
                setStep((c) => (c + 1 < steps.length ? c + 1 : c));
                setLoading(false);
              })
              .catch((err) => {
                if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
                setLoading(false);
              });
          } else {
            api
              .post(api_url, newData, {
                headers: {
                  "Content-Type":
                    step < 2 || step > 3
                      ? "multipart/form-data"
                      : "application/json",
                },
              })
              .then((res) => {
                set_id_by_step(step, res.data.id);
                if (step === 0) {
                  setComplete(1);
                } else {
                  setComplete(step);
                }
                if (step === steps.length - 1) {
                  toast.open("Created seller successfully.", "success");
                  history.push(backUrl);
                }
                setStep((c) => (c + 1 < steps.length ? c + 1 : c));
                setLoading(false);
              })
              .catch((err) => {
                if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
                setLoading(false);
              });
          }
        } catch (err) {
          unknownError(err);
          setLoading(false);
        }
      }}
    >
      <div id={step < 4 || step > 5 ? "twoside-form" : ""}>
        <BackButton href={backUrl} />
        <Header>{title}</Header>
        <Stepper
          step={step}
          setter={setStep}
          steps={steps}
          allowedTill={complete}
        />
        {step === 4 && (
          <SellerDocDashboard
            seller_id={data.seller_id}
            next={() => {
              setStep(step + 1);
              setComplete(step);
            }}
          />
        )}
        {step === 5 && (
          <SellerCertDashboard
            seller_id={data.seller_id}
            next={() => {
              setStep(step + 1);
              setComplete(step);
            }}
          />
        )}
        {(step < 4 || step > 5) && (
          <div className="twoside-form">
            {step === 0 && (
              <div className="formside">
                <InputText
                  label="Code"
                  placeholder="Code"
                  disabled={view}
                  required
                  value={data.code}
                  onChange={(v) => set("code", v)}
                  error={errors["code"]}
                />
                <InputText
                  label="Printable name"
                  placeholder="Printable name"
                  disabled={view}
                  required
                  value={data.printable_name}
                  onChange={(v) => set("printable_name", v)}
                  error={errors["printable_name"]}
                />
                <InputTextBox
                  label="Description"
                  placeholder="Description"
                  disabled={view}
                  required
                  value={data.description}
                  onChange={(v) => set("description", v)}
                  error={errors["description"]}
                />
                <InputText
                  label="First name"
                  placeholder="First name"
                  disabled={view}
                  required
                  value={data.firstname}
                  onChange={(v) => set("firstname", v)}
                  error={errors["firstname"]}
                />
                <InputText
                  label="Last name"
                  placeholder="Last name"
                  disabled={view}
                  required
                  value={data.lastname}
                  onChange={(v) => set("lastname", v)}
                  error={errors["lastname"]}
                />
                <InputText
                  label="Email id"
                  placeholder="Email id"
                  type="email"
                  disabled={view}
                  required
                  value={data.email_id}
                  onChange={(v) => set("email_id", v)}
                  error={errors["email_id"]}
                />
                <InputNumber
                  label="Mobile number"
                  placeholder="Mobile number"
                  disabled={view}
                  required
                  value={data.mobile_number}
                  onChange={(v) => set("mobile_number", v)}
                  error={errors["mobile_number"]}
                />
              </div>
            )}
            {step === 1 && (
              <>
                <div className="formside">
                  <InputText
                    label="Company name"
                    placeholder="Company name"
                    disabled={view}
                    required
                    value={data.company_name}
                    onChange={(v) => set("company_name", v)}
                    error={errors["company_name"]}
                  />
                  <InputText
                    label="Email id"
                    placeholder="Email id"
                    type="email"
                    disabled={view}
                    required
                    value={data.company_email_id}
                    onChange={(v) => set("company_email_id", v)}
                    error={errors["company_email_id"]}
                  />
                  <InputText
                    label="Website url"
                    placeholder="Website url"
                    disabled={view}
                    required
                    value={data.website}
                    onChange={(v) => set("website", v)}
                    error={errors["website"]}
                  />
                  <InputNumber
                    label="Landline number"
                    placeholder="Landline number"
                    disabled={view}
                    required
                    value={data.landline_number}
                    onChange={(v) => set("landline_number", v)}
                    error={errors["landline_number"]}
                  />
                  <APIDropdown
                    label="Brand"
                    placeholder="Brand"
                    required
                    disabled={view}
                    value={data.brand}
                    url={isGlobal ? gbrand_url : brand_url}
                    onChange={(v) => set("brand", v)}
                    error={errors["brand"]}
                    hasMultipleSelection
                  />
                  <InputText
                    label="Registered office GSTIN number"
                    placeholder="Registered office GSTIN number"
                    disabled={view}
                    required
                    value={data.gstin_number}
                    onChange={(v) => set("gstin_number", v)}
                    error={errors["gstin_number"]}
                  />
                  <InputText
                    label="PAN number"
                    placeholder="PAN number"
                    disabled={view}
                    required
                    value={data.pan_number}
                    onChange={(v) => set("pan_number", v)}
                    error={errors["pan_number"]}
                  />
                  <InputText
                    label="TAN number"
                    placeholder="TAN number"
                    disabled={view}
                    required
                    value={data.tan_number}
                    onChange={(v) => set("tan_number", v)}
                    error={errors["tan_number"]}
                  />
                  <InputText
                    label="CIN number"
                    placeholder="CIN number"
                    disabled={view}
                    required
                    value={data.cin_number}
                    onChange={(v) => set("cin_number", v)}
                    error={errors["cin_number"]}
                  />
                </div>
                <div className="formside">
                  <InputImage
                    label="Company image"
                    placeholder="Company image"
                    disabled={view}
                    required
                    value={data.company_image}
                    onChange={(v) => set("company_image", v)}
                    error={errors["company_image"]}
                  />
                  <APIDropdown
                    label="Organisation type"
                    placeholder="Organisation type"
                    required
                    disabled={view}
                    value={data.orgzn_type}
                    url={seller_organisation_type}
                    onChange={(v) => set("orgzn_type", v)}
                    error={errors["orgzn_type"]}
                  />
                  <InputDate
                    label="Date of incorporation"
                    required
                    placeholder="Date of incorporation"
                    value={data.date_of_incoperate}
                    disabled={view}
                    onChange={(v) => set("date_of_incoperate", v)}
                    error={errors["date_of_incoperate"]}
                  />
                  <APIDropdown
                    label="Business type"
                    placeholder="Business type"
                    required
                    disabled={view}
                    value={data.bizz_type}
                    url={seller_business_type}
                    onChange={(v) => set("bizz_type", v)}
                    error={errors["bizz_type"]}
                  />
                  <APIDropdown
                    label="Annual income"
                    placeholder="Annual income"
                    required
                    disabled={view}
                    value={data.ann_income}
                    url={seller_annual_income}
                    onChange={(v) => set("ann_income", v)}
                    error={errors["ann_income"]}
                  />
                  <APIDropdown
                    label="Country"
                    placeholder="Country"
                    required
                    disabled={view}
                    value={data.country}
                    url={country_url}
                    onChange={(v) => set("country", v)}
                    error={errors["country"]}
                    hasMultipleSelection
                  />
                </div>
              </>
            )}
            {step === 2 && (
              <>
                <div className="formside">
                  <InputText
                    label="Contact name"
                    placeholder="Contact name"
                    required
                    disabled={view}
                    value={data.contact_name}
                    onChange={(v) => set("contact_name", v)}
                    error={errors["contact_name"]}
                  />
                  <InputNumber
                    label="Mobile number"
                    placeholder="Mobile number"
                    required
                    disabled={view}
                    value={data.contact_number}
                    onChange={(v) => set("contact_number", v)}
                    error={errors["contact_number"]}
                  />
                  <InputText
                    label="Email id"
                    placeholder="Email id"
                    type="email"
                    required
                    disabled={view}
                    value={data.contact_email_id}
                    onChange={(v) => set("contact_email_id", v)}
                    error={errors["contact_email_id"]}
                  />
                  <InputText
                    label="Address 1"
                    placeholder="Room no, floor, building name, etc."
                    required
                    disabled={view}
                    value={data.address_line_1}
                    onChange={(v) => set("address_line_1", v)}
                    error={errors["address_line_1"]}
                  />
                  <InputText
                    label="Address 2"
                    placeholder="Street name, area, district, etc."
                    required
                    disabled={view}
                    value={data.address_line_2}
                    onChange={(v) => set("address_line_2", v)}
                    error={errors["address_line_2"]}
                  />
                  <InputText
                    label="Landmark"
                    placeholder="Landmark"
                    required
                    disabled={view}
                    value={data.landmark}
                    onChange={(v) => set("landmark", v)}
                    error={errors["landmark"]}
                  />
                  <InputText
                    label="City"
                    placeholder="City"
                    required
                    disabled={view}
                    value={data.city_village}
                    onChange={(v) => set("city_village", v)}
                    error={errors["city_village"]}
                  />
                  <InputNumber
                    label="Pincode"
                    placeholder="Pincode"
                    required
                    disabled={view}
                    value={data.pincode}
                    onChange={(v) => set("pincode", v)}
                    error={errors["pincode"]}
                  />
                </div>
                <div className="formside">
                  <APIDropdown
                    label="State"
                    placeholder="State"
                    required
                    disabled={view}
                    value={data.state_link}
                    url={state_url}
                    onChange={(v) => set("state_link", v)}
                    error={errors["state_link"]}
                  />
                  <APIDropdown
                    label="Country"
                    placeholder="Country"
                    required
                    disabled={view}
                    value={data.add_country}
                    url={country_url}
                    onChange={(v) => set("add_country", v)}
                    error={errors["add_country"]}
                  />
                  <InputText
                    label="Facility GSTIN number"
                    placeholder="Facility GSTIN number"
                    required
                    disabled={view}
                    value={data.facility_gstin_number}
                    onChange={(v) => set("facility_gstin_number", v)}
                    error={errors["facility_gstin_number"]}
                  />
                  <InputText
                    label="Facility name"
                    placeholder="Facility name"
                    required
                    disabled={view}
                    value={data.facility_name}
                    onChange={(v) => set("facility_name", v)}
                    error={errors["facility_name"]}
                  />
                  <APIDropdown
                    label="Facility type"
                    placeholder="Facility type"
                    disabled={view}
                    value={data.facility_type}
                    url={seller_facility}
                    onChange={(v) => set("facility_type", v)}
                    error={errors["facility_type"]}
                  />
                  <InputText
                    label="Building premises number"
                    placeholder="Building premises number"
                    disabled={view}
                    value={data.building_premises_number}
                    onChange={(v) => set("building_premises_number", v)}
                    error={errors["building_premises_number"]}
                  />
                  <InputText
                    label="Building premises name"
                    placeholder="Building premises name"
                    disabled={view}
                    value={data.building_premises_name}
                    onChange={(v) => set("building_premises_name", v)}
                    error={errors["building_premises_name"]}
                  />
                </div>
              </>
            )}
            {step === 3 && (
              <div className="formside">
                <InputText
                  label="Bank name"
                  placeholder="Bank name"
                  required
                  disabled={view}
                  value={data.bank_name}
                  onChange={(v) => set("bank_name", v)}
                  error={errors["bank_name"]}
                />
                <InputText
                  label="Bank address"
                  placeholder="Bank address"
                  required
                  disabled={view}
                  value={data.bank_branch_address}
                  onChange={(v) => set("bank_branch_address", v)}
                  error={errors["bank_branch_address"]}
                />
                <InputText
                  label="Account name"
                  placeholder="Account name"
                  required
                  disabled={view}
                  value={data.account_name}
                  onChange={(v) => set("account_name", v)}
                  error={errors["account_name"]}
                />
                <InputNumber
                  label="Account number"
                  placeholder="Account number"
                  required
                  disabled={view}
                  value={data.account_number}
                  onChange={(v) => set("account_number", v)}
                  error={errors["account_number"]}
                />
                <InputText
                  label="IFSC code"
                  placeholder="IFSC code"
                  required
                  disabled={view}
                  value={data.ifsc_code}
                  onChange={(v) => set("ifsc_code", v)}
                  error={errors["ifsc_code"]}
                />
                <InputText
                  label="UPI id"
                  placeholder="UPI id"
                  required
                  disabled={view}
                  value={data.upi_id_number}
                  onChange={(v) => set("upi_id_number", v)}
                  error={errors["upi_id_number"]}
                />
              </div>
            )}
            {step === 6 && (
              <div className="formside">
                <APIDropdown
                  label="First category"
                  placeholder="First category"
                  required
                  disabled={view}
                  value={data.category_first}
                  url={isGlobal ? gfirst_category_url : first_category_url}
                  onChange={(v) => set("category_first", v)}
                  error={errors["category_first"]}
                  hasMultipleSelection
                />
                <APIDropdown
                  label="Second category"
                  placeholder="Second category"
                  required
                  disabled={view}
                  value={data.category_second}
                  url={
                    (isGlobal ? gsecond_category_url : second_category_url) +
                    "?" +
                    objToParams({
                      category_first: data.category_first,
                    })
                  }
                  onChange={(v) => set("category_second", v)}
                  error={errors["category_second"]}
                  hasMultipleSelection
                />
                <APIDropdown
                  label="Third category"
                  placeholder="Third category"
                  required
                  disabled={view}
                  value={data.category_third}
                  url={
                    (isGlobal ? gthird_category_url : third_category_url) +
                    "?" +
                    objToParams({
                      category_second: data.category_second,
                    })
                  }
                  onChange={(v) => set("category_third", v)}
                  error={errors["category_third"]}
                  hasMultipleSelection
                />
                <InputCheckBox
                  label="Verified"
                  disabled={view}
                  value={data.is_verified}
                  onChange={(v) => set("is_verified", v)}
                />
                <InputToggle
                  label="Active"
                  disabled={view}
                  value={data.is_active}
                  error={errors["is_active"]}
                  onChange={(v) => set("is_active", v)}
                />
              </div>
            )}
            {/* {step === 4 && (
            <div className="formside">
              <InputText
                label="Document name"
                placeholder="Document name"
                disabled={view}
                required
                value={data.kyc_doc_name}
                onChange={(v) => set("kyc_doc_name", v)}
                error={errors["doc_name"]}
              />
              <InputSelect
                label="Document type"
                placeholder="Document type"
                disabled={view}
                required
                value={idToname(data.kyc_doc_type, kycdoc)}
                options={kycdoc ? kycdoc.map((d) => d.printable_name) : []}
                onChange={(v) =>
                  set(data.kyc_doc_type, nameToid(data.kyc_doc_type, kycdoc))
                }
                error={errors["doc_type"]}
              />
              <InputText
                label="Document number"
                placeholder="Document number"
                required
                disabled={view}
                value={data.kyc_doc_number}
                onChange={(v) => set("kyc_doc_number", v)}
                error={errors["doc_number"]}
              />
              <InputDate
                label="Document expiry date"
                placeholder="Document expiry date"
                required
                disabled={view}
                value={data.kyc_doc_expiry}
                onChange={(v) => set("kyc_doc_expiry", v)}
                error={errors["doc_expiry"]}
              />
              <div className="two-inputs">
                <InputFile
                  label="Upload document"
                  placeholder="Upload document"
                  required
                  disabled={view}
                  value={data.kyc_doc_file}
                  onChange={(v) => set("kyc_doc_file", v)}
                  error={errors["doc_file"]}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "small",
                    color: "#64646499",
                    height: "120px",
                    alignSelf: "stretch",
                  }}
                >
                  Format pdf, docx, png, jpg
                </div>
              </div>
              <InputCheckBox
                label="Verified"
                color="#5AA100"
                disabled={view}
                value={data.kyc_is_verified}
                onChange={(v) => set("kyc_is_verified", v)}
              />
            </div>
          )}
          {step === 5 && (
            <div className="formside">
              <InputText
                label="Certificate name"
                placeholder="Certificate name"
                disabled={view}
                required
                value={data.cert_doc_name}
                onChange={(v) => set("cert_doc_name", v)}
                error={errors["doc_name"]}
              />
              <InputSelect
                label="Certificate type"
                placeholder="Certificate type"
                disabled={view}
                required
                value={idToname(data.cert_doc_type, kyccert)}
                options={kyccert ? kyccert.map((d) => d.printable_name) : []}
                onChange={(v) =>
                  set(data.cert_doc_type, nameToid(data.cert_doc_type, kyccert))
                }
                error={errors["doc_type"]}
              />
              <InputText
                label="Certificate number"
                placeholder="Certificate number"
                disabled={view}
                required
                value={data.cert_doc_number}
                onChange={(v) => set("cert_doc_number", v)}
                error={errors["doc_number"]}
              />
              <InputDate
                label="Certificate expiry date"
                placeholder="Certificate expiry date"
                disabled={view}
                required
                value={data.cert_doc_expiry}
                onChange={(v) => set("cert_doc_expiry", v)}
                error={errors["doc_expiry"]}
              />
              <div className="two-inputs">
                <InputFile
                  label="Upload document"
                  placeholder="Upload document"
                  disabled={view}
                  required
                  value={data.cert_doc_file}
                  onChange={(v) => set("cert_doc_file", v)}
                  error={errors["doc_file"]}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "small",
                    color: "#64646499",
                    height: "120px",
                    alignSelf: "stretch",
                  }}
                >
                  Format pdf, docx, png, jpg
                </div>
              </div>
              <InputCheckBox
                label="Verified"
                color="#5AA100"
                disabled={view}
                value={data.cert_is_verified}
                onChange={(v) => set("cert_is_verified", v)}
              />
            </div>
          )} */}
          </div>
        )}
        {!view && (step < 4 || step > 5) && (
          <>
            <div style={{ padding: "10px" }} />
            <div className="actions">
              <button
                className="btn-red"
                type="button"
                onClick={() => {
                  history.push(backUrl);
                }}
              >
                Cancel
              </button>
              {steps.length - 1 === step ? (
                <button
                  className="btn"
                  onClick={() => {
                    moveUpToError();
                  }}
                  disabled={loading}
                >
                  Save
                </button>
              ) : (
                <button
                  className="btn"
                  disabled={loading}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "35px",
                    width: "auto",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                  }}
                  onClick={() => {
                    moveUpToError();
                  }}
                >
                  {loading ? <LoadingIcon height={30} width={30} /> : null}
                  Save & Next
                </button>
              )}
            </div>
          </>
        )}
        <div style={{ padding: "10px" }} />
      </div>
    </form>
  );
};

export default NewSeller;

function data_by_step(data, step, isGlobal) {
  const newData = { ...data };
  if (isGlobal) {
    newData.is_global = true;
  }
  if (step === 0) {
    return objToFormdata(newData);
  }
  if (step === 1) {
    return objToFormdata(newData);
  }
  if (step === 2) {
    const twoData = {
      code: data.code,
      description: data.description,
      printable_name: data.printable_name,
      disable: data.disable,
      is_active: data.is_active,
      facility_name: data.facility_name,
      contact_name: data.contact_name,
      contact_number: data.contact_number,
      contact_email_id: data.contact_email_id,
      building_premises_number: data.building_premises_name,
      building_premises_name: data.building_premises_name,
      address_line_1: data.address_line_1,
      address_line_2: data.address_line_2,
      landmark: data.landmark,
      area: data.area,
      district: data.district,
      country_name: data.country_name,
      city_village: data.city_village,
      pincode: data.pincode,
      state: data.state,
      state_code: data.state_code,
      facility_gstin_number: data.facility_gstin_number,
      facility_type: data.facility_type,
      country: data.add_country,
      state_link: data.state_link,
      seller: data.seller_id,
    };
    return twoData;
  }

  if (step === 3) {
    const threeData = {
      code: data.code,
      description: data.description,
      printable_name: data.printable_name,
      disable: data.disable,
      is_active: data.is_active,
      bank_name: data.bank_name,
      bank_branch_address: data.bank_branch_address,
      account_name: data.account_name,
      account_number: data.account_number,
      ifsc_code: data.ifsc_code,
      upi_id_number: data.upi_id_number,
      seller: data.seller_id,
    };
    return threeData;
  }

  if (step === 6) {
    return objToFormdata(newData);
  }

  return newData;
}

function objToFormdata(data) {
  const form_data = new FormData();
  for (const key in data) {
    if (data[key] === "") continue;
    if (data[key] === undefined) continue;
    if (Object.prototype.toString.call(data[key]) === "[object Array]") {
      data[key].forEach((d) => {
        form_data.append(key, d);
      });
    } else {
      form_data.append(key, data[key]);
    }
  }
  return form_data;
}
