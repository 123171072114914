import { useEffect, useState } from "react";
import Modal from "../../../components/Modal/Modal";
import "./generate_bill.scss";
import "./more_info_modal.scss";
import { na, na2, namt } from "../../../utils/na";
import Loading from "../../../components/Loading/Loading";
import { InputNumber } from "../../../components/Inputs/Inputs";
import { api } from "../../../api/api";
import { useToast } from "../../../components/Toast";
import unknownError from "../../../utils/unknownError";
import { moveUpToError } from "../../../utils/moveUpToError";
import { getDateOfStatus } from "./more_info_modal";
import moment from "moment";
import { order_list_url } from "../../../api/urls";
import { DashboardImage } from "../../../components/DashboardImage/DashboardImage";

const RefundModal = ({ data, actionPath, actionName, refetch, isFailed }) => {
  const [open, setOpen] = useState(false);
  const [orders, setOrders] = useState();

  useEffect(() => {
    const newData = { ...data };
    if (isFailed) {
      newData.total_amount_paid_amt =
        newData.product_sell_price_amt - newData.online_paid_amt;
      newData.online_paid_amt = 0;
    } else {
      newData.total_amount_paid_amt = newData.product_sell_price_amt;
    }
    setOrders([newData]);
  }, [data, isFailed]);

  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      trigger={
        <div className="blue-title" style={{ width: "100px" }}>
          {actionName}
        </div>
      }
      className="modal-window product-modal"
    >
      <div
        className="close-btn"
        onClick={() => {
          setOpen(false);
        }}
      >
        &#10005;
      </div>
      <div style={{ padding: "2px" }} />
      <div className="modal-content">
        <div className="modal-form">
          <Display
            data={orders ? orders : []}
            modalCloser={() => setOpen(false)}
            actionPath={actionPath}
            closer={() => setOpen(false)}
            refetch={refetch}
            isFailed={isFailed}
          />
        </div>
      </div>
    </Modal>
  );
};

const Display = ({
  data,
  modalCloser,
  actionPath,
  closer,
  refetch,
  isFailed,
}) => {
  if (data.length === 0) {
    return (
      <div
        style={{
          flex: 1,
          height: "300px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loading />
      </div>
    );
  }
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      {data.map((d, key) => {
        return (
          <ProductDetailsSection
            key={key}
            data={d}
            modalCloser={modalCloser}
            actionPath={actionPath}
            closer={closer}
            refetch={refetch}
            isFailed={isFailed}
          />
        );
      })}
    </div>
  );
};

const viewDetails = false;

const initialState = {
  refund: true,
};

const ProductDetailsSection = ({
  data: d,
  cancel,
  is_return,
  modalCloser,
  actionPath,
  refetch,
  closer,
  isFailed,
}) => {
  const [open] = useState(!viewDetails);
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const toast = useToast();
  const [saving, setSaving] = useState(false);

  // useEffect(() => {
  //   const get = async () => {
  //     try {
  //       const res = await api.get(
  //         order_list_url + d.id + "/" + actionPath + "/"
  //       );
  //       setQuestions(res.data.questions.filter((x) => x.accept));
  //     } catch (err) {
  //       unknownError(err);
  //     }
  //   };
  //   get();
  // }, [actionPath, d.id]);

  const set = (key, value) => {
    setData((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };

  return (
    <>
      <div style={{ padding: "5px" }} />
      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <div
          className="color-blue"
          style={{ fontSize: "20px", fontWeight: "500" }}
        >
          Order details
        </div>
        <div className="bg-blue" style={{ height: "2px", flex: 1 }}></div>
      </div>
      <div style={{ padding: "5px" }} />
      <div
        style={{
          border: "1px solid #00000040",
          borderRadius: "10px",
          display: "flex",
          padding: "20px",
          gap: "20px",
          alignItems: "center",
        }}
      >
        <DashboardImage
          src={d.tagon_product_image}
          height="150px"
          width="150px"
        />
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <div>
            <b>Product : </b>
            {na(d?.prod_json?.title)}
          </div>
          <div>
            <b>Order ID : </b>
            {na(d.order_id)}
          </div>
          <div>
            <b>Selling price : </b>
            {namt(d?.subtotal_after_tax_amt, d?.currency_symbol)}
          </div>
          <div>
            <b>Reason : </b>
            {na(d?.reason_choices)}
          </div>
        </div>
      </div>
      <div style={{ padding: "5px" }} />
      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <div
          className="color-blue"
          style={{ fontSize: "20px", fontWeight: "500" }}
        >
          Paymet details
        </div>
        <div className="bg-blue" style={{ height: "2px", flex: 1 }}></div>
      </div>
      <div style={{ padding: "5px" }} />
      <div
        style={{
          border: "1px solid #00000040",
          borderRadius: "10px",
          display: "flex",
          padding: "20px",
          gap: "20px",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", gap: "10px" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                width: "100%",
              }}
            >
              <div>
                <b>Transaction ID : </b>
                {na(d?.payment_id)}
              </div>
              <div>
                <b>Date : </b>
                {na2(
                  getDateOfStatus(d?.order_logs, "OS_ ORDERCNFRMD_47"),
                  moment(
                    getDateOfStatus(d?.order_logs, "OS_ ORDERCNFRMD_47")
                  ).format("DD-MM-YYYY")
                )}
              </div>
              <div>
                <b>Paid amount : </b>
                {namt(d.total_amount_paid_amt, d?.currency_symbol)}
              </div>
              <div>
                <b>Payment method : </b>
                {na(d?.payment_method)}
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                width: "100%",
              }}
            >
              <div>
                <b>Paid status : </b>
                {na(d?.paid_status)}
              </div>
              <div>
                <b>Refund to bank : </b>
                {na(d?.refund_to_bank?.toString())}
              </div>
              <div>
                <b>Refund to source : </b>
                {na(d?.refund_to_source?.toString())}
              </div>
              <div>
                <b>Refund to wallet : </b>
                {na(d?.refund_to_wallet?.toString())}
              </div>
            </div>
          </div>
          <div />
          <div className="two-price-sections">
            <div className="payment-box">
              <b>Total payment</b>
              <br />
              <table>
                <tbody>
                  <tr>
                    <td>Online payment</td>
                    <td>{namt(d?.online_paid_amt, d?.currency_symbol)}</td>
                  </tr>
                  <tr>
                    <td>COD payment</td>
                    <td>
                      {namt(d?.collect_on_delivery_amt, d?.currency_symbol)}
                    </td>
                  </tr>
                  <tr>
                    <td>Wallet used</td>
                    <td>
                      {namt(d?.wallet_partial_paid_amt, d?.currency_symbol)}
                    </td>
                  </tr>
                  <tr>
                    <td>Promo coupon value</td>
                    <td>
                      {namt(d?.promo_coupons_value_partial, d?.currency_symbol)}
                    </td>
                  </tr>
                  <tr>
                    <td>Points used</td>
                    <td>{namt(d?.points_partial_amt, d?.currency_symbol)}</td>
                  </tr>
                </tbody>
              </table>
              <div
                style={{
                  borderTop: "1px solid #0000001A",
                  padding: "2px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <b>Total payment</b>
                  <b>{namt(d?.total_amount_paid_amt, d?.currency_symbol)}</b>
                </div>
              </div>
            </div>
            <div className="payment-box">
              <b>Total refundable amount</b>
              <br />
              <table>
                <tbody>
                  <tr>
                    <td>Total payment</td>
                    <td>
                      {namt(d?.total_amount_paid_amt, d?.currency_symbol)}
                    </td>
                  </tr>
                  <tr>
                    <td>Promo coupon value</td>
                    <td>
                      {namt(
                        d?.promo_coupons_value_partial,
                        d?.currency_symbol,
                        "-"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Points used</td>
                    <td>
                      {namt(d?.points_partial_amt, d?.currency_symbol, "-")}
                    </td>
                  </tr>
                  {d.paid_status !== "PAID" && (
                    <tr>
                      <td>COD unpaid</td>
                      <td>
                        {namt(
                          d?.collect_on_delivery_amt,
                          d?.currency_symbol,
                          "-"
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div
                style={{
                  borderTop: "1px solid #0000001A",
                  padding: "2px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <b>Total refundable amount</b>
                  <b>
                    {namt(
                      (
                        Number(d?.total_amount_paid_amt) -
                        Number(d?.promo_coupons_value_partial) -
                        Number(d?.points_partial_amt) -
                        Number(
                          d?.paid_status !== "PAID"
                            ? d?.collect_on_delivery_amt
                            : "0"
                        )
                      ).toFixed(2),
                      d?.currency_symbol
                    )}
                  </b>
                </div>
              </div>
            </div>
          </div>
          <div />
          <div className="two-price-sections">
            <div className="payment-box">
              <b>Refund breakup</b>
              <br />
              <table>
                <tbody>
                  <tr>
                    <td>Refund to wallet</td>
                    <td>
                      {namt(
                        Number(d?.wallet_partial_paid_amt) +
                          (d?.refund_to_wallet
                            ? Number(d?.online_paid_amt) +
                              Number(
                                d.paid_status === "PAID"
                                  ? d?.collect_on_delivery_amt
                                  : "0"
                              )
                            : 0),
                        d?.currency_symbol
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Refund to bank</td>
                    <td>
                      {namt(
                        d?.refund_to_bank
                          ? Number(d?.online_paid_amt) +
                              Number(
                                d.paid_status === "PAID"
                                  ? d?.collect_on_delivery_amt
                                  : "0"
                              )
                          : 0,
                        d?.currency_symbol
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Refund to source</td>
                    <td>
                      {namt(
                        d?.refund_to_source
                          ? Number(d?.online_paid_amt) +
                              Number(
                                d.paid_status === "PAID"
                                  ? d?.collect_on_delivery_amt
                                  : "0"
                              )
                          : 0,
                        d?.currency_symbol
                      )}
                    </td>
                  </tr>
                  {isFailed && (
                    <tr>
                      <td>Refund points</td>
                      <td>{namt(d?.points_partial_amt, d?.currency_symbol)}</td>
                    </tr>
                  )}
                  {isFailed && (
                    <tr>
                      <td>Refund coupon</td>
                      <td>
                        {namt(
                          d?.promo_coupons_value_partial,
                          d?.currency_symbol
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div
                style={{
                  borderTop: "1px solid #0000001A",
                  padding: "2px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <b>Total payment</b>
                  <b>
                    {namt(
                      (
                        Number(d?.total_amount_paid_amt) -
                        Number(d?.promo_coupons_value_partial) -
                        Number(d?.points_partial_amt) -
                        Number(
                          d?.paid_status !== "PAID"
                            ? d?.collect_on_delivery_amt
                            : "0"
                        ) +
                        Number(isFailed ? d?.points_partial_amt : "0") +
                        Number(isFailed ? d?.promo_coupons_value_partial : "0")
                      ).toFixed(2),
                      d?.currency_symbol
                    )}
                  </b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ padding: "5px" }} />
      <form
        style={{
          overflow: "hidden",
          maxHeight: open ? "10000px" : "0px",
          transition: open ? "max-height 2s ease" : null,
        }}
        onSubmit={(e) => {
          e.preventDefault();
          if (data.wallet > d.wallet_partial_paid_amt) {
            toast.open(
              "New wallet paid amount can not be greater than original amount",
              "error"
            );
            return;
          }
          if (data.online > d.online_paid_amt) {
            toast.open(
              "New online paid amount can not be greater than original amount",
              "error"
            );
            return;
          }
          if (data.cod > d.collect_on_delivery_amt) {
            toast.open(
              "New COD paid amount can not be greater than original amount",
              "error"
            );
            return;
          }
          setSaving(true);
          const newData = { ...data };
          try {
            api
              .patch(`${order_list_url}${d.id}/${actionPath}/`, newData)
              .then((res) => {
                toast.open("Action performed successfully.", "success");
                setSaving(false);
                refetch();
                closer();
              })
              .catch((err) => {
                if (err?.response?.data) {
                  if (Array.isArray(err?.response?.data)) {
                    err.response.data.map((errorMsg) =>
                      toast.open(errorMsg, "error")
                    );
                  }
                  setErrors(err.response.data);
                  moveUpToError();
                  setSaving(false);
                } else {
                  unknownError(err);
                  setSaving(false);
                  closer();
                }
              });
          } catch (e) {
            unknownError(e);
            setSaving(false);
          }
        }}
      >
        {d?.payment_method !== "Cash On Delivery" && (
          <div id="form" style={{}}>
            {d?.wallet && (
              <InputNumber
                label="Change wallet paid amount"
                placeholder="0.00"
                value={data.wallet}
                onChange={(v) => set("wallet", v)}
                error={errors["wallet"]}
              />
            )}
            {d?.online_paid_amt > 0 && !isFailed && (
              <InputNumber
                label="Change online paid amount"
                placeholder="0.00"
                value={data.online}
                onChange={(v) => set("online", v)}
                error={errors["online"]}
              />
            )}
            {d?.paid_status !== "PAID" &&
              d?.collect_on_delivery_amt > 0 &&
              !isFailed && (
                <InputNumber
                  label="Change COD paid amount"
                  placeholder="0.00"
                  value={data.cod}
                  onChange={(v) => set("cod", v)}
                  error={errors["cod"]}
                />
              )}
          </div>
        )}
        <div style={{ padding: "20px" }} />
        <button className="btn" disabled={saving} style={{ width: "100%" }}>
          Initiate refund
        </button>
      </form>
      <div style={{ padding: "5px" }} />
    </>
  );
};

export default RefundModal;
