import { useState } from "react";
import Modal from "../../../components/Modal/Modal";
import "./more_info_modal.scss";
import { ReactComponent as ArrowIcon } from "../../../images/arrow.svg";
// import { ReactComponent as EditIcon } from "../../../images/edit.svg";
import { api } from "../../../api/api";
import { pls_add_url, pls_bank_url, pls_docs_url } from "../../../api/urls";
import { useEffect } from "react";
// import AddressDeleteModal from "./delete_confirm_modal";
import Loading from "../../../components/Loading/Loading";
import { na, na2 } from "../../../utils/na";
import unknownError from "../../../utils/unknownError";
// import EditPLSProfileModal from "./edit_seller_profile";
// import NewPLSAddressModal from "./new_seller_address";
import { sellerAddressFormatter } from "../../../utils/addresssFormatter";
// import NewPLSBankModal from "./new_seller_bank";
import moment from "moment";
import { DashboardImage } from "../../../components/DashboardImage/DashboardImage";
// import EditPLSDocsModal from "./edit_docs";

const MoreInfoModal = ({ data, refetch }) => {
  const [open, setOpen] = useState(false);
  const [addresses, setAddresses] = useState();
  const [addressRefetcher, setAddressRefetcher] = useState(false);
  const [banks, setBanks] = useState();
  const [bankRefetcher, setBankRefetcher] = useState(false);
  const [docs, setDocs] = useState();
  const [docsRefetcher, setDocsRefetcher] = useState(false);
  // const [certs, setCerts] = useState();
  // const [certsRefetcher, setCertsRefetcher] = useState(false);

  useEffect(() => {
    const get = async () => {
      try {
        const res = await api.get(pls_add_url + "?delivery_boy=" + data.id);
        setAddresses(res.data);
      } catch (err) {
        unknownError(err);
      }
    };
    if (open) {
      get();
    }
  }, [data.id, open, addressRefetcher]);

  useEffect(() => {
    const get = async () => {
      try {
        const res = await api.get(pls_bank_url + "?delivery_boy=" + data.id);
        setBanks(res.data);
      } catch (err) {
        unknownError(err);
      }
    };
    if (open) {
      get();
    }
  }, [data.id, open, bankRefetcher]);

  useEffect(() => {
    const get = async () => {
      try {
        const res = await api.get(pls_docs_url + "?delivery_boy=" + data.id);
        setDocs(res.data?.[0]);
      } catch (err) {
        unknownError(err);
      }
    };
    if (open) {
      get();
    }
  }, [data.id, open, docsRefetcher]);

  // useEffect(() => {
  //   const get = async () => {
  //     try {
  //       const res = await api.get(pls_docs_url + "?delivery_boy=" + data.id);
  //       setDocs(res.data.filter((x) => x.doc_kyc === true));
  //     } catch (err) {
  //       unknownError(err);
  //     }
  //   };
  //   if (open) {
  //     get();
  //   }
  // }, [data.id, open, docsRefetcher]);

  // useEffect(() => {
  //   const get = async () => {
  //     try {
  //       const res = await api.get(pls_docs_url + "?delivery_boy=" + data.id);
  //       setCerts(res.data.filter((x) => x.doc_certificate === true));
  //     } catch (err) {
  //       unknownError(err);
  //     }
  //   };
  //   if (open) {
  //     get();
  //   }
  // }, [data.id, open, certsRefetcher]);

  const addressRefetch = () => {
    setAddressRefetcher((v) => !v);
  };

  const bankRefetch = () => {
    setBankRefetcher((v) => !v);
  };

  const docsRefetch = () => {
    setDocsRefetcher((v) => !v);
  };

  // const certsRefetch = () => {
  //   setCertsRefetcher((v) => !v);
  // };

  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      trigger={<div className="blue-link">View Details</div>}
      className="modal-window product-modal"
    >
      <div
        className="close-btn"
        onClick={() => {
          setOpen(false);
        }}
      >
        &#10005;
      </div>
      <div style={{ padding: "2px" }} />
      <div className="modal-content">
        <div className="modal-form">
          <Annexture
            data={data}
            addresses={addresses}
            addressRefetch={addressRefetch}
            banks={banks}
            bankRefetch={bankRefetch}
            docs={docs}
            docsRefetch={docsRefetch}
            // certs={certs}
            // certsRefetch={certsRefetch}
            refetch={refetch}
          />
        </div>
      </div>
    </Modal>
  );
};

const Annexture = ({
  data,
  addresses,
  addressRefetch,
  banks,
  bankRefetch,
  docs,
  docsRefetch,
  certs,
  certsRefetch,
  refetch,
}) => {
  return (
    <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
      <HeadingSection data={data} refetch={refetch} />
      <ProfileDetailsSection data={data} refetch={refetch} />
      <AddressSection
        data={data}
        addresses={addresses}
        refetch={addressRefetch}
      />
      <BankSection data={data} banks={banks} refetch={bankRefetch} />
      <DocSection data={data} docs={docs} refetch={docsRefetch} />
      {/* <CertSection data={data} certs={certs} refetch={certsRefetch} /> */}
    </div>
  );
};

const HeadingSection = ({ data: d, refetch }) => {
  return (
    <div className="heading-section">
      <div className="left-section">
        <h3>
          <b>Delivery Boy Details</b>
        </h3>
      </div>
      <div className="right-section"></div>
    </div>
  );
};

const ProfileDetailsSection = ({ data: d, cancel, is_return, refetch }) => {
  return (
    <div className="product-info">
      <div className="image-section">
        <DashboardImage
          src={d.profile_img}
          height="200px"
          width="200px"
          circle
        />
      </div>
      <div className="details-section" style={{ position: "relative" }}>
        {/* <EditPLSProfileModal
          refetch={refetch}
          editData={d}
          trigger={
            <div
              className="i-btn"
              style={{ position: "absolute", right: "10px", top: "10px" }}
            >
              <EditIcon width={10} height={10} />
            </div>
          }
        /> */}
        <div className="twoside-details">
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <div>
              <b>Name : </b>
              {na2(
                d.first_name || d.last_name,
                d?.first_name + " " + d?.last_name
              )}
            </div>
            <div>
              <b>Mobile number : </b>
              {na2(d.mobile_no, <>+{d.mobile_no}</>)}
            </div>
            <div>
              <b>Birth date : </b>
              {na(d.birth_date)}
            </div>
            <div>
              <b>Temporary password : </b>
              {na(d.tmp_hex)}
            </div>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "5px" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

const DocSection = ({ docs: d, cancel, is_return, refetch }) => {
  if (!d) {
    return (
      <div className="product-info">
        <div className="details-section" style={{ position: "relative" }}>
          <Loading />
        </div>
      </div>
    );
  }
  return (
    <div className="product-info">
      <div className="details-section" style={{ position: "relative" }}>
        {/* <EditPLSDocsModal
          refetch={refetch}
          editData={d}
          trigger={
            <div
              className="i-btn"
              style={{ position: "absolute", right: "10px", top: "10px" }}
            >
              <EditIcon width={10} height={10} />
            </div>
          }
        /> */}
        <div className="twoside-details">
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <div>
              <b>Pan no : </b>
              {na(d.pan_no)}
            </div>
            <div>
              <b>Pan no file : </b>
              {na2(
                d.pan_file,
                <a
                  className="blue-link"
                  href={d.pan_file}
                  target="_blank"
                  rel="noreferrer"
                >
                  link
                </a>
              )}
            </div>
            <div>
              <b>Aadhaar no : </b>
              {na(d.aadhaar_number)}
            </div>
            <div>
              <b>Aadhaar file : </b>
              {na2(
                d.aadhaar_file,
                <a
                  className="blue-link"
                  href={d.aadhaar_file}
                  target="_blank"
                  rel="noreferrer"
                >
                  link
                </a>
              )}
            </div>
            <div>
              <b>License no : </b>
              {na(d.licence_no)}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <div>
              <b>License file : </b>
              {na2(
                d.driving_license_file,
                <a
                  className="blue-link"
                  href={d.driving_license_file}
                  target="_blank"
                  rel="noreferrer"
                >
                  link
                </a>
              )}
            </div>
            <div>
              <b>NOC file : </b>
              {na2(
                d.noc_file,
                <a
                  className="blue-link"
                  href={d.noc_file}
                  target="_blank"
                  rel="noreferrer"
                >
                  link
                </a>
              )}
            </div>
            <div>
              <b>PUC file : </b>
              {na2(
                d.puc_file,
                <a
                  className="blue-link"
                  href={d.puc_file}
                  target="_blank"
                  rel="noreferrer"
                >
                  link
                </a>
              )}
            </div>
            <div>
              <b>Vehicle registration certificate : </b>
              {na2(
                d.vehicle_registration_cert_file,
                <a
                  className="blue-link"
                  href={d.vehicle_registration_cert_file}
                  target="_blank"
                  rel="noreferrer"
                >
                  link
                </a>
              )}
            </div>
            <div>
              <b>Vehicle registration certificate expiry date : </b>
              {na2(
                d.vehicle_registration_cert_expiry,
                moment(d.vehicle_registration_cert_expiry).format("DD-MM-YYYY")
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AddressSection = ({ data, addresses, refetch }) => {
  return (
    <Accordian
      head={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            flex: 1,
          }}
        >
          <div>Address</div>
          {/* <NewPLSAddressModal
            refetch={refetch}
            delivery_boy_id={data.id}
            trigger={
              <div className="button" style={{ padding: "2px 10px" }}>
                Add new
              </div>
            }
          /> */}
        </div>
      }
      alwaysOpen
      body={
        <>
          {addresses === undefined ? (
            <div style={{ height: "200px" }}>
              <Loading />
            </div>
          ) : (
            <div className="address-box-wrapper">
              {addresses && addresses.length > 0
                ? addresses.map((d, idx) => (
                    <div className="address-box" key={idx}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          gap: "5px",
                        }}
                      >
                        {/* <NewPLSAddressModal
                          refetch={refetch}
                          delivery_boy_id={data.id}
                          editData={d}
                          trigger={
                            <div className="i-btn">
                              <EditIcon width={10} height={10} />
                            </div>
                          }
                        />
                        <AddressDeleteModal
                          data={d}
                          refetch={refetch}
                          dropdownCloser={() => false}
                          route={pls_add_url}
                        /> */}
                      </div>
                      {sellerAddressFormatter(d)}
                    </div>
                  ))
                : "No data entered yet."}
            </div>
          )}
        </>
      }
    />
  );
};

const BankSection = ({ data, banks, refetch }) => {
  return (
    <Accordian
      head={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            flex: 1,
          }}
        >
          <div>Bank Details</div>
          {/* <NewPLSBankModal
            refetch={refetch}
            delivery_boy_id={data.id}
            trigger={
              <div className="button" style={{ padding: "2px 10px" }}>
                Add new
              </div>
            }
          /> */}
        </div>
      }
      alwaysOpen
      body={
        <>
          {banks === undefined ? (
            <div style={{ height: "200px" }}>
              <Loading />
            </div>
          ) : (
            <div className="address-box-wrapper">
              {banks && banks.length > 0
                ? banks.map((d, idx) => (
                    <div className="address-box" key={idx}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          gap: "5px",
                        }}
                      >
                        {/* <NewPLSBankModal
                          refetch={refetch}
                          delivery_boy_id={data.id}
                          editData={d}
                          trigger={
                            <div className="i-btn">
                              <EditIcon width={10} height={10} />
                            </div>
                          }
                        />
                        <AddressDeleteModal
                          data={d}
                          refetch={refetch}
                          dropdownCloser={() => false}
                          route={pls_bank_url}
                        /> */}
                      </div>
                      <div>
                        <b>Bank name : </b>
                        {na(d.bank_name)}
                      </div>
                      <div>
                        <b>Account holder name : </b>
                        {na(d.account_holder_name)}
                      </div>
                      <div>
                        <b>Account number : </b>
                        {na(d.account_number)}
                      </div>
                      <div>
                        <b>IFSC code : </b>
                        {na(d.ifsc_code)}
                      </div>
                      <div>
                        <b>UPI id : </b>
                        {na(d.upi_id_number)}
                      </div>
                    </div>
                  ))
                : "No data entered yet."}
            </div>
          )}
        </>
      }
    />
  );
};

// const DocSection = ({ data, docs, refetch }) => {
//   return (
//     <Accordian
//       head={
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "space-between",
//             width: "100%",
//             flex: 1,
//           }}
//         >
//           <div>KYC Document Details</div>
//           <NewPLSDocModal
//             refetch={refetch}
//             delivery_boy_id={data.id}
//             trigger={
//               <div className="button" style={{ padding: "2px 10px" }}>
//                 Add new
//               </div>
//             }
//           />
//         </div>
//       }
//       alwaysOpen
//       body={
//         <>
//           {docs === undefined ? (
//             <div style={{ height: "200px" }}>
//               <Loading />
//             </div>
//           ) : (
//             <div className="address-box-wrapper">
//               {docs && docs.length > 0
//                 ? docs.map((d, idx) => (
//                     <div className="address-box" key={idx}>
//                       <div
//                         style={{
//                           display: "flex",
//                           justifyContent: "flex-end",
//                           gap: "5px",
//                         }}
//                       >
//                         <NewPLSDocModal
//                           refetch={refetch}
//                           delivery_boy_id={data.id}
//                           editData={d}
//                           trigger={
//                             <div className="i-btn">
//                               <EditIcon width={10} height={10} />
//                             </div>
//                           }
//                         />
//                         <AddressDeleteModal
//                           data={d}
//                           refetch={refetch}
//                           dropdownCloser={() => false}
//                           route={pls_docs_url}
//                         />
//                       </div>
//                       <div>
//                         <b>Code : </b>
//                         {na(d.code)}
//                       </div>
//                       <div>
//                         <b>Document name : </b>
//                         {na(d.doc_name)}
//                       </div>
//                       <div>
//                         <b>Document number : </b>
//                         {na(d.doc_number)}
//                       </div>
//                       <div>
//                         <b>Document expiry : </b>
//                         {na2(
//                           d.doc_expiry,
//                           <>{moment(d.doc_expiry).format("DD-MM-YYYY")}</>
//                         )}
//                       </div>
//                       <div>
//                         <b>Document file : </b>
//                         {na2(
//                           d.doc_file,
//                           <a
//                             className="blue-link"
//                             href={d.doc_file}
//                             target="_blank"
//                             rel="noreferrer"
//                           >
//                             View File
//                           </a>
//                         )}
//                       </div>
//                       <div>
//                         <b>Verified : </b>
//                         {boolSmall(d.is_verified)}
//                       </div>
//                     </div>
//                   ))
//                 : "No data entered yet."}
//             </div>
//           )}
//         </>
//       }
//     />
//   );
// };

// const CertSection = ({ data, certs, refetch }) => {
//   return (
//     <Accordian
//       head={
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "space-between",
//             width: "100%",
//             flex: 1,
//           }}
//         >
//           <div>KYC Certificate Details</div>
//           <NewPLSCertModal
//             refetch={refetch}
//             delivery_boy_id={data.id}
//             trigger={
//               <div className="button" style={{ padding: "2px 10px" }}>
//                 Add new
//               </div>
//             }
//           />
//         </div>
//       }
//       alwaysOpen
//       body={
//         <>
//           {certs === undefined ? (
//             <div style={{ height: "200px" }}>
//               <Loading />
//             </div>
//           ) : (
//             <div className="address-box-wrapper">
//               {certs && certs.length > 0
//                 ? certs.map((d, idx) => (
//                     <div className="address-box" key={idx}>
//                       <div
//                         style={{
//                           display: "flex",
//                           justifyContent: "flex-end",
//                           gap: "5px",
//                         }}
//                       >
//                         <NewPLSCertModal
//                           refetch={refetch}
//                           delivery_boy_id={data.id}
//                           editData={d}
//                           trigger={
//                             <div className="i-btn">
//                               <EditIcon width={10} height={10} />
//                             </div>
//                           }
//                         />
//                         <AddressDeleteModal
//                           data={d}
//                           refetch={refetch}
//                           dropdownCloser={() => false}
//                           route={pls_docs_url}
//                         />
//                       </div>
//                       <div>
//                         <b>Code : </b>
//                         {na(d.code)}
//                       </div>
//                       <div>
//                         <b>Document name : </b>
//                         {na(d.doc_name)}
//                       </div>
//                       <div>
//                         <b>Document number : </b>
//                         {na(d.doc_number)}
//                       </div>
//                       <div>
//                         <b>Document expiry : </b>
//                         {na2(
//                           d.doc_expiry,
//                           <>{moment(d.doc_expiry).format("DD-MM-YYYY")}</>
//                         )}
//                       </div>
//                       <div>
//                         <b>Document file : </b>
//                         {na2(
//                           d.doc_file,
//                           <a
//                             className="blue-link"
//                             href={d.doc_file}
//                             target="_blank"
//                             rel="noreferrer"
//                           >
//                             View File
//                           </a>
//                         )}
//                       </div>
//                       <div>
//                         <b>Verified : </b>
//                         {boolSmall(d.is_verified)}
//                       </div>
//                     </div>
//                   ))
//                 : "No data entered yet."}
//             </div>
//           )}
//         </>
//       }
//     />
//   );
// };

const Accordian = ({ head, body, footer, alwaysOpen = false }) => {
  const [open, setOpen] = useState(alwaysOpen);
  const height = 10000;

  return (
    <div className={"acc " + (open ? "open" : "")}>
      <div
        className="acc-head"
        onClick={() => (!alwaysOpen ? setOpen(!open) : null)}
      >
        <b style={{ flex: 1 }}>{head}</b>
        {!alwaysOpen && (
          <ArrowIcon className={"acc-arrow " + (open ? "open" : "")} />
        )}
      </div>
      <div
        className={"acc-body " + (open ? "open" : "")}
        style={{ maxHeight: open ? height : 0 }}
      >
        {body}
        {footer}
      </div>
    </div>
  );
};

export default MoreInfoModal;
